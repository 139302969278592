import { IAugmentedJQuery, IComponentController, IRootScopeService } from 'angular';
import { IMenuNavigationItem } from '../../interfaces/menu-navigation-item.interface';
import IStateService = angular.ui.IStateService;
import { IRoute } from "../../interfaces/route.interface";
import { IApiConfig } from "../../interfaces/api-config.interface";
import IInjectorService = angular.auto.IInjectorService;
import { IFavorisMenu } from "../../interfaces/favoris-menu.interface";

const TRAITEMENTS = {
    LOT: 'TRL',
    EXCEL: 'TRX',
    QLIK: 'QCS'
};

interface IComposantNavigationItem extends IComponentController {
    items: Array<IMenuNavigationItem>;
    rechercheRapide: string;
    pracleint: number;
    menuId: number;
    activeItemIndex: number;
    queryParams: any;
    vaesysapp: string;
    skipReloadActive: boolean;
    cleFavoris: string;
    getHref(item: IMenuNavigationItem): string;
    isItemMenu(item: IMenuNavigationItem): boolean;
    onStarClick(e: Event, item: IMenuNavigationItem): void;
    isFavoris(item: IMenuNavigationItem): boolean;
}

/* @ngInject */
export default function NavigationItemController($injector: IInjectorService,
    $state: IStateService,
    ApiConfig: IApiConfig,
    $element: IAugmentedJQuery,
    $rootScope: IRootScopeService) {
    const vm: IComposantNavigationItem = this;

    vm.$onInit = function $onInit() {
        vm.activeItemIndex = null;
        $rootScope.$on('$stateChangeSuccess', () => {
            if (!vm.skipReloadActive) {
                vm.activeItemIndex = null
            }
            vm.skipReloadActive = false
        })
    }

    vm.getHref = function getHref(item: IMenuNavigationItem) {
        if (item.hypurl) {
            return item.hypurl
        } else if (item.vaesysapp && item.vaesysapp !== "SOF2" && item.vaesysapp !== "EMP2") {
            return ApiConfig.ROOT + '/get-menu-url/' + item.medcleintref;
        } else {
            return $state.href(item.isQlik ? $injector.has('gs0089Route') && ($injector.get('gs0089Route') as IRoute).NAME : (item.isRapport ? $injector.has('gs0090Route') && ($injector.get('gs0090Route') as IRoute).NAME : item.stateName), {
                menuId: `${vm.pracleint}-${item.vaesysenttrtmen},${item.mencleint},${item.vaesysenttrt},${item.medcleintref}`,
                ecran: (item.isRapport || item.isQlik) ? "00-2" : item.codref.split('-').slice(1).join('-'),
                ...vm.queryParams,
                id: (item.vaesysenttrt === TRAITEMENTS.QLIK) ? item.codref : undefined
            }, { inherit: false });
        }
    }

    vm.onNavigationChange = function onNavigationChange(index: number) {
        localStorage.setItem('forageFromMenu', 'oui');
        localStorage.removeItem("forageTabSelected")
        vm.activeItemIndex = index;
        vm.skipReloadActive = true
    }

    vm.onStarClick = function onStarClick(e: Event, item: IMenuNavigationItem) {
        e.preventDefault();

        let favoris = localStorage.getItem(vm.cleFavoris) ? JSON.parse(localStorage.getItem(vm.cleFavoris)) : [];

        if (!vm.isFavoris(item)) {
            favoris.push({ pracleint: vm.pracleint, medcleintref: item.medcleintref, vaesysenttrt: item.vaesysenttrt, mencleint: item.mencleint });
        } else {
            favoris = favoris.filter((pItem: IFavorisMenu) => pItem.medcleintref !== item.medcleintref || vm.pracleint !== pItem.pracleint || pItem.mencleint !== item.mencleint || pItem.vaesysenttrt !== item.vaesysenttrt);
        }

        localStorage.setItem(vm.cleFavoris, JSON.stringify(favoris));
        $rootScope.$emit(vm.cleFavoris, favoris)
    }

    vm.isFavoris = function isFavoris(item: IMenuNavigationItem) {
        const favoris = localStorage.getItem(vm.cleFavoris) ? JSON.parse(localStorage.getItem(vm.cleFavoris)) : [];
        return favoris.some((pItem: IFavorisMenu) => pItem.medcleintref === item.medcleintref && vm.pracleint === pItem.pracleint && pItem.mencleint === item.mencleint && pItem.vaesysenttrt === item.vaesysenttrt);
    }

    vm.showStar = function showStar(item: IMenuNavigationItem) {
        $element.find(`[name='${item.medcleintref}-${item.vaesysenttrt}-${item.mencleint}'] > md-icon`).removeClass('ng-hide');
    }

    vm.hideStar = function hideStar(item: IMenuNavigationItem) {
        $element.find(`[name='${item.medcleintref}-${item.vaesysenttrt}-${item.mencleint}'] > md-icon`).addClass('ng-hide');
    }
}
