/**
 * Composant représentant un input-date
 *
 */
import {IComponentOptions, module} from 'angular';
import Binding from '../../helpers/binding.helper';
import InputDateController from './ex-input-date.controller';

export default module('core.components.ex-input-date', []).component('exInputDate', InputDateFactory());

function InputDateFactory(): IComponentOptions {
    return {
        bindings: Binding(
            Binding.NAME_ELEMENT,
            Binding.LIBELLES,
            Binding.VALUE,
            Binding.DISABLED,
            Binding.DATA.OPTIONAL,
            Binding.READONLY.OPTIONAL,
            Binding.DATA_TYPE.OPTIONAL,
            {
                titre: '@',
                change: '&',
                required : '<?ngRequired',
                disabled: '<?ngDisabled',
                minDate: '<?',
                maxDate: '<?',
                onValueChange: "<?",
                tooltip: '@?'
            }
        ),
        require: {
            formCtrl: '^form'
        },
        controllerAs: 'vm',
        controller: InputDateController,
        template: require('./ex-input-date.html')
    };
}
