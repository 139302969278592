import IResourceArray = angular.resource.IResourceArray;
import IDialogOptions = angular.material.IDialogOptions;
import { IComponentController, IRootScopeService, } from 'angular';
import { IDialog } from '../../services/dialog.service';
import { IApiError } from '../../interfaces/api-error.interface';
import { ICycleRhClass, IMessageAvertissementRh } from '../../resources/cycle-rh.resource';
import { IMessagesCycle } from '../../services/messages-cycle.service';
import { IEcranContextController } from '../../behaviors/ex-ecran-context/ex-ecran-context.behavior';
import { IParametresSecuriteService } from '../../services/parametres-securite.service';

interface IComposantCardMessage extends IComponentController {
    ecranContextCtrl: IEcranContextController;
    messagesCycle: IMessagesCycle;
    saveAction: Function;
    toutSelectionne: boolean;
    saving: boolean;
    onCheckboxChange(): void;
    isDisabled(): boolean;
    toggleToutSelectionne(): void;
    save(): void;
    getListeAvertissements(): Array<any>
}

/* @ngInject */
export default function CardCycleMessageController(CycleRh: ICycleRhClass,
    DialogConfirm: IDialog,
    parametresSecurite: IParametresSecuriteService,
    $rootScope: IRootScopeService) {
    const vm: IComposantCardMessage = this;

    vm.$onInit = $onInit;
    vm.onCheckboxChange = onCheckboxChange;
    vm.isDisabled = isDisabled;
    vm.toggleToutSelectionne = toggleToutSelectionne;
    vm.save = save;
    vm.getListeAvertissements = getListeAvertissements;
    function $onInit() {
        vm.saving = false;
        vm.toutSelectionne = false;
        setTimeout(() => {
            activerAdvertissement()
        }, 500);
    }

    function activerAdvertissement() {
        //On coche tous les messages qui sont déjà autorisés
        if (vm.messagesCycle && vm.messagesCycle.messagesAvertissement) {
            vm.messagesCycle.messagesAvertissement.forEach((messageAvertissement: any) => {
                messageAvertissement.$selected = (messageAvertissement?.trvflgaut) ? true : false
                messageAvertissement.$disabled = (messageAvertissement?.trvflgaut) ? true : false
            });
        }
    }

    function toggleToutSelectionne() {
        vm.messagesCycle.messagesAvertissement.forEach((messageAvertissement: any) => {
            messageAvertissement.$selected = (vm.toutSelectionne && messageAvertissement.trvflgaut === 0);
        });
    }

    function onCheckboxChange() {
        vm.toutSelectionne = false;
    }

    function isDisabled() {
        let result = true
        vm.messagesCycle.messagesAvertissement.some((messageAvertissement) => {
            if (messageAvertissement && messageAvertissement.trvflgaut === 0 && (messageAvertissement.$selected || false)) {
                result = false
            }
        });
        return result
    }

    function save() {
        if (!vm.isDisabled()) {
            vm.saving = true;
            vm.messagesCycle.fetchingMessagesAvertissement = true
            autoriserAvertissements()
                .catch((error: IApiError) => {
                    afficherAutorisationErreur(error);
                }).finally(() => {
                    vm.messagesCycle.fetchingMessagesAvertissement = false
                    const codeEcran = vm?.ecranContextCtrl?.stateParams?.srccod + "-" + vm?.ecranContextCtrl?.stateParams?.ecran
                    if (codeEcran) {
                        $rootScope.$broadcast("vm.mono-occurrence.reloadEcran", { codeEcran: codeEcran })
                    }
                });
        }
    }

    function getListeAvertissements(): Array<any> {
        return (vm?.messagesCycle?.messagesAvertissement || [])
    }

    function autoriserAvertissements() {
        // On ne fait qu'autoriser
        const data = {
            trvflgaut: 1
        };

        // On concatène les ids des avertissements à autoriser
        const ids: string = vm.messagesCycle.messagesAvertissement
            .reduce((ids: Array<number>, valeur) => {
                if (valeur.$selected) {
                    ids.push(valeur.trvcleint);
                }

                return ids;
            }, [])
            .join(',');

        const params = {
            ids,
            trncleint: vm.messagesCycle.id,
            srccod: vm.ecranContextCtrl.stateParams.srccod,
            ...parametresSecurite(vm.ecranContextCtrl.stateParams)
        };

        return CycleRh.updateMessagesAvertissement(params, data).$promise
            .then((nouveauxAvertissements: IResourceArray<IMessageAvertissementRh>) => {
                // On doit mettre à jour chaque avertissement modifié
                nouveauxAvertissements.forEach((nouvelAvertissement: IMessageAvertissementRh) => {
                    const avertissement = vm.messagesCycle.messagesAvertissement.find((avertissement: IMessageAvertissementRh) => {
                        return avertissement.trvcleint == nouvelAvertissement.trvcleint;
                    });

                    // On met à jour
                    Object.assign(avertissement, nouvelAvertissement);
                });
            })
            .finally(() => {
                vm.saving = false;
            });
    }

    function afficherAutorisationErreur(error: IApiError) {
        const dialogOptions: IDialogOptions = {
            locals: {
                error,
                lblTitre: 'G_LBL_AVERTISSEMENT',
                lblMessage: 'G_MSG_SAUV_ENR',
                ecranContext: vm.ecranContextCtrl,
                confirmAction() {
                    return autoriserAvertissements();
                }
            }
        };

        DialogConfirm.show(dialogOptions);
    }
}
