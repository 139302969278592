import { module } from 'angular';
import { IMenu } from '../menu/menu.service';
import { IMenuItemForage } from '../menu/menu-item-forage.service';
import { IDataType } from '../data-types/data-type.service';
import { IMenuItem } from '../menu/menu-item.service';
import { IFormulaire } from './formulaire.service';
import { IMultiOccurrence } from '../multi-occurrence.service';
import { IMenuItemFactoryFactory } from '../menu/menu-item-factory.service';

export interface IFormulaireItemClass {
    new(col: string, option?: IFormulaireItemOptions): IFormulaireItem;
    new(): IFormulaireItem;
}

export interface IFormulaireItem {
    readonly lblCol: string;
    readonly col: string;
    readonly titre: string | IFormulaireItemTitreFonction;
    readonly masque: string;
    readonly modelOptions: any;
    readonly largeur: number | string;
    readonly direction: string;
    readonly required: boolean | IFormulaireRequiredFonction;
    readonly enableCount: boolean;
    readonly modifAllow: boolean;
    readonly disabled: boolean | IFormulaireItemDisabledFonction;
    readonly: boolean | IFormulaireReadOnlyFonction;
    readonly lblSuffixPastille: string;
    readonly default: any | IFormulaireItemDefaultFonction;
    readonly nombreLibellesDetailsCacher: number;
    readonly hidden: boolean | IFormulaireItemHiddenFonction;
    longueurFixe: number;
    readonly supprimable: boolean;
    readonly historiqueColonne: boolean;
    readonly onFocus: IFormulaireItemFocusFonction;
    readonly onBlur: IFormulaireItemBlurFonction;
    readonly onValueChange: IFormulaireItemValueChangeFonction;
    readonly minDate: Date | IFormulaireItemDateFonction;
    readonly maxDate: Date | IFormulaireItemDateFonction;
    readonly maxlength: number;
    readonly raised: boolean;
    readonly afficheLibelleEntete: boolean;
    readonly formulairesConditionnels: Array<IFormulaireItemFormulaireConditionnel>;
    readonly minWidth: number;
    readonly ignoreEtat: boolean;
    readonly securityCycle: boolean;
    readonly switch: boolean;
    readonly alignRight: boolean;
    readonly libellesContour: IFormulaireItemLibellesContour;
    readonly affichage: boolean;
    readonly isValidatingAtLoad: (data: any) => boolean;
    readonly viderChamp?: boolean;
    readonly viderByEntiteExterne?: boolean;
    readonly lovSelectionMultiple?: boolean;
    readonly setOrdre?: number;
    readonly reloadData?: boolean;
    readonly tooltip?: string | IFormulaireItemTooltipFonction;
    readonly toolTipJson?: { data: string, value: string };
    readonly isChampDescriptionHtml?: boolean;
    dataType: IDataType;
    resourceParams: any | IFormulaireItemResourceParamsFonction;
    multiOccurrence?: IMultiOccurrence;
}

export interface IFormulaireRequiredFonction { (data?: any): boolean; }
export interface IFormulaireItemDateFonction { (data?: any): void; }
export interface IFormulaireReadOnlyFonction { (data?: any): boolean | any; }
export interface IFormulaireItemFocusFonction { (data: any, event: MouseEvent): void; }
export interface IFormulaireItemBlurFonction { (data: any, event: JQueryEventObject): void; }
export interface IFormulaireItemHiddenFonction { (data: any): boolean; }
export interface IFormulaireItemDefaultFonction { (data: any): any; }
export interface IFormulaireItemDisabledFonction { (data?: any): boolean; }
export interface IFormulaireItemResourceParamsFonction { (data?: any): void; }
export interface IFormulaireItemValueChangeFonction { (data?: any): void; }
export interface IFormulaireItemTitreFonction { (data?: any): string; }
export interface IFormulaireItemTooltipFonction { (data?: any): string; }

export interface IFormulaireItemLibellesContour {
    gauche?: string | IFormulaireItemLibelleContourOptions;
    droite?: string | IFormulaireItemLibelleContourOptions;
}

interface IFormulaireItemLibelleContourOptions {
    largeur: number;
    texte: string;
    alignment?: string;
}

export interface IFormulaireItemFormulaireConditionnel {
    valeur: any;
    formulaire: IFormulaire;
}

export interface IFormulaireItemOptions {
    largeur?: number | string;
    titre?: string | IFormulaireItemTitreFonction;
    lblCol?: string;
    required?: boolean | IFormulaireRequiredFonction;
    direction?: string;
    lblAide?: string;
    enableCount?: boolean;
    masque?: string;
    modelOptions?: any;
    modifAllow?: boolean;
    disabled?: boolean | IFormulaireItemDisabledFonction;
    readonly?: boolean | IFormulaireReadOnlyFonction;
    affichage?: boolean;
    default?: any;
    lblSuffixPastille?: string; // Dans l'utilisation d'une pastille il est possible d'ajouter du text statique a la fin de la valeur
    dataType?: IDataType;
    resourceParams?: any | IFormulaireItemResourceParamsFonction;
    nombreLibellesDetailsCacher?: number;
    hidden?: boolean | IFormulaireItemHiddenFonction;
    menu?: Array<IMenu | IMenuItem | IMenuItemFactoryFactory>;
    menuGauche?: Array<IMenu | IMenuItem | IMenuItemFactoryFactory>;
    longueurFixe?: number;
    supprimable?: boolean;
    historiqueColonne?: boolean;
    onFocus?: IFormulaireItemFocusFonction;
    onBlur?: IFormulaireItemBlurFonction;
    onValueChange?: IFormulaireItemValueChangeFonction;
    minDate?: Date | IFormulaireItemDateFonction;
    maxDate?: Date | IFormulaireItemDateFonction;
    maxlength?: number;
    raised?: boolean;
    afficheLibelleEntete?: boolean;
    formulairesConditionnels?: Array<IFormulaireItemFormulaireConditionnel>;
    minWidth?: number;
    ignoreEtat?: boolean;
    securityCycle?: boolean;
    switch?: boolean;
    alignRight?: boolean;
    libellesContour?: IFormulaireItemLibellesContour;
    isValidatingAtLoad?: (data: any) => boolean;
    viderChamp?: boolean;
    viderByEntiteExterne?: boolean;
    lovSelectionMultiple?: boolean;
    setOrdre?: number;
    reloadData?: boolean;
    tooltip?: string | IFormulaireItemTooltipFonction;
    toolTipJson?: { data: string, value: string };
    isChampDescriptionHtml?: boolean;
}

export default module('core.services.formulaire-item', []).factory('FormulaireItem', FormulaireItemFactory);

/* @ngInject */
function FormulaireItemFactory() {
    class FormulaireItem implements IFormulaireItem {
        readonly lblCol: string;
        readonly col: string;
        readonly titre: string | IFormulaireItemTitreFonction;
        readonly largeur: number | string = null;
        readonly direction: string;
        readonly masque: string;
        readonly modelOptions: any;
        readonly required: boolean | IFormulaireRequiredFonction;
        readonly enableCount: boolean;
        readonly modifAllow: boolean = true;
        readonly disabled: boolean | IFormulaireItemDisabledFonction = false;
        readonly readonly: boolean | IFormulaireReadOnlyFonction = false;
        readonly affichage: boolean = false;
        readonly lblSuffixPastille: string = '';
        readonly default: any;
        readonly dataType: IDataType;
        readonly nombreLibellesDetailsCacher: number;
        readonly hidden: boolean | IFormulaireItemHiddenFonction;
        readonly longueurFixe: number;
        readonly menu: Array<IMenu | IMenuItemForage | IMenuItemFactoryFactory>;
        readonly menuGauche: Array<IMenu | IMenuItem | IMenuItemFactoryFactory>;
        readonly supprimable: boolean;
        readonly historiqueColonne: boolean;
        readonly onFocus: IFormulaireItemFocusFonction;
        readonly onBlur: IFormulaireItemBlurFonction;
        readonly onValueChange: IFormulaireItemValueChangeFonction;
        readonly minDate: Date | IFormulaireItemDateFonction;
        readonly maxDate: Date | IFormulaireItemDateFonction;
        readonly maxlength: number;
        readonly raised: boolean;
        readonly afficheLibelleEntete: boolean;
        readonly formulairesConditionnels: Array<IFormulaireItemFormulaireConditionnel>;
        readonly minWidth: number;
        readonly ignoreEtat: boolean = false;
        readonly securityCycle: boolean = true;
        readonly switch: boolean = false;
        readonly alignRight: boolean = false;
        readonly libellesContour: IFormulaireItemLibellesContour;
        readonly isValidatingAtLoad: (data: any) => boolean;
        readonly viderChamp?: boolean;
        readonly viderByEntiteExterne?: boolean;
        readonly lovSelectionMultiple?: boolean;
        readonly setOrdre?: number;
        readonly reloadData?: boolean;
        readonly tooltip?: string | IFormulaireItemTooltipFonction;
        readonly toolTipJson?: { data: string, value: string };
        readonly isChampDescriptionHtml?: boolean;
        resourceParams: any | IFormulaireItemResourceParamsFonction;

        constructor(col: string, options: IFormulaireItemOptions = {}) {
            this.col = col;

            if (typeof options.lblCol !== 'undefined') {// ceci est util pour les filtres d'un bri de clé dans portail emp
                this.lblCol = options.lblCol;
            } else {
                this.lblCol = this.col;
            }

            this.largeur = options.largeur;
            this.direction = options.direction;
            this.required = options.required;
            this.titre = options.titre;
            this.enableCount = options.enableCount;
            this.masque = options.masque;

            if (typeof options.modifAllow !== 'undefined') {
                this.modifAllow = options.modifAllow;
            }

            this.dataType = options.dataType;
            this.disabled = options.disabled || false;
            this.affichage = options.affichage || false;
            this.readonly = options.affichage || options.readonly || false;
            this.default = options.default;
            this.lblSuffixPastille = options.lblSuffixPastille || '';
            this.resourceParams = options.resourceParams;
            this.nombreLibellesDetailsCacher = options.nombreLibellesDetailsCacher;
            this.hidden = options.hidden;
            this.longueurFixe = options.longueurFixe;
            this.supprimable = options.supprimable;
            this.historiqueColonne = options.historiqueColonne;
            this.menu = options.menu;
            this.menuGauche = options.menuGauche;
            this.modelOptions = options.modelOptions;
            this.onFocus = options.onFocus;
            this.onBlur = options.onBlur;
            this.minDate = options.minDate;
            this.maxDate = options.maxDate;
            this.maxlength = options.maxlength;
            this.raised = options.raised;
            this.afficheLibelleEntete = options.afficheLibelleEntete;
            this.formulairesConditionnels = options.formulairesConditionnels;
            this.minWidth = options.minWidth;
            this.viderChamp = options.viderChamp || false;
            this.viderByEntiteExterne = options.viderByEntiteExterne || false;
            this.lovSelectionMultiple = options.lovSelectionMultiple || false;
            this.setOrdre = options.setOrdre || 0;
            this.reloadData = options.reloadData || false;
            this.tooltip = options.tooltip;
            this.toolTipJson = options.toolTipJson
            this.isChampDescriptionHtml = options.isChampDescriptionHtml
            this.ignoreEtat = options.ignoreEtat || false;

            if (typeof options.securityCycle !== 'undefined') {
                this.securityCycle = options.securityCycle;
            }

            this.onValueChange = options.onValueChange;
            this.switch = options.switch || false;
            this.alignRight = options.alignRight || false;
            this.libellesContour = options.libellesContour;
            this.isValidatingAtLoad = options.isValidatingAtLoad;
        }
    }
    return FormulaireItem;
}
