/**
 * Composant représentant un input-date-hour
 *
 */
import {IComponentOptions, module} from 'angular';
import Binding from '../../helpers/binding.helper';
import InputDateHourController from './ex-input-date-hour.controller';

export default module('core.components.ex-input-date-hour', []).component('exInputDateHour', InputDateHourFactory());

function InputDateHourFactory(): IComponentOptions {
    return {
        bindings: Binding(
            Binding.NAME_ELEMENT,
            Binding.LIBELLES,
            Binding.VALUE,
            Binding.DISABLED,
            Binding.DATA_TYPE,
            Binding.DATA,
            Binding.READONLY.OPTIONAL,
            {
                titre: '@?',
                required : '<?ngRequired',
                lblDate : '@?',
                lblHour : '@?',
                tooltip: '@?'
            }
        ),
        require: {
            formCtrl: '^form'
        },
        controllerAs: 'vm',
        controller: InputDateHourController,
        template: require('./ex-input-date-hour.html')
    };
}
