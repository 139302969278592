import { module } from 'angular';
import DialogTooltipController from './dialog-tooltip.controller';
import { IDialogClass } from '../../services/dialog.service';

export default module('app.dialogs.dialog-tooltip', [
    'core.services.dialog'
]).factory('DialogTooltip', DialogTooltipFactory);

/* @ngInject */
function DialogTooltipFactory(Dialog: IDialogClass) {
    return new Dialog({
        controller: DialogTooltipController,
        controllerAs: 'vm',
        bindToController: true,
        template: require('./dialog-tooltip.html'),
        focusOnOpen: false,
        locals: {
            icon: 'history',
            data: 'history',
            lblTitre: ''
        }
    });
}
