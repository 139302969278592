import {IComponentOptions, module} from 'angular';
import Binding from '../../helpers/binding.helper';
import InputNumberController from './ex-input-number.controller';

export default module('core.components.ex-input-number', []).component('exInputNumber', InputNumberFactory());

function InputNumberFactory(): IComponentOptions {
    return {
        bindings: Binding(
            Binding.NAME_ELEMENT,
            Binding.VALUE,
            Binding.DATA.OPTIONAL,
            Binding.LIBELLES.OPTIONAL,
            Binding.DATA_TYPE.OPTIONAL,
            Binding.LIBELLES.OPTIONAL,
            Binding.DISABLED.OPTIONAL,
            Binding.MODEL_OPTIONS.OPTIONAL,
            Binding.READONLY.OPTIONAL,
            {
                titre: '@',
                required : '<?ngRequired',
                min: '<?',
                max: '<?',
                step: '<?',
                precision: '<?',
                decimals: '<?',
                alignRight: '<?',
                onFocus: '<?',
                onBlur: '<?',
                onValueChange: '<?',
                tooltip: '@?'
            }
        ),
        require: {
            formCtrl: '^form'
        },
        controllerAs: 'vm',
        controller: InputNumberController,
        template: require('./ex-input-number.html')
    };
}
