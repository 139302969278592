import { IAttributes, IAugmentedJQuery, IScope, ITimeoutService, module } from 'angular';

export default module('core.behaviors.ex-measure-width', [])
    .directive('exMeasureWidth', MeasureWidth);

/* @ngInject */
function MeasureWidth() {
    return {
        restrict: 'A',
        priority: 1000,
        terminal: true,
        link: function (scope: IScope, $element: IAugmentedJQuery, attrs: IAttributes, $timeout: ITimeoutService) {
            let isWidthMeasured = false;

            if (!isWidthMeasured) {
                const siblingElement: any = $element.find('#ex-hierarchie-item-content');
                const parentElement = siblingElement?.prevObject?.[0]?.parentElement;

                if (parentElement && parentElement.offsetWidth) {
                    const width = parentElement.offsetWidth;
                    isWidthMeasured = true;

                    scope.$evalAsync(() => {
                        if (attrs.onWidthChange && width > 0) {
                            scope.$eval(attrs.onWidthChange, { width });
                        }
                    });

                } 
            }
        }
    };
}