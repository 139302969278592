import { IComponentController } from 'angular';

export interface IDialogImputation extends IComponentController {
    icon: string;
    data: any
}

/* @ngInject */
export default function DialogTooltipController() {
    const vm: IDialogImputation = this;
    vm.$onInit = function $onInit() {
    }
}
