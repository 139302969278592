import { IAugmentedJQuery, module } from 'angular';

export default module('core.behaviors.ex-render-complete', [])
    .directive('exRenderComplete', RenderComplete);

/* @ngInject */
function RenderComplete() {
    return {
        restrict: 'A',
        link: function (scope: any, element: IAugmentedJQuery, attrs: any) {
            if (scope.$first) {
                scope.$eval(attrs.onStartRender);
            }
            if (scope.$last) {
                scope.$eval(attrs.onFinishRender);
            }
        }
    };
}
