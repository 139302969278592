import { IDataType, IDataTypeClass, IDataTypeMap } from '../../services/data-types/data-type.service';
import { ISchemas } from '../../interfaces/schemas.interface';
import { IDataSourceController } from '../ex-data-source/ex-data-source.component';
import { IComposantMonoOccurrence } from '../ex-mono-occurrence/ex-mono-occurrence.controller';
import { IComponentController } from 'angular';
import { IMenu } from '../../services/menu/menu.service';
import { IBoutonDataTypeClass } from '../../services/data-types/bouton-data-type.service';
import { IDialog } from '../../services/dialog.service';
import IDialogOptions = angular.material.IDialogOptions;

interface IDataValue extends IComponentController {
    col: string;
    data: any;
    context: any;
    libelles: any;
    schemas: ISchemas;
    dataTypes: IDataTypeMap;
    dataType: IDataType;
    menu: IMenu;
    isGridData: boolean;
    monoOccurrenceCtrl: IComposantMonoOccurrence;
    dataSourceCtrl: IDataSourceController;
    mnemonique: string;
    isBouton: boolean;
    hasMenu: boolean;
    prefix?: string | number;
    suffix?: string | number;
    cssprefix?: string;
    isIcon: string;
    iconTitle: string;
    iconClass: string;
    icon: string;
    tagMultiLigne: string;
    setSeparateurEntete: boolean;
    stringToTag: string;
    stringToTagSeparateur: string;
    tagSeparateurEntete: string;
    iconLibelleTitre?: string;
    hasModal?: boolean;
    hasCssPrefix(): boolean;
    getCssPrefix(): string;
    ouvrirModale(): void;
}
/* @ngInject */
export default function DataValueController(DataType: IDataTypeClass, BoutonDataType: IBoutonDataTypeClass, DialogTooltip: IDialog) {
    const vm: IDataValue = this;
    vm.hasCssPrefix = hasCssPrefix
    vm.getCssPrefix = getCssPrefix
    vm.ouvrirModale = ouvrirModale
    vm.$onInit = function $onInit() {
        vm.data = vm.data || (vm.dataSourceCtrl && vm.dataSourceCtrl.data) || (vm.monoOccurrenceCtrl && vm.monoOccurrenceCtrl.monoOccurrence.data);
        vm.schemas = vm.schemas || (vm.dataSourceCtrl && vm.dataSourceCtrl.schemas) || (vm.monoOccurrenceCtrl && vm.monoOccurrenceCtrl.monoOccurrence.schema);
        vm.dataTypes = vm.dataTypes || (vm.dataSourceCtrl && vm.dataSourceCtrl.dataTypes) || (vm.monoOccurrenceCtrl && vm.monoOccurrenceCtrl.monoOccurrence.dataTypes) || {};

        vm.dataType = vm.dataTypes[vm.col] || new DataType({ schemaItem: vm.schemas && vm.schemas[vm.col] });
        if (this.isGridData && vm.dataType?.schemaItem?.length && vm.dataType.schemaItem.length > 100) {
            vm.dataType.setGridData();
        }

        vm.context = { libelles: vm.libelles, inclusLibelleBoolean: Boolean(vm.multiOccurrenceTemplateCtrl), ...(vm.context || {}) };

        if (vm.monoOccurrenceCtrl) {
            vm.monoOccurrenceCtrl.monoOccurrence.on('exMenuItemActionDataUpdate', onMenuItemDataUpdate);
            vm.monoOccurrenceCtrl.monoOccurrence.on('softDataUpdate', onMenuItemDataUpdate);
            vm.$onDestroy = () => {
                vm.monoOccurrenceCtrl.monoOccurrence.removeListener('exMenuItemActionDataUpdate', onMenuItemDataUpdate)
                vm.monoOccurrenceCtrl.monoOccurrence.removeListener('softDataUpdate', onMenuItemDataUpdate)
            }

        }

        vm.isBouton = vm.dataType instanceof BoutonDataType;
        vm.hasMenu = vm.menu && !vm.isBouton;

        if (vm.hasMenu) {
            vm.mnemonique = (vm.dataSourceCtrl && vm.dataSourceCtrl.mnemonique) || (vm.monoOccurrenceCtrl && vm.monoOccurrenceCtrl.monoOccurrence.mnemonique);
            vm.libelles = vm.libelles || (vm.dataSourceCtrl && vm.dataSourceCtrl.libelles) || (vm.monoOccurrenceCtrl && vm.monoOccurrenceCtrl.monoOccurrence.libelles);
        }

        if (vm.data && vm.tagMultiLigne && vm.tagMultiLigne !== "") {
            if (vm.setSeparateurEntete && vm.stringToTagSeparateur && vm.tagSeparateurEntete) {
                vm.data[vm.col] = vm.data[vm.col].replace(vm.stringToTagSeparateur, vm.tagSeparateurEntete);
            }

            if (vm?.stringToTag) {
                vm.data[vm.col] = vm.data[vm.col].replaceAll(vm.stringToTag, vm.tagMultiLigne);
            }
        }
    }

    function onMenuItemDataUpdate(data: any) {
        if (data[vm.col] !== undefined) {
            vm.data[vm.col] = data[vm.col];
        }
    }
    function hasCssPrefix() {
        if (vm?.cssprefix) {
            return true;
        }
        return false;
    }
    function getCssPrefix() {
        if (vm?.cssprefix) {
            return vm.cssprefix
        }
        return "-"
    }

    function ouvrirModale() {
        const dialogOptions: IDialogOptions = {
            locals: {
                data: vm.iconTitle,
                lblTitre: vm.iconLibelleTitre
            },
            multiple: true
        };
        DialogTooltip.show(dialogOptions)
    }
}
