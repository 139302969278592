/**
 * Composant représentant un icône chevron qui s'ouvre et se ferme avec une animation.
 *
 * @param {boolean} opened Contrôle l'ouverture et la fermeture du chevron.
 */
import { IComponentOptions, module } from 'angular';
import Binding from '../../helpers/binding.helper';
import TooltipController from './ex-tooltip.controller';

export default module('core.components.ex-tooltip', []).component('exTooltip', TooltipFactory());

function TooltipFactory(): IComponentOptions {
    return {
        bindings: Binding(
            Binding.ICON.OPTIONAL,{
                tooltip:'@?'
            }
        ),
        controller: TooltipController,
        controllerAs: 'vm',
        template: require('./ex-tooltip.html')
    };
}