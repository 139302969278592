import { element, IAugmentedJQuery, IComponentController, IRootScopeService, IScope, ITimeoutService } from 'angular';
import { IFilterLibelle } from '../../filters/ex-libelle.filter';
import { IMenuItem } from '../../services/menu/menu-item.service';
import { IOccurrence } from '../../services/occurrence.service';
import { INotificationHandler } from '../../services/utils/notification-handler.service';
import { IDataSourceController } from '../ex-data-source/ex-data-source.component';
import { IComposantMenu } from '../ex-menu/ex-menu.controller';
import { IComposantMonoOccurrenceEcran } from '../ex-mono-occurrence-ecran/ex-mono-occurrence-ecran.controller';
import { IComposantMonoOccurrence } from '../ex-mono-occurrence/ex-mono-occurrence.controller';
import { IComposantMultiOccurrence } from '../ex-multi-occurrence/ex-multi-occurrence.controller';
import { IMultiOccurrenceClass } from '../../services/multi-occurrence.service';
import { IDataLinker } from '../../services/data-linker.service';
import { IEcranContextController } from '../../behaviors/ex-ecran-context/ex-ecran-context.behavior';
import { IMenuItemFactoryClass } from '../../services/menu/menu-item-factory.service';
import { IMonoOccurrenceClass } from '../../services/mono-occurrence.service';
import { IChangementManager } from '../../services/changement-manager.service';

interface IComposantMenuItem extends IComponentController {
    ecranContextCtrl: IEcranContextController;
    data: any;
    libelles: any;
    monoOccurrenceCtrl: IComposantMonoOccurrence;
    multiOccurrenceCtrl: IComposantMultiOccurrence;
    monoOccurrenceEcranCtrl: IComposantMonoOccurrenceEcran;
    occurrence: IOccurrence;
    dataSourceCtrl: IDataSourceController;
    menuCtrl: IComposantMenu;
    menuItem: IMenuItem;
    busy: boolean;
    lblTitre: string;
    fonction: string;
    fonctionHidden: boolean;
    fonctionDisabled: boolean;
    iconDynamique: boolean;
    iconColorDynamique: boolean;
    disabled: boolean;
    executingAction: boolean;
    fileInput: HTMLInputElement;
    tertiary: any;
    isIconButton(): boolean;
    isActionButton(): boolean;
    isSimpleLinkButton(): boolean;
    isIconLinkButton(): boolean;
    isIcon(): boolean;
    isMenu(): boolean;
    isEmptyMenu(): boolean;
    doAction(event: Event): void;
    getDisabled(menuItem: IMenuItem): boolean;
    getCourant(): boolean;
    getIconDynamique(menuItem: IMenuItem): string;
    getHidden(menuItem: IMenuItem): boolean;
    validate(event: Event): boolean | void;
    couldHaveCompteur(): boolean;
    getClass(): string;
}

/* @ngInject */
export default function MenuItemController(exLibelleFilter: IFilterLibelle,
    changementManager: IChangementManager,
    notificationHandler: INotificationHandler,
    MultiOccurrence: IMultiOccurrenceClass,
    MonoOccurrence: IMonoOccurrenceClass,
    MenuItemFactory: IMenuItemFactoryClass,
    $element: IAugmentedJQuery,
    $scope: IScope,
    $rootScope: IRootScopeService,
    dataLinker: IDataLinker,
    $timeout: ITimeoutService) {
    const vm: IComposantMenuItem = this;

    vm.$onInit = $onInit;
    vm.getDisabled = getDisabled;
    vm.getCourant = getCourant;
    vm.getIconDynamique = getIconDynamique;
    vm.getHidden = getHidden;
    vm.doAction = doAction;
    vm.validate = validate;
    vm.isIconButton = isIconButton;
    vm.isActionButton = isActionButton;
    vm.isSimpleLinkButton = isSimpleLinkButton;
    vm.isIconLinkButton = isIconLinkButton;
    vm.isIcon = isIcon;
    vm.isMenu = isMenu;
    vm.isEmptyMenu = isEmptyMenu;
    vm.couldHaveCompteur = couldHaveCompteur;
    vm.getClass = getClass;

    function $onInit() {
        vm.data = vm.data ||
            (vm.dataSourceCtrl && vm.dataSourceCtrl.data) ||
            (vm.monoOccurrenceCtrl && vm.monoOccurrenceCtrl.monoOccurrence.data) ||
            vm.occurrence instanceof MonoOccurrence && vm.occurrence.data || {};

        vm.occurrence = vm.occurrence ||
            (vm.dataSourceCtrl && vm.dataSourceCtrl.occurrence) ||
            (vm.multiOccurrenceCtrl && vm.multiOccurrenceCtrl.multiOccurrence) ||
            (vm.monoOccurrenceCtrl && vm.monoOccurrenceCtrl.monoOccurrence);

        vm.ecranDetails = vm.occurrence && vm.occurrence.ecranDetails || vm.ecranContextCtrl.ecranDetails
        vm.stateParams = vm.occurrence && vm.occurrence.stateParams || vm.ecranContextCtrl.stateParams
        vm.ecranSourceDetails = vm.occurrence && vm.occurrence.ecranSourceDetails || vm.ecranContextCtrl.ecranSourceDetails

        if (vm.data) {
            dataLinker.link($element, vm.data, vm.stateParams, vm.ecranDetails);
        }

        if (vm.menuItem instanceof MenuItemFactory) {
            vm.menuItem = vm.menuItem.callback(vm.data);
        }

        vm.libelles = vm.libelles ||
            (vm.dataSourceCtrl && vm.dataSourceCtrl.libelles) ||
            (vm.monoOccurrenceCtrl && vm.monoOccurrenceCtrl.monoOccurrence.libelles) ||
            (vm.ecranSourceDetails.libelles);

        const fonctionName = (vm.menuItem.fonction || vm.fonction);
        if (fonctionName) {
            const fonctions = vm.ecranDetails.fonctions;
            const fonction = fonctions[fonctionName.toUpperCase()] || vm.occurrence && fonctions[`${vm.occurrence.mnemonique}.${fonctionName}`.toUpperCase()];
            vm.fonctionHidden = Boolean(fonction && !fonction.flgacc);

            if (fonction && fonction.isChampTransactionnel) {
                vm.fonctionDisabled = !vm.occurrence.isChampModifiable(fonctionName.toLowerCase());
            }
        }

        if (vm.lblTitre) {
            vm.menuItem.lblTitre = vm.lblTitre;
        }

        if (vm.menuCtrl) {
            vm.menuItem.iconButton = false;
        }

        vm.iconDynamique = vm.menuItem.icon instanceof Function;
        vm.iconColorDynamique = vm.menuItem.iconColor instanceof Function;

        if (vm.menuItem.link && vm.menuItem.linkExterne) {
            const listenerName = vm.occurrence instanceof MultiOccurrence ? 'dataListUpdate' : 'dataUpdate';
            //On doit mettre à jour le lien avec les données.
            if (vm.occurrence) {
                vm.occurrence.on(listenerName, updateForageLink);
            }

            $scope.$on('$destroy', () => {
                if (vm?.occurrence) {
                    vm.occurrence.removeListener(listenerName, updateForageLink);
                }
            });
        }

        if (vm.menuItem.importation) {
            $timeout(() => {

                var idRecherche = (vm.menuItem.blocImportation && vm.menuItem.blocImportation !== "") ? vm.menuItem.blocImportation : vm.menuItem.importation.nom;
                vm.fileInput = $element.find("input[type='file']#" + idRecherche)[0] as HTMLInputElement;

                if (vm.fileInput) {
                    vm.fileInput.onchange = (event) => {
                        if (vm.fileInput.files.length) {
                            vm.data[vm.menuItem.importation.nom] = vm.fileInput.files[0]
                            vm.doAction(event)
                            vm.data[vm.menuItem.importation.nom] = undefined
                        }
                    }
                }
            })
        }
    }

    function getDisabled(menuItem: IMenuItem) {
        return vm.fonctionDisabled || getDisabledValue(menuItem);
    }

    function getDisabledValue(menuItem: IMenuItem) {
        if (vm.disabled) {
            return vm.disabled;
        }

        if (menuItem.disabled instanceof Function) {
            return menuItem.disabled(vm.data);
        } else {
            return menuItem.disabled;
        }
    }

    function getCourant() {
        if (vm.menuItem.courant instanceof Function) {
            return vm.menuItem.courant(vm.data);
        } else {
            return vm.menuItem.courant;
        }
    }

    function getHidden(menuItem: IMenuItem) {
        if (vm.fonctionHidden) {
            return true;
        } else if (menuItem.hidden instanceof Function) {
            return menuItem.hidden(vm.data);
        } else if (menuItem.visible instanceof Function) {
            return !menuItem.visible(vm.data);
        } else {
            return menuItem.hidden || menuItem.visible;
        }
    }

    function getIconDynamique(menuItem: IMenuItem) {
        return (menuItem.icon as Function)(vm.data);
    }

    function doAction(event: Event) {
        // Si le bouton est l'étape courante, l'action ne s'applique pas
        if (vm.getCourant()) {
            return;
        }

        if (!validate(event) && !vm.executingAction) {
            vm.executingAction = true;

            let result = vm.menuItem.action(event, vm.data, vm.occurrence);

            // On supporte les actions asynchrones
            if (result && result.then) {
                vm.busy = true;
                result.catch((error: any) => {
                    if (error && error.interventionRequise) {
                        notificationHandler.erreur({ error, confirmAction: () => doAction(event) });
                    } else {
                        throw error;
                    }
                }).finally(() => {
                    vm.busy = false;
                    vm.executingAction = false;
                    //si on execute une importation on nettoye le fichier dans le cas de faire l'upload du meme fichier
                    if (vm?.fileInput?.value) {
                        vm.fileInput.value = ""
                    }
                });
            } else {
                vm.executingAction = false;
            }
        }
    }

    function validate(event: Event) {
        if (vm.getDisabled(vm.menuItem)) {
            return false;
        }

        if (vm.menuItem.link) {
            localStorage.removeItem('forageFromMenu');
            const multiTab = vm?.multiOccurrenceCtrl?.multiOccurrence
            if (multiTab && vm?.stateParams?.srccod && multiTab.bloc) {
                if (multiTab.isTab && multiTab.tabSelected) {
                    const tabSelected = vm.stateParams.srccod + "-" + multiTab.bloc;
                    let tabsSelected = localStorage.getItem("forageTabSelected")
                        ? JSON.parse(localStorage.getItem("forageTabSelected"))
                        : [];

                    if (!tabsSelected.includes(tabSelected)) {
                        tabsSelected.push(tabSelected);
                        localStorage.setItem("forageTabSelected", JSON.stringify(tabsSelected));
                    }
                }
            }
        }
        const isOccurrenceInError = hasChangementsNonSauvegardesOccurrencePresente(event, vm.menuItem);
        const hasErreurFormulairesNonSauvegarde =
            vm.menuItem.saveFirst && vm.monoOccurrenceEcranCtrl && changementManager.getPremierFormulaireNonSauvegarde(vm.monoOccurrenceEcranCtrl.monoOccurrence.element);

        if (typeof (vm.menuItem.validation) === "undefined") {
            //l'écran provient d'un forage, donc mettre le flag pour réinitialiser les critères suggères
            sessionStorage.setItem("ForageBack", "0")
            setTimeout(() => {
                //executer le broadcast seulement pour les boutons de forage qui n'ont pas des pastilles cr0011
                if (vm && vm.isIconLinkButton() && vm.stateParams && vm.stateParams.srccod === "CR0011") {
                    $rootScope.$broadcast("ex-criteres-suggeres.setDataInDelay");
                }
            }, 500);
        }

        if (vm.menuItem.validation) {
            const message = vm.menuItem.validation(vm.data);

            if (message) {
                event.preventDefault();
                notificationHandler.erreur({
                    lblTitre: vm.menuItem.lblTitreValidation,
                    lblMessage: exLibelleFilter(message, vm.libelles)
                });

                return true;
            }
        } else if (isOccurrenceInError || hasErreurFormulairesNonSauvegarde) {
            event.preventDefault();
            const lblMessage = isOccurrenceInError ? 'G_MSG_ENREG_MODIF_PARENT' : 'G_MSG_ENREG_MODIFS';

            notificationHandler.erreur({
                lblTitre: 'G_LBL_MOD_ERREUR_TITRE',
                lblMessage
            });

            return true;
        }
    }

    function hasChangementsNonSauvegardesOccurrencePresente(event: Event, menuItem: IMenuItem) {
        return hasMonoOccurrenceEcranChangementsNonSauvegardes(menuItem) || hasMultiOccurrenceChangementsNonSauvegardes(event, menuItem);
    }

    function hasMonoOccurrenceEcranChangementsNonSauvegardes(menuItem: IMenuItem) {
        return menuItem.saveFirst && vm.monoOccurrenceEcranCtrl && vm.monoOccurrenceEcranCtrl.monoOccurrence.hasChangementsNonSauvegardes(vm.monoOccurrenceEcranCtrl.formData)
    }

    function hasMultiOccurrenceChangementsNonSauvegardes(event: Event, menuItem: IMenuItem) {
        const menuContainerId = $element.closest('.md-open-menu-container').attr('id');

        // Dans le cas d'une action de carte de multiOccurrence, on s'assure qu'aucune donnée reste à enregistrer dans le MultiOccurrence
        return menuItem.saveFirst && (
            (vm.occurrence instanceof MultiOccurrence && !vm.multiOccurrenceCtrl && element(`[aria-owns="${menuContainerId}"]`).closest('ex-card').find('form.ng-dirty').length) ||
            (vm.occurrence instanceof MonoOccurrence && vm.multiOccurrenceCtrl && vm.occurrence.hasChangementsNonSauvegardes(vm.data))
        )
    }

    function updateForageLink() {
        const newDestination = vm.menuItem.action(vm.data, vm.stateParams);
        $element.find('a[href]').attr('href', newDestination);
    }

    function couldHaveCompteur() {
        return vm.getCompteur() != null;
    }

    function getClass() {
        if (vm.menuItem.class instanceof Function) {
            return vm.menuItem.class(vm.data);
        } else {
            return vm.menuItem.class;
        }
    }

    function isIconButton() {
        return Boolean(
            !vm.menuItem.link &&
            !vm.menuItem.importation &&
            !vm.menuItem.listeMenuItem &&
            vm.menuItem.iconButton &&
            !vm.menuItem.noAction
        );
    }

    function isActionButton() {
        return Boolean(
            !vm.menuItem.link &&
            !vm.menuItem.importation &&
            !vm.menuItem.listeMenuItem &&
            !vm.menuItem.iconButton &&
            !vm.menuItem.noAction
        );
    }

    function isSimpleLinkButton() {
        return Boolean(
            vm.menuItem.link &&
            !vm.menuItem.iconButton &&
            !vm.menuItem.noAction
        );
    }

    function isIconLinkButton() {
        return Boolean(
            vm.menuItem.link &&
            vm.menuItem.iconButton
        );
    }

    function isIcon() {
        return Boolean(
            vm.menuItem.noAction &&
            vm.menuItem.icon
        );
    }


    function isMenu() {
        return Boolean(
            vm.menuItem.listeMenuItem &&
            vm.menuItem.listeMenuItem.length
        );
    }

    function isEmptyMenu() {
        return vm.menuItem.listeMenuItem.every((menuItem: IMenuItem) => vm.getHidden(menuItem));
    }

    vm.getCompteur = function getCompteur() {
        return vm.menuItem.compteur instanceof Function ? vm.menuItem.compteur(vm.data) : vm.menuItem.compteur
    }
}
