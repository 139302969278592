import {
    IAugmentedJQuery,
    IComponentController,
    IFormController,
    IRootScopeService,
    IScope,
    ITimeoutService
} from 'angular';
import { IMultiOccurrence, IMultiOccurrenceClass, IMultiOccurrenceOptions } from '../../services/multi-occurrence.service';
import { IEcranContextController } from '../../behaviors/ex-ecran-context/ex-ecran-context.behavior';
import { IDataLinker } from '../../services/data-linker.service';
import { IFocusService } from '../../behaviors/ex-focus/ex-focus.behavior';
import { IApiError } from '../../interfaces/api-error.interface';
import { IEtatStore } from '../../services/etat-store.service';
import { INotificationHandler } from '../../services/utils/notification-handler.service';
import { IComposantMonoOccurrenceEcran } from '../ex-mono-occurrence-ecran/ex-mono-occurrence-ecran.controller';
import { IDefaultsService } from '../../services/utils/defaults.service';
import { IComposantMultiOccurrence } from '../ex-multi-occurrence/ex-multi-occurrence.controller';
import { IFormulaireItem } from '../../services/formulaire/formulaire-item.service';
import AxesAffichage from '../../classes/axe-affichage.class';
import { IFiltre, IFiltreClass } from '../../services/filtre.service';
import { IOperateurService } from '../../services/operateur.service';
import { IProfil } from '../../resources/profil.resource';

export interface IComposantCriteresSuggeres extends IComponentController {
    nouveauxCriteresSuggeres: any;
    monoOccurrenceEcranCtrl: IComposantMonoOccurrenceEcran;
    multiOccurrenceCtrl: IComposantMultiOccurrence;
    multiOccurrence: IMultiOccurrence;
    multiOccurrences: Array<IMultiOccurrence>;
    multiOccurrencesAdditionnels: Array<IMultiOccurrence>;
    formCtrl: IFormController;
    multiOccurrencesBusy: Array<boolean>;
    ecranContextCtrl: IEcranContextController;
    options: ICriteresSuggeresOptions;
    multiOccurrencesReady: boolean;
    isFirstSearch: boolean;
    multiOccurrenceOptionsPartage: IMultiOccurrenceOptions;

    isSomeMultiOccurrenceOccurrenceBusy(): boolean;
    isEveryMultiOccurrencesReady(): boolean;
    annulerCriteresSuggeres(): void;
    reinitialiserCriteresSuggeres(): void;
    appliquerCriteresSuggeres(updateCriteres?: boolean): void;
    retourSaisieCriteresSuggeres(): void;
    hasNoDataList(): boolean;
    checkIfFormHasValues(): boolean;
}

export interface ICriteresSuggeresOptions {
    iconFab?: boolean;
    showAnnuler?: boolean;
    showFiltresInline?: boolean;
    classBoutonsPrincipaux?: string;
    classBoutonsSecondaire?: string;
    lblTitre?: string;
    iconReinitialiser?: string;
    iconRechercher?: string;
    iconRetour?: string;
    disableSearchOnEmptyForm?: boolean;
}

const CRITERES_SUGGERES_SELECTOR = '.ex-criteres-suggeres';
const DEFAULT_OPTIONS_CRITERES_SUGGERES = {
    iconFab: true,
    lblTitre: 'G_LBL_CRITERES_SUGGERES',
    showAnnuler: true,
    classBoutonsSecondaire: 'md-raised ex-button-secondary',
    classBoutonsPrincipaux: 'md-raised ex-button ex-button-action',
    showFiltresInline: false,
    disableSearchOnEmptyForm: false
};

/* @ngInject */
export default function CriteresSuggeresController(dataLinker: IDataLinker,
    etatStore: IEtatStore,
    defaults: IDefaultsService,
    exFocus: IFocusService,
    notificationHandler: INotificationHandler,
    $element: IAugmentedJQuery,
    $scope: IScope,
    $rootScope: IRootScopeService,
    Filtre: IFiltreClass,
    Operateur: IOperateurService,
    MultiOccurrence: IMultiOccurrenceClass,
    $timeout: ITimeoutService,
    profil: IProfil) {
    const vm: IComposantCriteresSuggeres = this;

    vm.annulerCriteresSuggeres = annulerCriteresSuggeres;
    vm.reinitialiserCriteresSuggeres = reinitialiserCriteresSuggeres;
    vm.appliquerCriteresSuggeres = appliquerCriteresSuggeres;
    vm.retourSaisieCriteresSuggeres = retourSaisieCriteresSuggeres;
    vm.hasNoDataList = hasNoDataList;
    vm.isSomeMultiOccurrenceOccurrenceBusy = isSomeMultiOccurrenceOccurrenceBusy;
    vm.isEveryMultiOccurrencesReady = isEveryMultiOccurrencesReady;
    vm.checkIfFormHasValues = checkIfFormHasValues;
    vm.isFirstSearch = true;
    vm.$onInit = function $onInit() {
        if (vm?.multiOccurrenceOptionsPartage) {
            vm.multiOccurrenceOptionsPartage.isCriteresuggerePartage = true
            initMultiOccurrence()
        }
        defaults(vm, {
            multiOccurrencesBusy: [],
            nouveauxCriteresSuggeres: {}
        });

        vm.options = {
            ...DEFAULT_OPTIONS_CRITERES_SUGGERES,
            ...(vm.options || {})
        };

        $scope.$watch('vm.multiOccurrence.fonctions.criteresSuggeresVisibles && vm.formCtrl', (newValue, oldValue) => {
            if (newValue !== oldValue && newValue) {
                showCriteresSuggeres();
            }
        });

        $scope.$watch('vm.formCtrl', (newValue) => {
            if (newValue) {
                if (!vm.multiOccurrence.autoFetch && vm.multiOccurrence.hasFiltreOrCritere()) {
                    vm.isFirstSearch = false;
                }

                if (vm.multiOccurrence.autoFetch || !vm.isFirstSearch) {
                    if (!vm.multiOccurrence.fonctions.criteresSuggeresVisibles) {
                        vm.appliquerCriteresSuggeres();
                    }
                } else {
                    vm.isFirstSearch = false;
                    if (!vm.multiOccurrence.hasFiltreOrCritere()) {
                        vm.multiOccurrence.fonctions.criteresSuggeresVisibles = true;
                    }
                }
            }
        });

        const removeInitWatcher = $scope.$watch('vm.isEveryMultiOccurrencesReady()', (ready: boolean) => {
            if (ready) {
                if (!vm.multiOccurrence.autoFetch && !vm.multiOccurrence.hasFiltreOrCritere()) {
                    vm.multiOccurrence.fonctions.criteresSuggeresVisibles = true;
                }

                vm.multiOccurrence.on("uqApplySearch", () => {
                    vm.appliquerCriteresSuggeres(false);
                });

                vm.multiOccurrences = [vm.multiOccurrence].concat(vm.multiOccurrencesAdditionnels || []);
                if (vm.multiOccurrences.length > 1) {
                    for (const multiOccurrence of vm.multiOccurrences) {
                        multiOccurrence.criteresSuggeres = vm.multiOccurrence.criteresSuggeres;
                        multiOccurrence.fonctions.reinitialiser = false;
                        multiOccurrence.fonctions.criteresSuggeresVisibles = vm.multiOccurrence.etatSelected.equal(vm.multiOccurrence.etatDefault);
                        multiOccurrence.on("uqApplySearch", () => {
                            vm.appliquerCriteresSuggeres(false);
                        });
                    }
                }

                vm.multiOccurrencesReady = true;
                removeInitWatcher();
            }
        });
        getPmtCleint()
    }

    $timeout(() => {
        //on filtre les multioccurrence en undefined car la securite par fonctionne ne permet pas de faire l'initialisation
        if (vm.multiOccurrencesAdditionnels) {
            vm.multiOccurrencesAdditionnels = vm.multiOccurrencesAdditionnels.filter(multiOccurrence => typeof (multiOccurrence) !== "undefined")
        }
    }, 0)

    $rootScope.$on("ex-criteres-suggeres.setDataInDelay", () => {
        var flag = sessionStorage.getItem("ForageBack")
        if (flag && flag !== "1" && !vm.multiOccurrence.fonctions.rechercheCollapsable) {
            setTimeout(() => {
                reinitialiserCriteresSuggeres();
                toggleCriteresSuggeresVisibles(false);
            }, 500);
        }
        sessionStorage.setItem("ForageBack", null)
    })

    function annulerCriteresSuggeres() {
        vm.multiOccurrence.fonctions.criteresSuggeresVisibles = false;
        $rootScope.$broadcast('exGridReajusterLargeurColonne');
    }
    $rootScope.$on('annulerActionCriteresSuggeres', (event) => {
        if (!event.defaultPrevented) {
            annulerCriteresSuggeres();
        }
    })
    function reinitialiserCriteresSuggeres() {
        // On supprime les champs tout en gardant le même objet
        viderNouveauCriteresSuggeres();
        // On ajouter les valeurs de l'écran
        Object.assign(vm.nouveauxCriteresSuggeres, vm.ecranContextCtrl.ecranDetails.valeurs);

        // On initialise les données avec les défauts
        vm.multiOccurrence.criteresSuggeres.initFormData(vm.nouveauxCriteresSuggeres, vm.multiOccurrence.dataTypes);
        vm.formCtrl.$setPristine();
        vm.formCtrl.$setUntouched();
        updateCriteresSuggeres()
        vm.multiOccurrence.etatSelected.filtres = [...vm.multiOccurrence.etatDefault.filtres]
        toggleCriteresSuggeresVisibles(true);

        exFocus($element.find(CRITERES_SUGGERES_SELECTOR));

        if (vm.multiOccurrencesAdditionnels) {
            for (const multiOccurrence of vm.multiOccurrences) {
                multiOccurrence.reinitialiser();
                multiOccurrence.reinitialiserPastillefiltre();
                multiOccurrence.reinitialiserDataOnglet();
            }
        }
        //envoyer message au composant dropdownlist etat pour reinitialiser au "Aucun"
        $rootScope.$broadcast('vm.multiOccurrence.etat.reinitialiser');
        //on enleve les valeurs par defaut de la selection multiple s'il y en a
        vm.multiOccurrence.deleteParams("criteremultiple", true)
        vm.multiOccurrence.viderValuesSelectionMultiple(vm.multiOccurrence.srccod).then(() => {
            setTimeout(() => {
                $rootScope.$broadcast('ex.input.lov.selection.multiple.valide.storage');
            }, 100)
        })
    }

    $scope.$on('multiOccurrence.fetchDataList.updateGrid', () => {
        setTimeout(() => {
            // On applique le fetchDataList pour tous les multi
            vm.multiOccurrences.forEach((multiOccurrence, index) => {
                multiOccurrence.preventDataListUpdate = true;
                //si le multi permet l'autoFetch on execute le fetchDataList
                if (vm.multiOccurrence.autoFetch) {
                    multiOccurrence.fonctions.criteresSuggeresVisibles = false
                    multiOccurrence.fetchDataList().finally(() => {
                        multiOccurrence.preventDataListUpdate = false;
                        vm.multiOccurrencesBusy[index] = false;
                    })
                }
            });
        })
    });
    $scope.$on('ex-criteres-suggeres-update-calendrier', (event, data) => {
        if (!event.defaultPrevented) {
            event.defaultPrevented = false;
            if (data?.date && vm.multiOccurrence.axesAffichage.axeActive.axeNom == AxesAffichage.AXES_AFFICHAGE.LIST) {
                updateFiltreCalendrier(data.date)
            }
        }
    });
    function appliquerCriteresSuggeres(updateCriteres: boolean = true) {
        // Permet aux masques de saisie comme le NAS de s'appliquer correctement
        setTimeout(() => {
            vm.formCtrl.$setSubmitted();
            if (vm.formCtrl.$invalid) return

            removeInvalidCritere();
            getCriteresSuggeresMultipleListe(vm.multiOccurrence)

            if (updateCriteres) {
                updateCriteresSuggeres();
            }

            if (anyMultiOccurrenceHasCritere() || vm.multiOccurrence.autoFetch) {
                vm.multiOccurrencesBusy = [...vm.multiOccurrences.map(() => true)];
                dataLinker.link(
                    $element,
                    vm.nouveauxCriteresSuggeres,
                    vm.ecranContextCtrl.stateParams,
                    vm.ecranContextCtrl.ecranDetails
                );

                // On applique les critères sur l'état sélectionné
                vm.multiOccurrences.forEach((multiOccurrence, index) => {
                    //si le multi fait partie d'un ex-tab, on valide s'il a été selectionné
                    if (multiOccurrence?.isTab && !multiOccurrence?.tabSelected) {
                        vm.multiOccurrencesBusy[index] = false;
                        etatStore.save(multiOccurrence);
                    } else {
                        multiOccurrence.preventDataListUpdate = true;
                        //On récupère les données et on ferme les critères de recherche
                        if (vm.multiOccurrence.autoFetch || multiOccurrence.hasFiltreOrCritere()) {
                            multiOccurrence.fonctions.criteresSuggeresVisibles = false
                            multiOccurrence.fetchDataList().then(() => {
                                $rootScope.$broadcast('exGridReajusterLargeurColonne');
                                etatStore.save(multiOccurrence);
                            }).catch((error: IApiError) => {
                                // Ceci est temporaire, le temps que Marie-Josée et jeff norme la présentation des erreurs pour l'application.
                                // Pour le moment, ce que l'on veut est d'afficher un message en dialogue...
                                // Ceci sera présenté seulement pour les erreurs de validation de paramètres (VALIDATION_PARAMETRE)
                                if (multiOccurrence.dataListErrorClient && (multiOccurrence.dataListError || multiOccurrence.initError)) {
                                    notificationHandler.erreur({
                                        lblTitre: 'G_MSG_ERREUR_PARAMETRE',
                                        lblMessage: error.data.message,
                                        error
                                    });
                                } else if (!multiOccurrence.dataListErrorClient && (multiOccurrence.dataListError || multiOccurrence.initError)) {
                                    notificationHandler.erreur({
                                        error,
                                        lblTitre: 'G_LBL_MOD_ERREUR_TITRE',
                                        lblMessage: 'G_MSG_CHARG_DONNEES',
                                        confirmAction() {
                                            appliquerCriteresSuggeres();
                                        }
                                    });
                                }
                            }).finally(() => {
                                multiOccurrence.preventDataListUpdate = false;
                                vm.multiOccurrencesBusy[index] = false;
                            });
                        } else {
                            multiOccurrence.dataList = [];
                        }
                    }
                });
            } else {
                for (const multiOccurrence of vm.multiOccurrences) {
                    multiOccurrence.dataList = [];
                }
            }
            const date = vm?.multiOccurrence?.etatSelected?.criteresSuggeresData?.date
            if (date) {
                setTimeout(() => {
                    vm.multiOccurrence.emit("ex-calendrier.setDate", { newDate: date, oldDate: undefined });
                    updateFiltreCalendrier(date)
                }, 500);
            }
        })
    }
    function updateFiltreCalendrier(date: any) {
        if (vm.multiOccurrence.axesAffichage.axeActive.axeNom == AxesAffichage.AXES_AFFICHAGE.LIST) {
            let colonnes: any = null;
            if (Array.isArray(vm?.multiOccurrence?.axesAffichage?.axes)) {
                //on cherche les colonnes du calendrier afin de modifier la date du filtre en mode view list
                vm.multiOccurrence.axesAffichage.axes.forEach((el: any) => {
                    if (el?.axeNom === AxesAffichage.AXES_AFFICHAGE.CALENDAR && el?.options) {
                        colonnes = (el?.options?.colonneDateDebut + "," + el?.options?.colonneDateFin).toString()
                    }
                })

                const filtreDate = vm.multiOccurrence.etatSelected.filtres.find((filtre: IFiltre) => filtre.colonne === colonnes);
                const filtreDateAffichable = vm.multiOccurrence.etatSelected?.criteresSuggeresData?.date
                //lorqu'on est en mode liste et qu'on a une filtre de date visible, on change la date debut et fin 
                if (filtreDate && filtreDateAffichable && colonnes) {
                    const index = vm.multiOccurrence.etatSelected.filtres.indexOf(filtreDate);
                    const nouveauFiltre = new Filtre({
                        colonne: [colonnes.split(",")],
                        operateur: Operateur.ENTRE,
                        valeur: date,
                        valeur2: date,
                        visible: false
                    });
                    vm.multiOccurrence.etatSelected.replaceFiltre(nouveauFiltre, index);
                }
            }
        }
        vm.multiOccurrence.fetchDataList({ forceFetch: true })
        vm.multiOccurrence.emit('etatUpdate');
    }

    function updateCriteresSuggeres() {
        for (const multiOccurrence of vm.multiOccurrences) {
            multiOccurrence.etatSelected.criteresSuggeresData = { ...vm.nouveauxCriteresSuggeres };
        }
        vm.multiOccurrence.etatSelected.criteresSuggeresData = { ...vm.nouveauxCriteresSuggeres };
    }

    function getCriteresSuggeresMultipleListe(multiOccurrence: any) {
        if (multiOccurrence?.etatSelected) {
            multiOccurrence.etatSelected.criteresMultiplesListe = [];
        }
        //on ajoute les champs de selection multiple afin d'afficher la pastille non supprimable
        Object.values(multiOccurrence.criteresSuggeres.liste).forEach((el: any) => {
            if (el?.lovSelectionMultiple) {
                if (!multiOccurrence?.etatSelected?.criteresMultiplesListe) {
                    multiOccurrence.etatSelected.criteresMultiplesListe = [];
                }
                if (!multiOccurrence.etatSelected.criteresMultiplesListe.includes(el)) {
                    multiOccurrence.etatSelected.criteresMultiplesListe.push(el)
                }
            }
        })
        if (multiOccurrence.etatSelected.criteresMultiplesListe.length > 0) {
            vm.options.showFiltresInline = true;
        }
        //message entre composants
        $rootScope.$broadcast("ex.liste.pastille.update.selection.multiple");
    }

    $scope.$on('ex.criteres-suggeres.set.criteres.multiple', () => {
        getCriteresSuggeresMultipleListe(vm.multiOccurrence)
    })

    function removeInvalidCritere() {
        vm.formCtrl.$getControls().forEach(control => {
            if (!control.$dirty) return

            const modelValue = control.$modelValue;

            if (modelValue === null || typeof modelValue === 'string' && modelValue.length === 0) {
                delete vm.nouveauxCriteresSuggeres[control.$name];
            }
        });
    }

    function isSomeMultiOccurrenceOccurrenceBusy() {
        return vm.multiOccurrencesBusy.some(isBusy => isBusy);
    }

    function showCriteresSuggeres() {
        if (vm.formCtrl.$valid) {
            vm.multiOccurrences[0].criteresSuggeres.initFormData(vm.multiOccurrences[0].etatSelected.criteresSuggeresData, vm.multiOccurrences[0].dataTypes)
            remplacerNouveauCriteresSuggeres(vm.multiOccurrences[0].etatSelected.criteresSuggeresData);

            // Si on retourne au formulaire, on doit pouvoir réinitialiser sans avoir à changer le formulaire
            vm.formCtrl.$setDirty();
            exFocus($element.find(CRITERES_SUGGERES_SELECTOR));
        } else {

            if (vm.formCtrl && vm.multiOccurrence?.srccod) {
                //on valide si la lov de sélection multiple a la propriete required afin de le mettre en true
                vm.multiOccurrence.getValuesItemsSelectionMultiple(vm.multiOccurrence.srccod)
                    .then((champsSelectionMultiple: any[]) => {
                        if (Array.isArray(champsSelectionMultiple) && champsSelectionMultiple.length > 0) {
                            champsSelectionMultiple.forEach((el: any) => {
                                if (el?.col && vm.formCtrl[el.col] && el.required) {
                                    vm.formCtrl[el.col].$setValidity('required', true);
                                    vm.formCtrl[el.col].$setValidity('captureInvalide', true);
                                }
                            });
                        }
                        //on re-fait la validation du form car la fonctionne remplacerNouveauCriteresSuggeres fait
                        //un call au watch
                        vm.formCtrl.$setSubmitted();
                        if (!vm.formCtrl.$valid) {
                            reinitialiserCriteresSuggeres();
                        }
                    })
                    .catch((error: any) => { });
            } else {
                if (!vm.formCtrl?.$valid) {
                    reinitialiserCriteresSuggeres();
                }
            }
        }
    }

    function viderNouveauCriteresSuggeres() {
        $rootScope.$broadcast('exReinitialiserCriteresSuggeres');

        Object.keys(vm.nouveauxCriteresSuggeres).forEach((champ: string) => {
            vm.nouveauxCriteresSuggeres[champ] = undefined;
        });
    }

    function remplacerNouveauCriteresSuggeres(criteres: any) {
        viderNouveauCriteresSuggeres();
        Object.assign(vm.nouveauxCriteresSuggeres, criteres);
    }

    function retourSaisieCriteresSuggeres() {
        toggleCriteresSuggeresVisibles(true);
        getPmtCleint()
    }

    function hasNoDataList() {
        return vm.multiOccurrences.every((multiOccurrence: IMultiOccurrence) => {
            return !multiOccurrence.dataList;
        });
    }

    function isEveryMultiOccurrencesReady() {
        //on initialise le multiOccurrence dans le cas d'avoir un multiOccurrence partage
        if (!vm?.multiOccurrence && vm.multiOccurrenceOptionsPartage) {
            initMultiOccurrence()
        }

        return vm.multiOccurrence &&
            vm.multiOccurrence.initialized &&
            (
                !vm.monoOccurrenceEcranCtrl ||
                !vm.multiOccurrencesAdditionnels ||
                isEveryMultiOccurrencesAdditionnelsReady()
            );
    }

    function isEveryMultiOccurrencesAdditionnelsReady() {
        return vm.multiOccurrencesAdditionnels.length && vm.multiOccurrencesAdditionnels.every(multiOccurrence => (multiOccurrence) ? multiOccurrence.initialized : false);
    }

    function toggleCriteresSuggeresVisibles(target: boolean) {
        vm.multiOccurrence.fonctions.criteresSuggeresVisibles = target;
    }

    function checkIfFormHasValues() {
        return vm.multiOccurrences[0].criteresSuggeres.flatListe.filter((item: IFormulaireItem) => !item.affichage && item.col).some((item: IFormulaireItem) => {
            return vm.nouveauxCriteresSuggeres[item.col] != null && vm.nouveauxCriteresSuggeres[item.col].length;
        });
    }

    function anyMultiOccurrenceHasCritere() {
        return vm.multiOccurrences.some(multiOccurrence => multiOccurrence.hasFiltreOrCritere());
    }
    //creér le multiOccurrence dans le cas d'avoir une critere partage
    function initMultiOccurrence() {
        const options: IMultiOccurrenceOptions = {
            ...vm.multiOccurrenceOptionsPartage,
            stateParams: vm.ecranContextCtrl.stateParams,
            ecranDetails: vm.ecranContextCtrl.ecranDetails,
            ecranSourceDetails: vm.ecranContextCtrl.ecranSourceDetails
        }
        vm.multiOccurrence = new MultiOccurrence(options)
    }

    function getPmtCleint() {
        if (vm.multiOccurrence) {
            vm.multiOccurrence.getPmtcleint(vm.multiOccurrence.srccod, profil.preferences.usrcleint).then((result) => {
                if (result) {
                    vm.multiOccurrence.pmtcleint = result
                }
                $rootScope.$broadcast("ex.input.lov.selection.multiple.all.valide.storage");
            })
        }
    }
}
