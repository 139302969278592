import { IAugmentedJQuery, IComponentController, IFormController, IScope, ITimeoutService } from 'angular';
import * as moment from 'moment/moment';
import { IKeyCodes } from '../../constants/key-codes.constant';
import { IDateDataType } from "../../services/data-types/date-data-type.service";

interface IComposantInputDate extends IComponentController {
    nameElement: string;
    readonly: boolean;
    formCtrl: IFormController;
    value: string;
    minDate: Function | Date;
    minDateValue: Date;
    maxDate: Date;
    maxDateValue: Function | Date;
    data: any;
    titre: string;
    localeFormatProvider: any;
    dataType: IDateDataType;
    format: string;
    formatRegex: RegExp;
    change(valeurs: { $value: string }): void;
    onChange(): void;
    onBlur(): void;
    tooltip:string;
}
const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD';
/* @ngInject */
export default function InputDateController($scope: IScope,
    $element: IAugmentedJQuery,
    keyCodes: IKeyCodes,
    $timeout: ITimeoutService) {
    const vm: IComposantInputDate = this;

    vm.$onDestroy = $onDestroy;
    vm.onChange = onChange;
    vm.onBlur = onBlur;

    vm.$onInit = function $onInit() {
        vm.format = vm.dataType && vm.dataType.params && vm.dataType.params.format && vm.dataType.params.format.toUpperCase() || DEFAULT_DATE_FORMAT;
        vm.formatRegex = defineFormatRegex(vm.format);
        vm.messageFormat = vm.format.replace(/Y/g, "A").replace(/D/g, "J")

        vm.localeFormatProvider = buildDateLocaleProvider();

        if (vm.minDate || vm.maxDate) {
            $scope.$watch('vm.data', () => {
                setDateRange();
            }, true);
        }

        // On ajoute un raccourci pour l'ouverture du calendrier
        $element.on('keydown', (event: JQueryEventObject) => {
            if ((event.which === keyCodes.UP || event.which === keyCodes.DOWN) && !event.shiftKey && !event.ctrlKey && !event.metaKey && !$element.find('md-input-container.md-datepicker-open').length) {
                $element.find(".md-datepicker-button").click();
            }
        });
    }

    function $onDestroy() {
        $element.off();
    }

    function onChange() {
        vm.formCtrl[vm.nameElement].$validate();
        if ((!vm.value || vm.formCtrl[vm.nameElement].$valid) && vm.change) {
            vm.change({ $value: vm.value })
        }
        if (vm.onValueChange) {
            $timeout(() => {
                vm.onValueChange(vm.data)
            })
        }
    }

    function onBlur() {
        const input = $element.find('input');
        const value = String(input.val());

        if (value !== vm.data[vm.nameElement]) {
            if (value) {
                let cleanedDate = value.replace(/[^0-9]/g, '-');
                const cleanerDate = cleanedDate.replace(new RegExp('-', 'g'), '');
                const cleanedDefaultFormat = DEFAULT_DATE_FORMAT.replace(new RegExp('-', 'g'), '');

                if (vm.format.indexOf('YYYY') === -1 && cleanerDate.length !== cleanedDefaultFormat.length) {
                    const dateWithYear = `2001${cleanerDate}`.replace(/(\d{4})(\d{2})(\d{2})/, "$1-$2-$3");
                    cleanedDate = moment(dateWithYear).format(`YYYY-${vm.format}`);
                }

                const date = moment(cleanedDate, DEFAULT_DATE_FORMAT);
                const valid = date.isValid();
                const formattedValue = date.format(DEFAULT_DATE_FORMAT);

                vm.formCtrl[vm.nameElement].$setValidity('date', valid);
                vm.value = date.format(vm.format)

                if (formattedValue !== vm.formCtrl[vm.nameElement].$viewValue) {
                    $timeout(() => {
                        vm.formCtrl[vm.nameElement].$setValidity('date', true);
                        vm.formCtrl[vm.nameElement].$setValidity('valid', true);
                        vm.formCtrl[vm.nameElement].$setViewValue(formattedValue);
                    });
                }

                if (valid && value !== date.format(vm.format)) {
                    input.val(date.format(vm.format));
                }
                vm.formCtrl[vm.nameElement].$setValidity('valid', valid);
                vm.formCtrl[vm.nameElement].$setValidity('date', valid);
                vm.formCtrl[vm.nameElement].$setViewValue(formattedValue);

            } else {
                vm.formCtrl[vm.nameElement].$setValidity('date', true);
                vm.formCtrl[vm.nameElement].$setViewValue(value);
            }
        }
    }

    function setDateRange(): void {
        const minDate = vm.minDate instanceof Function ? vm.minDate(vm.data) : vm.minDate;
        const maxDate = vm.maxDate instanceof Function ? vm.maxDate(vm.data) : vm.maxDate;

        const formattedMinDate = new Date(minDate);
        const formattedMaxDate = new Date(maxDate);

        if (formattedMinDate.getHours() !== 0) {
            formattedMinDate.setHours(24, 0, 0, 0);
        }

        if (formattedMaxDate.getHours() !== 0) {
            formattedMaxDate.setHours(24, 0, 0, 0);
        }

        vm.minDateValue = formattedMinDate;
        vm.maxDateValue = formattedMaxDate;
    }

    function buildDateLocaleProvider(): any {
        return {
            isDateComplete: (value: string) => {
                value = value.trim();

                return vm.formatRegex.test(value);
            },
            formatDate: (value: string) => {
                if (value) {
                    return moment(value).format(vm.format);
                } else {
                    return null;
                }
            },
            parseDate: (value: string) => {
                if (value) {
                    const formattedValue = moment(value, vm.format, true);
                    if (!vm.formCtrl[vm.nameElement].$modelValue) {
                        vm.formCtrl[vm.nameElement].$setViewValue(value);
                    }
                    return formattedValue.isValid() ? formattedValue.toDate() : null;
                } else {
                    return null;
                }
            }
        }
    }

    function defineFormatRegex(format: string): RegExp {
        return new RegExp(format
            .toUpperCase()
            .replace(/([ .,]+|[/-])/, '([ .,]+|[/-])')
            .replace('YYYY', '\\d{4}')
            .replace('DD', '\\d{2}')
            .replace('MM', '\\d{2}'))
    }
}
