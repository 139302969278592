import * as angular from 'angular';
import {
    IAugmentedJQuery,
    IHttpResponse,
    IHttpService,
    IPromise,
    IQService,
    IRootScopeService,
    IScope,
    ITimeoutService,
    module
} from 'angular';
import { IApiConfig } from '../interfaces/api-config.interface';
import { IEtatPredefini } from '../interfaces/etat-predefini.interface';
import { IReglesValidation } from '../interfaces/regles-validation.interface';
import { ISchemaItem, ISchemas } from '../interfaces/schemas.interface';
import { IChampsEvenementsResource } from '../resources/champs-evenements.resource';
import { IChampsTransactionnels, IChampsTransactionnelsResource } from '../resources/champs-transactionnels.resource';
import { IEcranDetailsResourcesEntite } from '../resources/ecran-details.resource';
import { IEtatsResourcesClass, IEtatsResourcesEntite } from '../resources/etat.resource';
import { IProfil } from '../resources/profil.resource';
import { IMessages, ISourceDetails } from '../resources/source-details.resource';
import { IValeursDefautResource } from '../resources/valeurs-defaut.resource';
import { IBooleanDataTypeClass } from './data-types/boolean-data-type.service';
import { IDataTypeClass, IDataTypeMap } from './data-types/data-type.service';
import { IImputationDataTypeClass } from './data-types/imputation-data-type.service';
import { ILovDataTypeClass } from './data-types/lov-data-type.service';
import { IDownloadManagerService } from './download-manager.service';
import { IEtatStore } from './etat-store.service';
import { IFiltre, IFiltreClass, IFiltreOptions } from './filtre.service';
import {
    IFormulaireItem,
    IFormulaireItemClass,
    IFormulaireItemResourceParamsFonction
} from './formulaire/formulaire-item.service';
import { FormulaireElement, IFormulaire } from './formulaire/formulaire.service';
import { IMenuItem, IMenuItemClass, IMenuItemOptions } from './menu/menu-item.service';
import { IMenu, IMenuClass } from './menu/menu.service';
import { IOccurrence, IOccurrenceClass } from './occurrence.service';
import { IPaginationClass, IPaginationOptions } from './pagination.service';
import { ISourceDetailsManager } from './source-details-manager.service';
import { ITriClass } from './tri.service';
import { IMonoOccurrence } from './mono-occurrence.service';
import { IComportementClass, IComportementMap } from './comportements.service';
import AxesAffichage, { IAxesAffichageOptions } from "../classes/axe-affichage.class";
import { IParametresSecuriteService } from './parametres-securite.service';
import { IRaccourciConfig } from './raccourci.service';
import { IData, IDataLinker } from './data-linker.service';
import { IFonctionsEdition, IFonctionsEditionClass } from './fonctions-edition.service';
import { IBoutonDataTypeClass } from './data-types/bouton-data-type.service';
import { IChangementManager } from './changement-manager.service';
import { IFocusService } from '../behaviors/ex-focus/ex-focus.behavior';
import { IFormulaireGroupe, IFormulaireGroupeClass } from './formulaire/formulaire-groupe.service';
import { IMenuItemEcran, IMenuItemEcranClass } from './menu/menu-item-ecran.service';
import { IMenuItemForage, IMenuItemForageClass } from './menu/menu-item-forage.service';
import { IStateService } from 'angular-ui-router';
import { IFilterEnteteLibelle } from '../filters/ex-entete-libelle.filter';
import { IStatutDataTypeClass } from './data-types/statut-data-type.service';
import { IIndicateurErreurDataTypeClass } from './data-types/indicateur-erreur-data-type.service';
import IResource = angular.resource.IResource;
import IResourceArray = angular.resource.IResourceArray;
import IResourceClass = angular.resource.IResourceClass;
import IResourceOptions = angular.resource.IResourceOptions;
import IResourceService = angular.resource.IResourceService;
import IResourceInterceptor = angular.resource.IResourceInterceptor;
import { IMenuItemFactoryClass, IMenuItemFactoryFactory } from './menu/menu-item-factory.service';
import { ILibellesClass } from './libelles.service';
import isMobile from "../constants/mobile.constant";
import { IEtatsMultipleResourcesClass, IEtatsMultipleResourcesEntite } from '../resources/etat-multiple.resource';

export interface IMultiOccurrenceClass {
    new(options: IMultiOccurrenceOptions): IMultiOccurrence;
}

interface IErrorClient {
    code?: string;
    description: string;
}

export interface IMultiOccurrence extends IOccurrence {
    readonly gtecleref: string;
    readonly etatsPredefinis: Array<IEtatsResourcesEntite>;
    readonly etatDefault: IEtatsResourcesEntite;
    readonly forcerEtatPredefinisDepart: string;
    readonly colonnesCachees: Array<string>;
    readonly champsNonSauvergarderEtat?: Array<string>
    readonly colonnesParametres: IColonnesParametres;
    readonly colonnesFixesGauche: Array<string>;
    readonly colonnesFixesDroite: Array<string>;
    readonly classItems: IClassItems;

    readonly dataTypes: IDataTypeMap;
    readonly navigatePage: string | INavigatePageFunction;
    readonly navigateParams: (rowData: any, multiOccurrence: IMultiOccurrence) => any;
    readonly id: number;
    readonly bris: string;
    readonly brisNiveaux: string;
    readonly brisIcon: string;
    readonly DataResource: IResourceClass<any>;
    readonly resourceParams: any;
    readonly resourceParamsDynamique: IResourceParamsFunction;
    readonly fonctions: IMultiOccurrenceFonctions;
    readonly axesAffichage: AxesAffichage;
    readonly actionsRangeeGauche: IMenu;
    readonly actionsRangeeDroite: IMenu;
    readonly raccourcisRangee: IRaccourciConfig;
    readonly actionsMore: IMenu;
    readonly actionsMoreLigne: IMenu;
    readonly actions: IMenu;
    readonly multiOccurrenceOptionsEnfant: IMultiOccurrenceOptions;
    readonly details: IMultiOccurrenceDetails;
    readonly nomSourceDetails: string;
    readonly hasGridElement: boolean;

    actionsNouveaux: IMenu;
    actionsSelection: IMenu | IMenuItem;
    ready: IPromise<any>;
    dataList: Array<any>;
    // Une copie de référence quand la liste mère est modifiée dans le cadre de l'édition rapide
    dataListCopy: Array<any>;
    srccod: string;
    srccodref: string;
    bloc: string;
    element: IAugmentedJQuery;
    multiOccurrenceEnfant: IMultiOccurrence;
    multiOccurrenceParent: IMultiOccurrence;
    monoOccurrenceParent: IMonoOccurrence;
    parentId: number | string;
    autoFetch: boolean,
    cleint: string;
    cycleCleint: string;
    cycleId: number;
    forageCleint: string;
    schema: ISchemas;
    comportements: IComportementMap;
    fonctionsEdition: IFonctionsEdition;
    selections: Array<ISelectionDetails>;
    libelles: any;
    validatedFields: Array<string>;
    listenedFields: Array<string>;
    isView: boolean;
    reglesValidation: IReglesValidation;
    mnemonique: string;
    messages: IMessages;
    colonnesEnteteTitre: any;
    activeRowCleint: number;
    initializing: boolean;
    initialized: boolean;
    initError: boolean;
    fetchingDataList: boolean;
    savingDataList: boolean;
    dataListError: boolean;
    dataListErrorClient: IErrorClient;
    dataListReady: boolean;
    dataListQuery: IResourceArray<any>;
    downloadingDataList: boolean;
    setIsSelectionMultipleEmpty?: boolean;
    fetchingEtats: boolean;
    etatsError: boolean;
    hasAccesMenuEmploye: boolean;
    fetchingNombreLignes: boolean;
    compteurNombreLignesErreur: boolean;
    // L'état sélectionné sans modification temporaire
    etatSelectedOriginal: IEtatsResourcesEntite;
    etatSelected: IEtatsResourcesEntite;
    etats: Array<IEtatsResourcesEntite>
    toutSelectionne: boolean;
    rangeesSelectionnables: boolean;
    selectionColonne: string; // Permet de déterminer la colonne qui servira de sélection
    disabledRow: () => boolean;
    selectionPersistante: string;
    selectionLocale: any[];
    formulaire: IFormulaire;
    criteresSuggeres: IFormulaire;
    isPageCriteresSuggeresDisplayed: boolean;
    preventDataListUpdate: boolean;
    champsTransactionnelsError: boolean;
    fetchingChampsTransactionnels: boolean;
    champsTransactionnels: IChampsTransactionnels;
    rechercheVisible: boolean;
    compteur: number;
    modeEditionRapide: boolean;
    editionRapideActive: boolean;
    rangeeEditionRapide: any;
    indexEditionRapide: number;
    optionsNouveau: IMultiOccurrenceFonctionNouveauOptions;
    stateParams: any;
    ecranDetails: IEcranDetailsResourcesEntite;
    ecranSourceDetails: ISourceDetails;
    disableSave: ((data?: any) => boolean);
    successActionSauvegarde: Function;
    onDelete?: () => void;
    reloadWithBlocs: Array<string>;
    entetesReplacedValues: IMultiOccurrenceEntetesReplacedValues;
    reloadPage?: boolean;
    resetEtatDialog?: boolean;
    // Valeurs par défaut
    procedureValeursDefautDefinie: boolean;
    valeursDefaut: any;
    fetchingValeursDefaut: boolean;
    valeursDefautError: boolean;
    localDataList: boolean;
    messageErreurSelection: string;
    resourceUrl: string;
    pmtcleint?: any;
    tabSelected?: boolean;
    isTab?: boolean;
    isCriteresuggerePartage?: boolean;
    reloadEcran?: boolean;
    champsChangeItemEditionRapide?: Array<string>
    init(): void;
    fetchDataList(options?: { isChangementPage?: boolean, forceFetch?: boolean, flagFab?: boolean }): IPromise<void>;
    downloadDataList(contextData: IData): IPromise<void>;
    getDataListQueryParams(): IMultiOccurrenceQueryParams;
    getDataListFilterQueryParam(): string;
    getDataListSortByQueryParam(): string;
    getDataListReplacedValuesQueryParam(): string;
    saveRowDetails(data: any): IPromise<any>;
    saveAll(data?: Array<any>): IPromise<any>;
    deleteRowDetails(data: any): IPromise<any>;
    fetchSourceDetails(options: IMultiOccurrenceOptions): IPromise<any>;
    fetchEtats(): IPromise<any>;
    getEtatsPersonnels(): Array<IEtatsResourcesEntite>;
    updateSelectedEtat(etatSelected: IEtatsResourcesEntite, criteresSuggeresVisibles?: boolean): IPromise<any>;
    saveEtat(etat: IEtatsResourcesEntite): IPromise<any>;
    deleteEtat(etat: IEtatsResourcesEntite): IPromise<any>;
    getEtatPredefiniById(id: number | string): IEtatsResourcesEntite;
    hasEdition(data: any): boolean;
    hasSuppression(data: any): boolean;
    reinitialiser(): IPromise<any>;
    reinitialiserPastillefiltre(): void;
    reinitialiserDataOnglet(): void;
    startWatching(scope: IScope): void;
    isBooleanDataType(colonne: string): boolean;
    toggleToutSelectionne(menu?: IMenu | IMenuItem): void;
    closeAllSelectedRow(): void;
    getSelectedRows(): Array<any>;
    getColonnesFixe(): Array<string>;
    defineEtatColonnesFiltrables(etat: IEtatsResourcesEntite): void;
    fetchValeursDefaut(): IPromise<any>;
    saveEtatTemporaire(): void;
    getNomSourceDetails(options?: { baseOnly: boolean }): string;
    getSrccodref(): string;
    getUrlPath(options?: { absolute?: boolean, prefillIds?: boolean }): string;
    getResourceUrl(): string;
    getParentParams(): object;
    getParentQueryParams(): object;
    hasChampSuiviModification(): boolean;
    getParentIds(): Array<number | string>;
    getResourceParamsValues(data?: any): any;
    getDataActiveRow(): any;
    toggleEditionRapide(toggle?: boolean): void;
    unshiftRangeeEditionRapide(): void;
    pushRangeeEditionRapide(initialPush?: boolean): void;
    deleteRangeeEditionRapide(index: number): void;
    hasChangementsNonSauvegardes(formData: any, index: number): boolean;
    hasZoneRechercheVisible(): boolean;
    refreshChampsTransactionnels(): IPromise<IChampsTransactionnels>;
    toggleRechercheVisible(): void;
    doubleClickRow(event: MouseEvent, data: any, stateParams: any): void;
    hasFiltreOrCritere(): boolean;
    hasCriteresSuggeresSaisis(criteres: { [key: string]: any }): boolean;
    isDataListEmpty(): boolean;
    resetPagination(): void;
    fetchNombreResultatsTrouves(): IPromise<number>;
    getSchemaItem(colonne: string): ISchemaItem;
    getCritereSuggereDefaultValue(colonne: string): string;
    getEnteteLibelle(colonne: string, colonneRegroupement: Array<any>, showEntete: boolean): string;
    getDataListReplacedValuesQueryParam(): string;
    getEntetesReplacedValuesQueryParam(): string;
    rowHasError(data: any): boolean;
    getErrorColumnsName(): Array<string>;
    getCycleId(): number | string;
    updateDataList(isChangementPage?: boolean, saveEtatTemporaire?: boolean): void;
    onEtatParamsChange(onQueryChange: Function, newValue: any, oldValue: any): void;
    creerBoutonDupliquer(getNewMono: () => IMonoOccurrence, openNew: () => void): void;
    hasItemsSelectionMultiple(): boolean;
    getValuesItemSelectionMultiple(srccod: string): any;
    hasValuesItemSelectionMultiple(lov?: string): boolean;
    viderValuesSelectionMultiple(srccod?: string): IPromise<any>;
    deleteParams(key: string, reload: boolean): IPromise<any>;
    reinitialiserSelectionMultiple(srccod: string, sources: any): IPromise<any>;
    reinitialiserLovSelectionMultiple(srccod: string, source: any): IPromise<any>;
    getLovsMultiple(srccod?: string, askForlov?: string): string;
    updateCriteresSuggeresListe(): IEtatsResourcesEntite;
    getPmtcleint(srccod: string, usrcleint: number): IPromise<number>;
    isLovMultipleIntervalle(srccod: string, source: string, col: string): Promise<boolean>;
    getDataColonneIntervalle(intervalle: any, col: string): string
    setTabSelected(flag: boolean): void;
    getValuesItemsSelectionMultiple(srccod: string): Promise<string[]>;
    valideChangeItemEditionRapide(): Promise<boolean>;
    addChampChangeItemEditionRapide(col: string): void;
    removeChampChangeItemEditionRapide(col: string): void;
}

interface ISelectionDetails {
    nom: string;
    isDynamique: boolean;
}

interface IClassItems {
    row?: string | Function;
    col?: (data: IData, col: string) => (void | string);
}

export interface IMultiOccurrenceFonctions extends IMultiOccurrenceFonctionsRecherche,
    IMultiOccurrenceFonctionsPagination, IMultiOccurrenceFonctionsSetting, IMultiOccurrenceFonctionsActionBouton,
    IMultiOccurrenceFonctionsGrid, IMultiOccurrenceFonctionsMoreBouton {
    selectionnerUnEtat: boolean;
    criteresRechercheCollapsable: boolean;
    criteresRechercheOpened: boolean;
    criteresSuggeresVisibles: boolean;
    effaceCriteres: boolean;
    afficherMenuSettingDansEntete: boolean;
    introspection: boolean;
    axesAffichageVisible: boolean;
    activeRowOnClick: boolean;
    saveEtatTemporaire: boolean;
    valeursEcranCriteres: boolean;
    routeParamsCriteres: boolean;
    selectionCycle: boolean
    customCriteresSuggeres: boolean
    editionRapide: boolean | (() => boolean)
    editionStandard: boolean;
    consultationStandard: boolean;
    isEtatGlobal: boolean;
    agrandirMultiOccurrence: boolean;
    enfantCollapsable: boolean;
    placeholderRecherche?: string;
    reloadParentOnError: boolean;
    boutonDupliquer: boolean | ((data: any) => boolean)
    skipDoubleRequetes: boolean
    avisEmploye: boolean
}

export interface IMultiOccurrenceFonctionsRecherche {
    recherche: boolean;
    rechercheUnique: boolean;
    rechercheParColonne: boolean;

    /**
     * Indique si la recherche peut être cachée
     * Par défaut est à true. 
     * Si true elle est collapsed par défaut, mais peut être visible avec l'aide d'un bouton.
     * Si false la recherche est toujours visible.
     */
    rechercheCollapsable: boolean;
}

export interface IMultiOccurrenceFonctionsPagination {
    pagination: boolean;
    rechercheSansLimite: boolean;
}

export interface IMultiOccurrenceFonctionsSetting {
    selectionnerDesColonnes: boolean;
    selectionnerTri: boolean;
    filtrer: boolean;
    enregistrerUnEtat: boolean;
    reinitialiser: boolean;
    reinitialiserOuvrirCriteres: boolean;
    exportation: boolean;
    importation: boolean;
    reinitialiserPastillefiltre?: boolean;
}

export interface IMultiOccurrenceFonctionsActionBouton {
    nouveau: boolean | Function;
}

export interface IMultiOccurrenceFonctionsMoreBouton {
    supprime: boolean | Function;
    edition: boolean | Function;
    suiviModification: boolean | Function;
}

export interface IMultiOccurrenceFonctionsGrid {
    colonnesFixes: boolean;
    colonnesToujoursVisibles: boolean;
    rangeeSelectionnableUnique: boolean;
    limiteTotalSurRangeesSelectionnees: boolean;
    selectionnerToutesLesRangees: boolean;
    selectionnerLocalement: boolean;
}

export interface IMultiOccurrenceFonctionsOptions {
    axesAffichageVisible?: boolean;
    selectionnerUnEtat?: boolean;
    recherche?: boolean;
    rechercheParColonne?: boolean;
    rechercheUnique?: boolean; // lorsqu'il est en recherche unique le filtre est afficher et en read only
    rechercheCollapsable?: boolean;
    enfantCollapsable?: boolean;
    criteresRechercheCollapsable?: boolean;
    criteresRechercheOpened?: boolean;
    criteresSuggeresVisibles?: boolean;
    effaceCriteres?: boolean;
    pagination?: boolean;
    rechercheSansLimite?: boolean;
    afficherMenuSettingDansEntete?: boolean;
    libellesDynamiques?: boolean;
    introspection?: boolean;
    activeRowOnClick?: boolean;
    saveEtatTemporaire?: boolean;
    valeursEcranCriteres?: boolean;
    routeParamsCriteres?: boolean;
    editionRapide?: boolean | (() => boolean)
    editionStandard?: boolean;
    consultationStandard?: boolean;
    selectionCycle?: boolean
    customCriteresSuggeres?: boolean; //Permet d'indiquer qu'un composant s'occupe de l'affichage des critères suggérés
    isEtatGlobal?: boolean;
    placeholderRecherche?: string;
    reloadParentOnError?: boolean;
    boutonDupliquer?: boolean | ((data: any) => boolean)
    skipDoubleRequetes?: boolean
    avisEmploye?: boolean

    //IMultiOccurrenceFonctionsSetting
    selectionnerDesColonnes?: boolean;
    selectionnerTri?: boolean;
    filtrer?: boolean;
    enregistrerUnEtat?: boolean;
    reinitialiser?: boolean;
    reinitialiserOuvrirCriteres?: boolean;
    reinitialiserPastillefiltre?: boolean;

    //IMultiOccurrenceFonctionsActionBouton
    nouveau?: boolean | Function;
    exportation?: boolean;
    importation?: boolean;

    //IMultiOccurrenceFonctionsMoreBouton
    supprime?: boolean | Function;
    edition?: boolean | Function;
    suiviModification?: boolean | Function;

    //IMultiOccurrenceFonctionsGrid
    rangeeSelectionnableUnique?: boolean;
    selectionnerToutesLesRangees?: boolean;
    limiteTotalSurRangeesSelectionnees?: boolean;
    colonnesToujoursVisibles?: boolean;
    selectionnerLocalement?: boolean;
    agrandirMultiOccurrence?: boolean;
}

export interface IColonne {
    nom: string;
    largeur: number;
    titre?: string,
    editable?: boolean | ((data: any) => boolean);
    menu?: Array<IMenu | IMenuItem>;
    cssMenu?: string;
    total?: boolean | IMenu | Function;
    isTitreLigne?: boolean;
    hidden?: boolean | ((data: any, multiOccurrence?: IMultiOccurrence) => boolean);
    replacedValue?: ((multiOccurrence?: IMultiOccurrence) => string) | string;
    enleverZeroNonSig?: boolean;
    // Valeur par défaut pour l'édition rapide
    default?: any | ((data: any) => any);
    resourceParams?: any | IFormulaireItemResourceParamsFonction;
    changeColor?: boolean;
    changeCellColor?: boolean;
    isIcon?: Array<Object>;
    hideFromRecheche?: boolean;
    champMultiLigne?: IMultiLigneOptions
}

export interface IMultiLigneOptions {
    tagMultiLigne: string;
    setSeparateurEntete?: boolean;
    tagSeparateurEntete?: string;
    stringToTagSeparateur?: string;
    stringToTag?: string
}
export interface IColonnesParametres {
    [key: string]: IColonne;
}

export interface IMultiOccurrenceOptions {
    hasGridElement?: boolean;
    cleint?: string;
    srccod?: string;
    srccodref?: string;
    bloc?: string;
    bris?: string;
    brisNiveaux?: string;
    brisIcon?: string;
    classItems?: IClassItems;
    multiOccurrenceParent?: IMultiOccurrence;
    monoOccurrenceParent?: IMonoOccurrence;
    parentId?: number | string;
    cycleId?: number;
    gtecleref?: string;
    resourceParams?: any;
    resourceParamsDynamique?: IResourceParamsFunction;
    resourceUrl?: string;
    navigatePage?: string | INavigatePageFunction;
    navigateParams?: (rowData: any, multiOccurrence: IMultiOccurrence) => any;
    etatsPredefinis?: Array<IEtatPredefini>;
    forcerEtatPredefinisDepart?: string;
    pagination?: IPaginationOptions;
    formulaire?: IFormulaire;
    // Des dataTypes additionnels à utiliser
    dataTypes?: IDataTypeMap;

    // Colonnes visibles sont les colonnes qui sont consultables par défaut lors de l'ouverture de la multi-occurrence
    autoFetch?: boolean;
    // Si array contient plus de 5~8, l'excédent se retrouve dans la partie de droite sur un MSL
    colonnesVisibles: Array<IColonne | string>;
    colonnesEditionRapide?: Array<IColonne>;

    // Colonnes disponibles sont des colonnes supplémentaires qui peuvent être sélectionnées
    colonnesDisponibles?: Array<IColonne | string>;

    // Colonnes Cachées sont les colonnes qui sont toujours chargées dans le background mais non sélectable
    colonnesCachees?: Array<string>;
    champsNonSauvergarderEtat?: Array<string>
    colonnesFixesGauche?: Array<IColonne | string>;
    colonnesFixesDroite?: Array<IColonne | string>;

    filtresParametres?: Array<IFiltreOptions>;
    filtres?: Array<IFiltreOptions>;
    criteresSuggeres?: IFormulaire;
    optionsNouveau?: IMultiOccurrenceFonctionNouveauOptions;

    fonctions?: IMultiOccurrenceFonctionsOptions

    actionsNouveaux?: IMenu;

    actionsRangeeGauche?: IMenu;
    actionsRangeeDroite?: IMenu;
    raccourcisRangee?: IRaccourciConfig;
    actionsMore?: IMenu;
    actionsMoreLigne?: IMenu;
    actions?: IMenu;

    actionsSelection?: IMenu | IMenuItem;

    valeursDefaut?: { [key: string]: any };

    rangeesSelectionnables?: boolean;
    selectionColonne?: string;
    disabledRow?: () => boolean;
    selectionPersistante?: string;

    multiOccurrenceOptionsEnfant?: IMultiOccurrenceOptions;

    /**
     * @deprecated "details" on utilise le composant ExTemplate <ex-template slot="details">
     */
    details?: IMultiOccurrenceDetails;

    axesAffichage?: IAxesAffichageOptions;
    nomSourceDetails?: string;
    stateParams?: any;
    ecranDetails?: IEcranDetailsResourcesEntite;
    ecranSourceDetails?: ISourceDetails;
    modeEditionRapide?: boolean;
    editionRapideActive?: boolean;
    disableSave?: ((data?: any) => boolean);
    successActionSauvegarde?: Function;
    onDelete?: () => void;
    reloadWithBlocs?: Array<string>
    entetesReplacedValues?: IMultiOccurrenceEntetesReplacedValues;
    menus?: Array<IMenuItem>;
    reloadPage?: boolean;
    resetEtatDialog?: boolean;
    // permet de définir si le dataList viens de l'API ( false ) ou du local ( true ).
    localDataList?: boolean;
    messageErreurSelection?: string;
    pmtcleint?: any;
    tabSelected?: boolean;
    isTab?: boolean;
    isCriteresuggerePartage?: boolean;
    reloadEcran?: boolean;
    champsChangeItemEditionRapide?: Array<string>
}

interface IMultiOccurrenceEntetesReplacedValues {
    [entete: string]: string;
}

export interface IMultiOccurrenceFonctionNouveauOptions {
    lblAide?: string;
    selectionSource?: string;
    selectionSourceImgExt?: string;
    lblTitre?: string;
    position?: 'left' | 'right';
    options?: IMenuItemOptions;
}

interface IMultiOccurrenceDetails {
    lblTitre: string;
    multiOccurrence?: IMultiOccurrence;
    multiOccurrenceOptions: IMultiOccurrenceOptions;
}

export interface INavigatePageFunction {
    (data?: any): string;
}

interface IResourceParamsFunction {
    (data?: any): any;
}

interface IMultiOccurrenceQueryParams {
    sortby: string;
    filter: string;
    limit: number;
    start?: number;
    cols: string;
    parentId?: number | string;
    includeLovs?: boolean;
}

interface IDataResource extends IResourceClass<any> {
    update(params: any, data: any): IResource<any>;

    updateAll(params: any, data: any): IResource<any>;

    download(params: any, data: any): IResource<any>;
}

export default module('core.services.multi-occurrence', [])
    .factory('MultiOccurrence', MultiOccurrenceFactory);

const LIMITE_LARGEUR_COLONNE = 50;

/* @ngInject */
function MultiOccurrenceFactory(Occurrence: IOccurrenceClass,
    Tri: ITriClass,
    DataType: IDataTypeClass,
    ApiConfig: IApiConfig,
    $resource: IResourceService,
    EtatsResource: IEtatsResourcesClass,
    EtatsMultipleResource: IEtatsMultipleResourcesClass,
    $q: IQService,
    $timeout: ITimeoutService,
    Filtre: IFiltreClass,
    Pagination: IPaginationClass,
    BooleanDataType: IBooleanDataTypeClass,
    BoutonDataType: IBoutonDataTypeClass,
    ImputationDataType: IImputationDataTypeClass,
    MenuItemEcran: IMenuItemEcranClass,
    ChampsTransactionnelsResource: IChampsTransactionnelsResource,
    ChampsEvenementsResource: IChampsEvenementsResource,
    sourceDetailsManager: ISourceDetailsManager,
    profil: IProfil,
    LovDataType: ILovDataTypeClass,
    ValeursDefautResource: IValeursDefautResource,
    etatStore: IEtatStore,
    downloadManager: IDownloadManagerService,
    Comportement: IComportementClass,
    FonctionsEdition: IFonctionsEditionClass,
    parametresSecurite: IParametresSecuriteService,
    messageHandlingInterceptor: IResourceInterceptor,
    dataLinker: IDataLinker,
    changementManager: IChangementManager,
    exFocus: IFocusService,
    $state: IStateService,
    Menu: IMenuClass,
    MenuItemForage: IMenuItemForageClass,
    MenuItem: IMenuItemClass,
    MenuItemFactory: IMenuItemFactoryClass,
    FormulaireItem: IFormulaireItemClass,
    FormulaireGroupe: IFormulaireGroupeClass,
    exEnteteLibelleFilter: IFilterEnteteLibelle,
    IndicateurErreurDataType: IIndicateurErreurDataTypeClass,
    StatutDataType: IStatutDataTypeClass,
    Libelles: ILibellesClass,
    $http: IHttpService,
    $rootScope: IRootScopeService) {
    let counter = 0;

    return class MultiOccurrence extends Occurrence implements IMultiOccurrence {
        private readonly colonnesSysteme: Array<string> = [
            // Suivi de modification
            'datcre',
            'usrnomcre',
            'datmod',
            'usrnommod',
            'usrmod',
            'usrcre',
            'demcleint'
        ];

        private currentQueryParams: any = {};
        private etatsQuery: IResourceArray<any>;

        readonly resourceParams: any;
        readonly resourceParamsDynamique: IResourceParamsFunction;
        readonly id: number;
        readonly bris: string;
        readonly brisNiveaux: string;
        readonly brisIcon: string;
        readonly gtecleref: string;
        readonly etatsPredefinis: Array<IEtatsResourcesEntite>;
        readonly forcerEtatPredefinisDepart: string;
        readonly etatDefault: IEtatsResourcesEntite;
        readonly colonnesParametres: IColonnesParametres;
        readonly colonnesCachees: Array<string>;
        readonly champsNonSauvergarderEtat?: Array<string>
        readonly colonnesFixesGauche: Array<string>;
        readonly colonnesFixesDroite: Array<string>;
        readonly classItems: IClassItems;
        readonly dataTypes: IDataTypeMap;
        readonly DataResource: IDataResource;
        readonly srccod: string;
        readonly srccodref: string;
        readonly bloc: string;
        readonly multiOccurrenceParent: IMultiOccurrence;
        readonly monoOccurrenceParent: IMonoOccurrence;
        readonly navigatePage: string | INavigatePageFunction;
        readonly navigateParams: (rowData: any, multiOccurrence: IMultiOccurrence) => any;
        readonly fonctions: IMultiOccurrenceFonctions;
        readonly axesAffichage: AxesAffichage;
        readonly actionsRangeeGauche: IMenu;
        readonly actionsRangeeDroite: IMenu;
        readonly raccourcisRangee: IRaccourciConfig;
        readonly actionsMore: IMenu;
        readonly actionsMoreLigne: IMenu;
        readonly actions: IMenu;
        readonly actionsSelection: IMenu | IMenuItem;
        readonly nomSourceDetails: string;
        readonly stateParams: any;
        readonly ecranDetails: IEcranDetailsResourcesEntite;
        readonly ecranSourceDetails: ISourceDetails;
        readonly disableSave: ((data?: any) => boolean);
        readonly successActionSauvegarde: Function;
        readonly onDelete: () => void;
        readonly reloadWithBlocs: Array<string>;
        readonly entetesReplacedValues: IMultiOccurrenceEntetesReplacedValues = {};
        readonly hasGridElement: boolean;
        readonly reloadPage: boolean = false;
        readonly pmtcleint: any = 0;
        readonly resetEtatDialog: boolean = false;
        readonly reloadEcran: boolean = false;
        champsChangeItemEditionRapide: Array<string> = []
        flagValDefSelectionMultiple: boolean = false;

        parentId: number | string;
        element: IAugmentedJQuery;
        actionsNouveaux: IMenu;
        ready: IPromise<any>;
        dataList: Array<any>;
        dataListCopy: Array<any>;
        initializing: boolean;
        initialized: boolean = false;
        autoFetch: boolean;
        cleint: string;
        cycleCleint: string;
        cycleId: number;
        forageCleint: string;
        schema: ISchemas;
        comportements: IComportementMap;
        fonctionsEdition: IFonctionsEdition;
        selections: Array<ISelectionDetails>;
        libelles: any;
        validatedFields: Array<string>;
        listenedFields: Array<string>;
        isView: boolean;
        reglesValidation: IReglesValidation;
        mnemonique: string;
        messages: IMessages;
        colonnesEnteteTitre: any;
        activeRowCleint: number;
        initError: boolean = false;
        fetchingNombreLignes: boolean = false;
        compteurNombreLignesErreur: boolean = false;
        fetchingDataList: boolean = false;
        savingDataList: boolean = false;
        dataListError: boolean = false;
        dataListErrorClient: IErrorClient = null;
        dataListQuery: IResourceArray<any>;
        dataListReady: boolean = false;
        downloadingDataList: boolean = false;
        setIsSelectionMultipleEmpty?: boolean = false;
        fetchingEtats: boolean = false;
        etatSelectedOriginal: IEtatsResourcesEntite;
        etatSelected: IEtatsResourcesEntite;
        etats: Array<IEtatsResourcesEntite>;
        etatsError: boolean = false;
        hasAccesMenuEmploye: boolean = false;
        toutSelectionne: boolean = false;
        rangeesSelectionnables: boolean = false;
        selectionColonne: string;
        disabledRow: () => boolean;
        selectionPersistante: string;
        selectionLocale: any[] = [];
        formulaire: IFormulaire;
        criteresSuggeres: IFormulaire;
        optionsNouveau: IMultiOccurrenceFonctionNouveauOptions;
        preventDataListUpdate: boolean = false;
        champsTransactionnelsError: boolean;
        fetchingChampsTransactionnels: boolean;
        rechercheVisible: boolean;
        champsTransactionnels: IChampsTransactionnels;
        multiOccurrenceOptionsEnfant: IMultiOccurrenceOptions;
        details: IMultiOccurrenceDetails;
        compteur: number;
        modeEditionRapide: boolean = false;
        editionRapideActive: boolean;
        rangeeEditionRapide: any;
        indexEditionRapide: any;
        multiOccurrenceEnfant: IMultiOccurrence;

        // Valeurs par défaut
        procedureValeursDefautDefinie: boolean;
        valeursDefaut: any;
        fetchingValeursDefaut: boolean;
        valeursDefautError: boolean;
        localDataList: boolean;
        resourceUrl: string;
        messageErreurSelection: string;
        isPageCriteresSuggeresDisplayed: boolean;
        tabSelected?: boolean;
        isTab?: boolean;
        isCriteresuggerePartage?: boolean;
        constructor(options: IMultiOccurrenceOptions) {
            super(options);
            //On s'assure de retirer tous les listeners à la destruction.
            this.on('$destroy', () => {
                this.removeAllListeners();
                this.saveEtatTemporaire;
            });

            // Par défaut, le Multi Occurrence est lié à l'écran
            this.id = counter++;
            this.stateParams = options.stateParams;
            this.ecranDetails = options.ecranDetails;
            this.ecranSourceDetails = options.ecranSourceDetails;
            this.srccod = options.srccod || this.stateParams.srccod;
            this.srccodref = options.srccodref;
            this.bloc = options.bloc;
            this.bris = options.bris;
            this.brisNiveaux = options.brisNiveaux;
            this.brisIcon = options.brisIcon;
            this.multiOccurrenceParent = options.multiOccurrenceParent;
            this.monoOccurrenceParent = options.monoOccurrenceParent;
            this.parentId = options.parentId;
            this.gtecleref = options.gtecleref || this.getGtecleref();
            this.cleint = options.cleint;
            this.cycleId = options.cycleId;
            this.navigatePage = options.navigatePage;
            this.navigateParams = options.navigateParams;
            this.nomSourceDetails = options.nomSourceDetails;
            this.colonnesCachees = options.colonnesCachees || [];
            this.champsNonSauvergarderEtat = options.champsNonSauvergarderEtat || [];
            this.colonnesFixesGauche = (options.colonnesFixesGauche) ? options.colonnesFixesGauche.map((colonne) => typeof colonne === 'string' ? colonne : colonne.nom) : [];
            this.colonnesFixesDroite = (options.colonnesFixesDroite) ? options.colonnesFixesDroite.map((colonne) => typeof colonne === 'string' ? colonne : colonne.nom) : [];
            this.colonnesParametres = [].concat(options.colonnesVisibles, options.colonnesFixesGauche || [], options.colonnesFixesDroite || [], options.colonnesDisponibles || []).reduce((accumulateur: any, valeurCourante: string | IColonne) => {
                if (typeof valeurCourante === 'string') {
                    accumulateur[valeurCourante] = { nom: valeurCourante };
                } else {
                    //ajustement si la largeur est inférieur a la limite
                    if (valeurCourante && valeurCourante.largeur !== undefined) {
                        valeurCourante.largeur = (valeurCourante.largeur >= LIMITE_LARGEUR_COLONNE) ? valeurCourante.largeur : LIMITE_LARGEUR_COLONNE;
                    }

                    accumulateur[valeurCourante.nom] = valeurCourante;
                    //on valide s'il s'agit d'une fonctionne 
                    if (valeurCourante && valeurCourante.total instanceof Function) {
                        //on valide le resultat de la fonctionne si on l'ajoute dans le meme if cela ne marche pas
                        if (valeurCourante.total()) {
                            this.colonnesCachees.push(`total___${valeurCourante.nom}`);
                        }
                    } else {
                        //On ajoute les colonnes pour les totaux dans les colonnes cachées.
                        if (valeurCourante && valeurCourante.total) {
                            this.colonnesCachees.push(`total___${valeurCourante.nom}`);
                        }
                    }
                }

                return accumulateur;
            }, {});
            this.resourceParams = options.resourceParams || null;
            this.resourceParamsDynamique = options.resourceParamsDynamique;
            this.actionsNouveaux = options.actionsNouveaux;
            this.actionsRangeeGauche = options.actionsRangeeGauche;
            this.actionsRangeeDroite = options.actionsRangeeDroite;
            this.raccourcisRangee = options.raccourcisRangee;
            this.actionsMore = options.actionsMore;
            this.actionsMoreLigne = options.actionsMoreLigne || new Menu([]);
            this.actions = options.actions;
            this.actionsSelection = options.actionsSelection;
            this.valeursDefaut = options.valeursDefaut;
            this.rangeesSelectionnables = options.rangeesSelectionnables || this.rangeesSelectionnables;
            this.selectionColonne = options.selectionColonne || '$selected';
            this.disabledRow = options.disabledRow;
            this.multiOccurrenceOptionsEnfant = options.multiOccurrenceOptionsEnfant;
            this.details = options.details;
            this.formulaire = options.formulaire;
            this.classItems = options.classItems;
            this.modeEditionRapide = options.modeEditionRapide;
            this.editionRapideActive = options.editionRapideActive || false;
            this.forcerEtatPredefinisDepart = options.forcerEtatPredefinisDepart;
            this.disableSave = options.disableSave;
            this.successActionSauvegarde = options.successActionSauvegarde;
            this.onDelete = options.onDelete;
            this.reloadWithBlocs = options.reloadWithBlocs;
            this.autoFetch = options.autoFetch === undefined || options.autoFetch;
            this.hasGridElement = options.hasGridElement;
            this.messageErreurSelection = options.messageErreurSelection;

            this.dataTypes = { ...options.dataTypes }

            if (options.selectionPersistante) {
                this.selectionPersistante = options.selectionPersistante;
                this.colonnesParametres[options.selectionPersistante] = <IColonne>{ nom: options.selectionPersistante };
            }

            if (options.criteresSuggeres) {
                options.criteresSuggeres.largeurDefaut = 33.33;
                this.criteresSuggeres = options.criteresSuggeres;
                // On ne veut pas appliquer les champs transactionnels aux critères suggérés
                this.criteresSuggeres.securityCycle = false;
            }

            if (options.entetesReplacedValues) {
                this.entetesReplacedValues = Object.keys(options.entetesReplacedValues).reduce((values: any, col: string) => {
                    //On s'assure que la référence au libellé est en majuscule.
                    values[col.toUpperCase()] = options.entetesReplacedValues[col];
                    return values;
                }, {});
            }

            if (options.reloadPage) {
                this.reloadPage = options.reloadPage;
            }

            if (options.reloadEcran) {
                this.reloadEcran = options.reloadEcran;
            }

            if (options.pmtcleint) {
                this.pmtcleint = options.pmtcleint
            }
            this.isTab = (options?.isTab) ? true : false
            this.isCriteresuggerePartage = (options.isCriteresuggerePartage) ? true : false
            this.tabSelected = (options?.tabSelected) ? options.tabSelected : false;
            if (options.resetEtatDialog) {
                this.resetEtatDialog = options.resetEtatDialog;
            }
            // Les filtres paramétrés proviennent des paramètres de url
            if (options.filtresParametres) {
                options.filtres = options.filtres || [];
                options.filtresParametres.reverse().forEach((filtre: IFiltreOptions) => {
                    if (filtre.valeur || (typeof this.stateParams[<string>filtre.colonne] !== 'undefined')) {
                        options.filtres.unshift({
                            colonne: filtre.colonne,
                            valeur: filtre.valeur || this.stateParams[<string>filtre.colonne],
                            operateur: filtre.operateur,
                            readOnly: true,
                            parametre: true,
                            affichage: filtre.affichage,
                            visible: filtre.visible
                        });
                    }
                });
            }

            this.localDataList = options.localDataList === undefined ? false : options.localDataList;

            //Certains écran ont une sécurité spéciale qui empêche toute modification/création. On ne devrait pas
            //recevoir de données, mais il faut s'assurer de ne pas permettre l'ajout.
            const securiteNouveau = (this.ecranDetails.accessRefused) ? { nouveau: false } : {};

            // La liste de toutes la fonctions possibles sur un Multi Occurrence. Chacune peut être désactivée ou activée.
            this.fonctions = {
                activeRowOnClick: false,
                saveEtatTemporaire: true,
                valeursEcranCriteres: true,
                routeParamsCriteres: true,
                editionRapide: false,
                editionStandard: true,
                consultationStandard: true,
                selectionCycle: false,
                customCriteresSuggeres: false,
                selectionnerUnEtat: true,
                recherche: true,
                rechercheParColonne: true,
                rechercheUnique: false,
                rechercheCollapsable: true,
                enfantCollapsable: true,
                criteresRechercheCollapsable: true,
                criteresRechercheOpened: true,
                criteresSuggeresVisibles: undefined, // Sera géré par le composant ex-multi-occurrence
                effaceCriteres: false,
                selectionnerDesColonnes: true,
                selectionnerTri: true,
                libellesDynamiques: false,
                filtrer: true,
                enregistrerUnEtat: true,
                reinitialiser: true,
                reinitialiserOuvrirCriteres: false,
                reinitialiserPastillefiltre: false,
                axesAffichageVisible: false,
                pagination: true,
                rechercheSansLimite: false,
                exportation: !isMobile,
                importation: false,
                suiviModification: true,
                afficherMenuSettingDansEntete: false,
                introspection: true,
                rangeeSelectionnableUnique: false,
                limiteTotalSurRangeesSelectionnees: false,
                colonnesToujoursVisibles: false,
                selectionnerToutesLesRangees: Boolean(options.rangeesSelectionnables),
                colonnesFixes: (this.colonnesFixesDroite.length > 0 || this.colonnesFixesGauche.length > 0),
                isEtatGlobal: false,
                // Ces fonction seront supplémentées par la sécurité par fonction si elle est configurée
                nouveau: undefined,
                supprime: undefined,
                edition: undefined,
                agrandirMultiOccurrence: false,
                selectionnerLocalement: false,
                reloadParentOnError: false,
                boutonDupliquer: true,
                skipDoubleRequetes: !this.getNomSourceDetails().startsWith('lov-'),
                avisEmploye: true,
                ...(options.fonctions as any),
                ...securiteNouveau
            };

            // Si on a pas accès aux interventions, on ne doit pas avoir accès aux fonctions d'édition
            if (this.ecranSourceDetails && this.ecranSourceDetails.sourceDeveloppement && !profil.accesUsager.selectionIntervention) {
                this.fonctions.nouveau = false;
                this.fonctions.edition = false;
                this.fonctions.supprime = false;
            }

            this.rechercheVisible = !this.fonctions.afficherMenuSettingDansEntete || !this.fonctions.rechercheCollapsable;
            this.optionsNouveau = options.optionsNouveau || {};

            if (!this.optionsNouveau.position) {
                this.optionsNouveau.position = 'right';
            }

            // Les données des critères suggérés sont basées par défaut sur les valeurs de l'écran
            const criteresSuggeresData = (this.criteresSuggeres && this.fonctions.valeursEcranCriteres) ?
                { ...this.ecranDetails.valeurs } : {};

            //Axes d'affichage
            this.axesAffichage = new AxesAffichage(options.axesAffichage);

            if (options.etatsPredefinis) {
                this.etatsPredefinis = options.etatsPredefinis.map((etatPredefini, index) => {
                    const etat = new EtatsResource({
                        id: '$NONE$' + index,
                        actif: etatPredefini.axeVisibiliteActif !== undefined ? etatPredefini.axeVisibiliteActif : true,
                        nom: etatPredefini.nom,
                        pagination: new Pagination(etatPredefini.paginationOptions || options.pagination),
                        gtecleref: this.gtecleref,
                        predefini: true,
                        axeAffichageActif: etatPredefini.axesAffichage && etatPredefini.axesAffichage.nomAxeActif,
                        colonnesVisibles: (etatPredefini.colonnesVisibles || options.colonnesVisibles).map((colonne: IColonne | string) => typeof colonne === 'string' ? colonne : colonne.nom),
                        colonnesDisponibles: (etatPredefini.colonnesDisponibles || options.colonnesDisponibles || []).map((colonne: IColonne | string) => typeof colonne === 'string' ? colonne : colonne.nom),
                        filtres: (etatPredefini.filtres || []).map((filtreOptions: IFiltreOptions) => {
                            filtreOptions.predefini = true;
                            filtreOptions.readOnly = true;
                            return new Filtre(filtreOptions);
                        }).concat((options.filtres || []).map((filtreOptions: IFiltreOptions) => {
                            filtreOptions.predefini = filtreOptions.predefini !== undefined ? filtreOptions.predefini : true;
                            return new Filtre(filtreOptions);
                        })),
                        criteresSuggeresData,
                        tri: new Tri()
                    });
                    this.defineEtatColonnesFiltrables(etat);

                    return etat;
                });
                if (options.forcerEtatPredefinisDepart) {
                    this.etatDefault = this.etatsPredefinis.find((ele) => ele.nom === options.forcerEtatPredefinisDepart);
                } else {
                    this.etatDefault = this.etatsPredefinis[0];
                }
            } else {
                this.etatsPredefinis = [];
                this.etatDefault = new EtatsResource({
                    id: '$NONE$',
                    nom: 'G_LBL_MSL_ETAT_AUCUN',
                    pagination: new Pagination(options.pagination),
                    axeAffichageActif: options.axesAffichage && (options.axesAffichage.nomAxeActif || options.axesAffichage.axes[0].axeNom),
                    gtecleref: this.gtecleref,
                    predefini: false,
                    colonnesVisibles: options.colonnesVisibles.map((colonne: IColonne | string) => typeof colonne === 'string' ? colonne : colonne.nom),
                    colonnesDisponibles: (options.colonnesDisponibles || []).map((colonne: IColonne | string) => typeof colonne === 'string' ? colonne : colonne.nom),
                    filtres: (options.filtres || []).map((filtreOptions: IFiltreOptions) => {
                        filtreOptions.predefini = filtreOptions.predefini !== undefined ? filtreOptions.predefini : true;
                        return new Filtre(filtreOptions);
                    }),
                    criteresSuggeresData,
                    tri: new Tri()
                });
                this.defineEtatColonnesFiltrables(this.etatDefault);
            }

            if (this.modeEditionRapide) {
                this.on('dataListUpdate', () => {
                    this.dataListCopy = this.dataList.map((data: any) => ({ ...data }));
                });
            }

            this.DataResource = this.getDataResource(options.resourceUrl);
            this.init();
            this.resourceUrl = options.resourceUrl;
        }

        getGtecleref() {
            return [
                this.srccod,
                this.bloc,
                this.stateParams.srccod !== this.srccod ? this.stateParams.srccod : '',
                this.stateParams.ecran
            ].filter((item: string) => item).join('-');
        }

        init() {
            this.initializing = true;

            this.ready = $q.all([
                this.fetchSourceDetails(),
                this.fetchEtats()
            ]).then(() => {
                const nomFonction = (this.bloc || this.mnemonique).toUpperCase();
                let fonction: any = undefined;
                const lowerCaseMnemonique = nomFonction.toLocaleLowerCase();

                for (const [key, value] of Object.entries(this.ecranDetails.fonctions)) {
                    const lowerCaseKey = key.toLocaleLowerCase();
                    if (lowerCaseKey.includes(lowerCaseMnemonique) && !lowerCaseKey.includes(".")) {
                        fonction = value;
                        break;
                    }
                    if (!fonction) {
                        if (
                            (this.mnemonique === "NOF" && lowerCaseKey.includes("not")) ||
                            (this.mnemonique === "ICC" && lowerCaseKey.includes("inf"))
                        ) {
                            fonction = value;
                            break;
                        }
                        if (this.mnemonique === "PIJ" && (lowerCaseKey.includes(".boupij") || lowerCaseKey.includes(".boupij_fin"))) {
                            fonction = value;
                            break;
                        }
                    }
                }

                if (this.actionsSelection instanceof Menu) {
                    // On applique la sécurité par fonction
                    //On boucle sur une copie du tableau pour ne pas modifier les index au fur et à mesure.
                    this.actionsSelection.listeMenuItem.slice().reverse().forEach((menuItem: IMenuItem) => {
                        if (!menuItem.fonction) { return; }
                        const fonctionMenuItem = this.ecranDetails.fonctions[`${nomFonction}.${menuItem.fonction}`] || this.ecranDetails.fonctions[`${menuItem.fonction}`];

                        if (fonctionMenuItem && !fonctionMenuItem.flgacc) {
                            const index = (<IMenu>this.actionsSelection).getIndex(menuItem);
                            (<IMenu>this.actionsSelection).remove(index);
                        }
                    });

                    if (!this.actionsSelection.listeMenuItem.length) {
                        (<IMenu>this.actionsSelection).add(new MenuItem('G_MSG_AUCUNE_ACTION_POSSIBLE', () => ({}), { noAction: true, disabled: true }));
                    }
                }
                if (this?.fonctions?.supprime === undefined || this?.fonctions?.supprime) {
                    const canDelete = Boolean(!fonction || fonction.flgsup);
                    if (!this.fonctionsEdition.supprime) {
                        this.fonctions.supprime = canDelete;
                    } else {
                        this.fonctions.supprime = canDelete ? this.fonctionsEdition.supprime : false;
                    }
                }

                if (this.fonctions.edition === undefined || this?.fonctions?.edition) {
                    const canEdit = Boolean(!fonction || fonction.flgmod);
                    if (!this.fonctionsEdition.edition) {
                        this.fonctions.edition = canEdit;
                    } else {
                        this.fonctions.edition = canEdit ? this.fonctionsEdition.edition : false;
                    }
                }

                //on retourne toujours un boolean car la propriete est utilise comme une valeur partout l'application
                if (this?.fonctions && typeof this.fonctions.editionRapide === 'function') {
                    const result = this.fonctions.editionRapide()
                    if (typeof result !== 'undefined') {
                        this.fonctions.editionRapide = result
                    }
                    else {
                        this.fonctions.editionRapide = false
                    }
                }

                const nouveau = this.fonctions.nouveau === undefined || this.fonctions.nouveau
                if (!this.fonctionsEdition.nouveau) {
                    if (this.hasNouveauForage()) {
                        const fonctionBoutonNouveau = this.ecranDetails.fonctions[`${nomFonction}.BOUNOU`];
                        this.fonctions.nouveau = (!fonctionBoutonNouveau || fonctionBoutonNouveau.flgacc) && nouveau;
                    } else {
                        const nomFonctionBoutonNouveau = !this.hasNouveauMultiple() && this.actionsNouveaux && this.actionsNouveaux.listeMenuItem && this.actionsNouveaux.listeMenuItem.length && (<any>this.actionsNouveaux.listeMenuItem[0]).fonction;
                        if (nomFonctionBoutonNouveau) {
                            const fonctionBoutonNouveau = this.ecranDetails.fonctions[`${nomFonction}.${nomFonctionBoutonNouveau}`];
                            this.fonctions.nouveau = (!fonctionBoutonNouveau || fonctionBoutonNouveau.flgacc) && nouveau;
                        } else {
                            this.fonctions.nouveau = (!fonction || fonction.flgins) && nouveau;
                        }
                    }
                } else {
                    let fonctionsEditionData = false
                    try {
                        let scopeColonnesHidden: any = $rootScope.$new();
                        //le dataLinker ne prend pas le bon $ancentre alors on a dû remetre l'info à main
                        scopeColonnesHidden.data = {};
                        scopeColonnesHidden.data.$ancetre = this?.monoOccurrenceParent?.data
                        scopeColonnesHidden.data.$params = this?.stateParams
                        scopeColonnesHidden.data.$valeurs = this?.ecranDetails?.valeurs
                        scopeColonnesHidden.data.$paramsEcran = this?.ecranDetails?.params
                        scopeColonnesHidden.data.$flgcen = this?.ecranDetails?.flgcen
                        fonctionsEditionData = (this.fonctionsEdition.nouveau instanceof Function) ? this.fonctionsEdition.nouveau(scopeColonnesHidden.data) : true;
                    } catch (error) { }
                    if (this.hasNouveauForage()) {
                        const fonctionBoutonNouveau = this.ecranDetails.fonctions[`${nomFonction}.BOUNOU`];
                        const hasAcces = (!fonctionBoutonNouveau || fonctionBoutonNouveau.flgacc);
                        this.fonctions.nouveau = hasAcces && this.fonctionsEdition.nouveau && nouveau && fonctionsEditionData;
                    } else {
                        const nomFonctionBoutonNouveau = !this.hasNouveauMultiple() && this.actionsNouveaux && this.actionsNouveaux.listeMenuItem && this.actionsNouveaux.listeMenuItem.length && (<any>this.actionsNouveaux.listeMenuItem[0]).fonction;
                        if (nomFonctionBoutonNouveau) {
                            const fonctionBoutonNouveau = this.ecranDetails.fonctions[`${nomFonction}.${nomFonctionBoutonNouveau}`];
                            this.fonctions.nouveau = (!fonctionBoutonNouveau || fonctionBoutonNouveau.flgacc) ? this.fonctionsEdition.nouveau && nouveau && fonctionsEditionData : false;
                        } else {
                            this.fonctions.nouveau = (!fonction || fonction.flgins) ? this.fonctionsEdition.nouveau && nouveau && fonctionsEditionData : false;
                        }
                    }
                }
            }).then(() => this.refreshChampsTransactionnels()).then(() => {
                if (this.modeEditionRapide) {
                    return this.fetchValeursDefaut();
                }
            }).then(() => {
                this.initError = false;
                this.initialized = true;
                this.emit('ready');
            }).finally(() => this.initializing = false);

            this.ready.then(async () => {
                if (this.autoFetch || (!this.getNomSourceDetails().startsWith('lov-') && this.hasFiltreOrCritere())) {
                    if (this.fonctions.effaceCriteres) {
                        await this.reinitialiser()
                    }
                    if (!this.fonctions.criteresSuggeresVisibles) {
                        this.fetchDataList();
                    }
                } else if (!this.localDataList) {
                    this.dataList = [];
                    this.resetPagination();
                }
            });

            this.ready.catch(() => {
                this.initError = true;
            });
        }

        /**
         * Retourne 'true' si la création est dans une autre page
         */
        private hasNouveauForage() {
            return (
                this.actionsNouveaux &&
                this.actionsNouveaux.listeMenuItem.length === 1 &&
                (
                    (<IMenuItem>this.actionsNouveaux.listeMenuItem[0]).link ||
                    (<IMenuItem>this.actionsNouveaux.listeMenuItem[0]) instanceof MenuItemEcran
                )
            );
        }

        private hasNouveauMultiple() {
            return this.actionsNouveaux && this.actionsNouveaux.listeMenuItem.length > 1;
        }

        private getDataResource(url: string) {
            const params: any = {
                ...this.getResourceParamsValues(),
                ...this.getParentParams(),
                ...parametresSecurite(this.stateParams, this.ecranDetails)
            };

            this.getStateParams(params);

            const resourceUrl = url || this.getResourceUrl();

            const actions: any = {
                query: {
                    isArray: true,
                    // En ajoutant cet interceptor, on obtient la réponse dans le 'then' de la promise, à la place des
                    // données. Ça permet de pouvoir lire des headers, ce qui est nécessaire.
                    interceptor: {
                        response(response: IHttpResponse<any>): IHttpResponse<any> {
                            return response;
                        }
                    }
                },
                update: {
                    method: 'PUT',
                    interceptor: messageHandlingInterceptor,
                    url: resourceUrl
                },
                updateAll: {
                    method: 'PUT',
                    interceptor: messageHandlingInterceptor,
                    isArray: true
                },
                save: {
                    method: 'POST',
                    interceptor: messageHandlingInterceptor
                },
                delete: {
                    method: 'DELETE',
                    interceptor: messageHandlingInterceptor
                }
            };
            const options: IResourceOptions = {
                cancellable: true
            };

            return $resource(resourceUrl, params, actions, options) as IDataResource;
        }

        closeAllSelectedRow() {
            if (this.dataList) {
                this.dataList.forEach((ele: any) => {
                    if (ele.$opened) {
                        delete ele.$opened;
                    }
                });
            }
        }

        getResourceParamsValues(data?: any) {
            if (this.resourceParamsDynamique) {
                let dataParam = { ...data };

                if (this.multiOccurrenceParent) {
                    Object.assign(dataParam, this.multiOccurrenceParent.getDataActiveRow());
                } else if (this.monoOccurrenceParent) {
                    Object.assign(dataParam, this.monoOccurrenceParent.data);
                }


                if (this.element) {
                    const monoOccurrenceEcranCtrl = this.element.closest('ex-mono-occurrence-ecran').controller('exMonoOccurrenceEcran');

                    if (monoOccurrenceEcranCtrl) {
                        Object.assign(dataParam, monoOccurrenceEcranCtrl.formData);
                    }
                }

                const resourceParamsDynamique = this.resourceParamsDynamique(dataParam) || {};
                return { ...resourceParamsDynamique, ...this.resourceParams };
            } else {
                return this.resourceParams;
            }
        }

        getParentParams() {
            if (!this.nomSourceDetails) {
                if (this.multiOccurrenceParent) {
                    const parentCleint = this.multiOccurrenceParent.cleint;
                    return {
                        ...this.multiOccurrenceParent.getParentParams(),
                        [`parent__${parentCleint}`]: `@${parentCleint}`
                    };
                } else if (this.parentId) {
                    return { parentId: '@parentId' };
                }
            }
        }

        getResourceUrl() {
            return `${this.getUrlPath()}/:id`;
        }

        getUrlPath(options: { absolute?: boolean, prefillIds?: boolean } = { absolute: true }) {
            const prefix = options.absolute ? `${ApiConfig.ROOT}/` : '/';
            const suffix = this.modeEditionRapide ? '/edition' : '';

            if (this.nomSourceDetails) {
                return `${prefix}${this.nomSourceDetails}`;
            } else if (this.multiOccurrenceParent && options.prefillIds) {
                return `${this.multiOccurrenceParent.getUrlPath(options)}/${this.multiOccurrenceParent.activeRowCleint}/${this.bloc}${suffix}`;
            } else if (this.multiOccurrenceParent) {
                return `${this.multiOccurrenceParent.getUrlPath(options)}/:parent__${this.multiOccurrenceParent.cleint}/${this.bloc}${suffix}`;
            } else if (this.bloc && options.prefillIds) {
                return `${prefix}${this.srccod}/${this.parentId}/${this.bloc}${suffix}`;
            } else if (this.bloc) {
                return `${prefix}${this.srccod}/:parentId/${this.bloc}${suffix}`;
            } else if (this.parentId) {
                return `${prefix}${this.srccod}/${this.parentId}${suffix}`;
            } else {
                return `${prefix}${this.srccod}${suffix}`;
            }
        }

        private initDataTypes(dataTypes: IDataTypeMap) {
            Object.assign(this.dataTypes, dataTypes);

            this.getAllColonnes().forEach((col: string) => {
                if (this.schema[col] && !this.dataTypes[col]) {
                    this.dataTypes[col] = new DataType({ schemaItem: this.schema[col] });
                }
            });

            Object.keys(this.dataTypes).forEach((col) => {
                if (this.dataTypes[col] instanceof ImputationDataType) {
                    Object.assign(this.libelles, { [col.toUpperCase()]: profil.compagnie.imputation.libelle });
                    Object.assign(this.libelles.$libelles, { [col.toUpperCase()]: profil.compagnie.imputation.libelle });

                    //Si la colonne fait partie des critères suggérés, on doit ajouter la valeur par défaut pour la réinitialisation.
                    const champCriteresSuggeres = (this.criteresSuggeres && this.criteresSuggeres.liste.find((champ: IFormulaireItem) => {
                        return champ.col === col;
                    }));
                    if (champCriteresSuggeres) {
                        this.etatDefault.criteresSuggeresData[col] = this.etatDefault.criteresSuggeresData[col] || "";
                    }
                }
            });
        }

        private getAllColonnes() {
            return Object.values(this.colonnesParametres).map((colonne: IColonne) => colonne.nom);
        }

        private getColonnesVisibles() {
            return this.colonnesFixesGauche.concat(this.etatSelected.colonnesVisibles, this.colonnesFixesDroite);
        }

        getColonnesFixe() {
            return this.colonnesFixesGauche.concat(this.colonnesFixesDroite);
        }

        private getColsParam() {
            // On enlève les duplicats
            const cols = Array.from(new Set(this.colonnesFixesGauche.concat(
                this.cleint,
                this.etatSelected.colonnesVisibles,
                this.colonnesFixesDroite,
                this.colonnesCachees,
                this.colonnesSysteme
            ))).filter((el) => { return el !== "" });
            // Durant l'édition rapide, il faut obtenir les champs d'imputation sinon lorsque la validation les obtiendra,
            // l'enregistrement appraîtra comme modifié
            if (this.modeEditionRapide) {
                cols.forEach((col: string) => {
                    if (this.dataTypes[col] instanceof ImputationDataType && this.dataTypes[col].params.cols) {
                        Object.keys(this.dataTypes[col].params.cols).forEach((imputationCol: string) => {
                            if (!cols.includes(imputationCol)) {
                                cols.push(imputationCol);
                            }
                        });
                    }
                });
            }

            return cols.join(',');
        }

        async fetchDataList(options: { isChangementPage?: boolean, forceFetch?: boolean, flagFab?: boolean } = {}) {
            //si le flag de selection multiple est activée on empeche le lancement de requete
            if (this.flagValDefSelectionMultiple) return
            if (this.fonctions.criteresSuggeresVisibles && !options.flagFab) return
            if (!options.forceFetch && this.fetchingDataList && this.fonctions.skipDoubleRequetes && !options.isChangementPage) return this.dataListQuery.$promise as Promise<any>
            this.toutSelectionne = false;
            this.fetchingDataList = true;
            this.dataListQuery = this.DataResource.query({ ...this.getDataListQueryParams() }) as IResourceArray<any>;
            return this.dataListQuery.$promise.then((result: any) => {
                if (!this.dataListQuery.$resolved) return
                if (this.dataListQuery.length === 0 && this.etatSelected.pagination.pageCourante > 1) {
                    if (options.isChangementPage) {
                        // Pour permettre des changements de page rapide, on revient d'une page jusqu'à la dernière page.
                        this.etatSelected.pagination.pageCourante--;
                        this.fetchingDataList = false
                        this.fetchDataList();
                    } else {
                        // Si on est pas sur la première page et qu'on a aucun résultat, on revient sur la première page
                        this.etatSelected.pagination.pageCourante = 1;
                        this.fetchingDataList = false
                        this.fetchDataList();
                    }
                } else {
                    const response = <IHttpResponse<any>>result;
                    if (this.selectionLocale) {
                        for (const itemSelectionLocale of this.selectionLocale) {
                            if (!this.dataListQuery.some(dataListItem => dataListItem[this.cleint] === itemSelectionLocale[this.cleint])) {
                                this.dataListQuery.unshift(itemSelectionLocale);
                            }
                        }
                    }
                    this.dataListReady = true;
                    this.dataListError = false;
                    this.dataListErrorClient = null;

                    this.etatSelected.pagination.total = Number(response.headers('X-Total-Count'));
                    this.dataList = this.dataListQuery;
                    this.fetchingDataList = false;

                    if (response.headers('X-Compteur')) {
                        this.compteur = Number(response.headers('X-Compteur'));
                    }
                    this.activeRowCleint = this.dataList[0] && this.dataList[0][this.cleint]

                    this.emit('dataListUpdate');
                }
            }).catch((err: any) => {
                this.emit('dataListUpdateError');
                if (this.fonctions.reloadParentOnError && this.multiOccurrenceParent) {
                    this.multiOccurrenceParent.fetchDataList()
                } else {
                    this.fetchingDataList = false;
                    this.dataListError = true;
                }
                // Ceci est temporaire, le temps que Marie-Josée et jeff norme la présentation des erreurs pour l'application.
                // Pour le moment, ce que l'on veut est d'afficher un message en dialogue...
                // Ceci sera présenté seulement pour les erreurs de validation de paramètres (VALIDATION_PARAMETRE)
                if (err && err.data && err.data.code && (err.data.code.startsWith('SOFE-2') || err.data.code === 'REQUETE_INVALIDE')) {
                    this.dataListErrorClient = {
                        code: err.data.code,
                        description: err.data.message
                    };
                }
            })
        }

        refreshChampsTransactionnels() {
            // On n'a besoin des champs transactionnels que si le multi fait de l'édition, de la création ou de la suppression dans un bloc
            if (this.bloc && (this.ecranSourceDetails.cycleCleint || this.ecranSourceDetails.cycleParent) && (this.fonctions.edition || this.fonctions.supprime || (this.fonctions.nouveau && this.actionsNouveaux && this.actionsNouveaux.listeMenuItem.length && !this.hasNouveauForage()))) {
                this.fetchingChampsTransactionnels = true;

                return this.fetchChampsTransactionnels().then((champsTransactionnels: IChampsTransactionnels) => {
                    this.champsTransactionnelsError = false;
                    this.champsTransactionnels = champsTransactionnels;
                    //On enlève la fonction de suppression si le cycle ne le permet pas.
                    this.fonctions.supprime = (champsTransactionnels.vaeceptypmod !== 'TOUS') ? false : this.fonctions.supprime;
                    return this.champsTransactionnels;
                }).catch((erreur: any) => {
                    this.champsTransactionnelsError = true;
                    return $q.reject(erreur);
                }).finally(() => {
                    this.fetchingChampsTransactionnels = false;
                });
            } else {
                this.champsTransactionnels = {};
                return $q.resolve(this.champsTransactionnels);
            }
        }

        private fetchChampsTransactionnels() {
            const params = {
                srccod: this.getNomSourceDetails(),
                srccodref: this.stateParams.srccod,
                cleint: this.getCycleId(),
                ...parametresSecurite(this.stateParams, this.ecranDetails)
            };

            if (this.ecranSourceDetails.cycleCleint === 'doccleint' || this.ecranSourceDetails.cycleCleintParent === 'doccleint') {
                return ChampsEvenementsResource.get(params).$promise;
            } else {
                return ChampsTransactionnelsResource.get(params).$promise;
            }
        }

        getCycleId() {
            if (this.multiOccurrenceParent) {
                return this.multiOccurrenceParent.getCycleId();
            } else {
                return this.cycleId || this.parentId;
            }
        }

        downloadDataList(contextData: IData) {
            const queryParams = {
                sortby: this.getDataListSortByQueryParam(),
                filter: this.getDataListFilterQueryParam(),
                replacedValues: `${this.getEntetesReplacedValuesQueryParam()};${this.getDataListReplacedValuesQueryParam()}`,
                ...this.etatSelected.criteresSuggeresData,
                cleint: this.cycleId || this.parentId,
                ...this.getResourceParamsValues(),
                isDownload: true,
                ...this.getParentParams(),
                ...this.stateParams,
                ...this.currentQueryParams
            };
            queryParams.limit = undefined;

            const cols = this.getColonnesVisibles().filter((colonne: string) => {
                const colonneParametre = this.colonnesParametres[colonne];
                const hidden = colonneParametre && colonneParametre.hidden;

                if (typeof hidden === 'function') {
                    return !hidden(contextData);
                } else {
                    return !(hidden ? true : false);
                }
            });

            queryParams.cols = cols.join(',');

            this.downloadingDataList = true;

            let resourceUrl = (this.resourceUrl || this.getUrlPath({ prefillIds: true })).replace(ApiConfig.ROOT, '');

            return downloadManager.download(resourceUrl, queryParams, this.stateParams)
                .finally(() => this.downloadingDataList = false);
        }

        getParentQueryParams() {
            if (this.multiOccurrenceParent) {
                const parentCleint = this.multiOccurrenceParent.cleint;
                return {
                    ...this.multiOccurrenceParent.getParentQueryParams(),
                    ...this.multiOccurrenceParent.etatSelected.criteresSuggeresData,
                    [`parent__${parentCleint}`]: this.multiOccurrenceParent.activeRowCleint
                };
            } else if (this.parentId) {
                return { parentId: this.parentId };
            }
        }

        getDataActiveRow() {
            return this.dataList && this.activeRowCleint && this.dataList.find(ele => ele[this.cleint] === this.activeRowCleint);
        }

        getParentIds() {
            if (this.multiOccurrenceParent) {
                const parentIds = [...this.multiOccurrenceParent.getParentIds()];
                // Dans certains cas de création, le activeRowCleint du parent est déjà à null,
                // on souhaite alors éviter d'ajouter plusieurs valeurs nulles à la suite.
                return (!parentIds[parentIds.length - 1]) ? parentIds : [
                    ...parentIds,
                    this.activeRowCleint
                ];
            } else if (this.parentId) {
                return [
                    this.parentId,
                    this.activeRowCleint
                ];
            } else {
                return [this.activeRowCleint];
            }
        }

        getDataListQueryParams() {
            const parameters: IMultiOccurrenceQueryParams = {
                sortby: this.getDataListSortByQueryParam(),
                filter: this.getDataListFilterQueryParam(),
                limit: (!this.fonctions.rechercheSansLimite) ? this.etatSelected.pagination.getLimit() : undefined,
                cols: this.getColsParam(),
                ...this.getResourceParamsValues(),
                includeLovs: this.modeEditionRapide,
                ...this.etatSelected.criteresSuggeresData,
                ...this.getParentQueryParams(),
                ...this.getColonnesAxe()
            };

            const changedParams = this.getChangedQueryParams(parameters);

            // Si des paramètres de recherche ont changés (à l'exception des colonnes affichées) on remet la page au départ.
            if (Object.keys(changedParams).length &&
                !(changedParams.cols && Object.keys(changedParams).length === 1)) {
                this.etatSelected.pagination.pageCourante = 1;
            }

            parameters.start = this.etatSelected.pagination.getStart();
            this.currentQueryParams = parameters;
            return parameters;
        }
        getColonnesAxe(): any {
            if (this.axesAffichage.axeActive.axeNom === AxesAffichage.AXES_AFFICHAGE.LIST) {
                return { _: "" }
            }
            const options = this?.axesAffichage?.axeActive?.options;
            let valeurAxe: any = undefined;
            //on ajoute l'axe juste pour les axes qui ont des options
            if (options) {
                switch (options?.viewMode) {
                    case "dayGridMonth":
                        valeurAxe = "MOI";
                        break;
                    case "timeGridWeek":
                        valeurAxe = "SEM";
                        break;
                    case "timeGridDay":
                        valeurAxe = "JRS";
                        break
                    default:
                        valeurAxe = "MOI";
                        break;
                }
            }
            return { axecal: valeurAxe };
        }
        getDataListSortByQueryParam(): string {
            return this.etatSelected.tri.toString();
        }

        getDataListFilterQueryParam() {
            this.filterDateAxeList();
            return this.etatSelected.filtres.filter((filtre) => !filtre.affichage && ((filtre.getValeur() !== '') || filtre.operateur.isAucuneValeurOperateur())).map((filtre) => {
                let colonne;
                let tmp: any = [];
                if (filtre.colonne === Filtre.CLE_RECHERCHE_GLOBALE) {
                    if (this.etatSelected.colonnesFiltrables.length < 1) {
                        this.defineEtatColonnesFiltrables(this.etatSelected);
                    }
                    //si la colonne filtrable est vide on l'enleve de la liste car elle genere un bad request dans le back-end
                    if (this?.etatSelected?.colonnesFiltrables && Array.isArray(this.etatSelected.colonnesFiltrables)) {
                        tmp = this.etatSelected.colonnesFiltrables.filter((el) => { return el !== "" }).join(',');
                    } else {
                        tmp = this.etatSelected.colonnesFiltrables.join(',');
                    }

                    colonne = tmp;
                } else {
                    colonne = filtre.colonne;
                }
                return filtre.toUrlAttribut(colonne);
            }).join(';');
        }

        filterDateAxeList() {
            let colonnes: any;
            if (Array.isArray(this?.axesAffichage?.axes)) {
                this.axesAffichage.axes.forEach((el) => {
                    if (el?.axeNom === AxesAffichage.AXES_AFFICHAGE.CALENDAR && el?.options) {
                        colonnes = (el?.options?.colonneDateDebut + "," + el?.options?.colonneDateFin).toString()
                    }
                })
            }
            const filtreDate = this.etatSelected.filtres.find((filtre: IFiltre) => filtre.colonne === colonnes);
            const filtreDateAffichable = this?.etatSelected?.criteresSuggeresData?.date
            if (typeof (filtreDate) !== "undefined" && this.axesAffichage.axeActive.axeNom === AxesAffichage.AXES_AFFICHAGE.LIST && !filtreDateAffichable) {
                const removeFiltre = this.etatSelected.filtres.indexOf(filtreDate)
                this.etatSelected.removeFiltre(removeFiltre)
            }
        }

        getDataListReplacedValuesQueryParam() {
            return Object.keys(this.colonnesParametres)
                .filter((col: string) => this.colonnesParametres[col].replacedValue)
                .map((col: string) => {
                    const colonne = this.colonnesParametres[col];
                    const value = this.getReplacedValue(colonne.replacedValue);

                    return `${col}:${window.encodeURIComponent(value)}`;
                }).join(';');
        }

        getEntetesReplacedValuesQueryParam() {
            return Object.keys(this.entetesReplacedValues).map((col: string) => {
                const value = this.entetesReplacedValues[col];

                return `${col}:${window.encodeURIComponent(value)}`;
            }).join(';');
        }

        private getReplacedValue(replacedValue: Function | string) {
            if (replacedValue instanceof Function) {
                return replacedValue(this);
            } else {
                return replacedValue;
            }
        }

        /**
         * Retourne la différences entre les novueaux paramètres de recherche et les précédents
         *
         * @param params
         * @returns {any}
         */
        getChangedQueryParams(params: IMultiOccurrenceQueryParams) {
            const clonedParams = angular.extend({ ...params });

            Object.keys(this.currentQueryParams).forEach((paramName: string) => {
                if (clonedParams[paramName] === this.currentQueryParams[paramName]) {
                    delete clonedParams[paramName];
                }
            });

            return clonedParams;
        }

        saveRowDetails(rowDetails: any) {
            if (rowDetails[this.forageCleint]) {
                const params: any = {
                    id: rowDetails[this.forageCleint],
                    ...this.etatSelected.criteresSuggeresData,
                    ...this.getParentQueryParams()
                };

                if (this.ecranSourceDetails.sourceDeveloppement) {
                    if (!profil.preferences.itvcleint) {
                        return $q.reject({ interventionRequise: true });
                    }

                    params.itvcleint = profil.preferences.itvcleint;
                }

                return this.DataResource.update(params, rowDetails).$promise.then((updatedRowDetails: IEtatsResourcesEntite) => {
                    const index = this.dataList.findIndex((data) => data[this.forageCleint] === rowDetails[this.forageCleint]);

                    // On met à jour l'élément dans la liste de donnée
                    if (index !== -1) {
                        this.dataList[index] = { ...this.dataList[index], ...updatedRowDetails };
                        this.dataList[index].$opened = false;
                        //On doit indiquer qu'il y a eu un change dans les données de la liste
                        this.emit('dataListUpdate');
                    }
                });
            } else {
                const params: any = {
                    ...this.etatSelected.criteresSuggeresData,
                    ...this.getParentQueryParams()
                };

                if (this.ecranSourceDetails.sourceDeveloppement) {
                    if (!profil.preferences.itvcleint) {
                        return $q.reject({ interventionRequise: true });
                    }

                    params.itvcleint = profil.preferences.itvcleint;
                }

                return this.DataResource.save(params, rowDetails).$promise.then(() => {
                    this.fetchDataList();
                });
            }
        }

        deleteRowDetails(rowDetails: any) {
            const params: any = {
                id: rowDetails[this.forageCleint],
                ...this.etatSelected.criteresSuggeresData,
                ...this.getParentQueryParams()
            };

            if (this.ecranSourceDetails.sourceDeveloppement) {
                if (!profil.preferences.itvcleint) {
                    return $q.reject({ interventionRequise: true });
                }

                params.itvcleint = profil.preferences.itvcleint;
            }

            return this.DataResource.delete(params).$promise.then(() => {
                // On ne peut pas simplement supprimer l'élément, car s'il est le dernier de la page, il faut
                // aller en recherche d'autres
                this.fetchDataList();

                if (this.onDelete) {
                    this.onDelete()
                }
            });
        }

        saveAll(data?: Array<any>) {
            this.savingDataList = true;

            const params: any = {
                ...this.getDataListQueryParams()
            };

            let dataList = this.dataList.filter((data, index) => this.hasChangementsNonSauvegardes(data, index)).map((data: any) => ({ ...data, $editionRapideCtrl: undefined }));

            if (data) {
                dataList.push(...data);
            }

            if (this.ecranSourceDetails.sourceDeveloppement) {
                if (!profil.preferences.itvcleint) {
                    return $q.reject({ interventionRequise: true });
                }

                params.itvcleint = profil.preferences.itvcleint;
            }

            return this.DataResource.updateAll(params, dataList).$promise.then(() => {
                this.fetchDataList();
            }).finally(() => {
                this.savingDataList = false;
            });
        }

        fetchValeursDefaut() {
            if (this.procedureValeursDefautDefinie) {
                const ids = this.getParentIds();
                if (ids.length > 1 && !ids[ids.length - 1]) {
                    ids.pop();
                }

                const params: any = {
                    srccod: this.getSrccodref(),
                    ids: ids.join(','),
                    ...this.getResourceParamsValues(),
                    ...parametresSecurite(this.stateParams, this.ecranDetails)
                };

                this.stateParams.route.QUERY_PARAMS.forEach((param: string) => {
                    const nomParam = param.replace(/^\?/, '');
                    params[nomParam] = this.stateParams[nomParam];
                });

                this.fetchingValeursDefaut = true;
                return ValeursDefautResource.get(params).$promise.then((data: any) => {
                    this.valeursDefaut = { ...this.valeursDefaut, ...data }
                    this.valeursDefautError = false;
                    return data;
                }).catch(() => {
                    this.valeursDefautError = true;
                }).finally(() => {
                    this.fetchingValeursDefaut = false;
                });
            } else {
                return $q.resolve();
            }
        }

        getNomSourceDetails(options?: { baseOnly: boolean }) {
            if (this.nomSourceDetails) {
                return this.nomSourceDetails;
            }

            const suffix = this.modeEditionRapide && !(options && options.baseOnly) ? '-edition' : '';
            if (this.multiOccurrenceParent) {
                return `${this.multiOccurrenceParent.getNomSourceDetails()}-${this.bloc}${suffix}`;
            } else if (this.bloc) {
                return [this.srccod, this.bloc].join('-') + suffix;
            } else {
                return this.srccod + suffix;
            }
        }

        getSrccodref() {
            return this.srccodref || this.getNomSourceDetails();
        }

        fetchSourceDetails() {
            const params: any = {
                ...this.stateParams,
                ...this.getResourceParamsValues(),
                srccod: this.getNomSourceDetails().toLowerCase(),
                ...parametresSecurite(this.stateParams, this.ecranDetails)
            };

            if (this.stateParams.id) {
                params.cleint = this.stateParams.id;
            }

            return sourceDetailsManager.fetch(this.getNomSourceDetails(), null, params).then((sourceDetails: ISourceDetails) => {
                this.setSourceDetails(sourceDetails);
            });
        }

        /**
         * Cette fonction sert a déterminer si le dataType d'une colonne est de type boolean
         * (Les boutons toujours visibles sont considérés comme des boolean (indicateurs))
         * @param colonne
         * @returns {boolean}
         */
        isBooleanDataType(colonne: string) {
            return this.dataTypes[colonne] instanceof BooleanDataType || (
                this.dataTypes[colonne] instanceof BoutonDataType &&
                this.dataTypes[colonne].params &&
                this.dataTypes[colonne].params.toujoursVisible
            );
        }

        private setSourceDetails(sourceDetails: ISourceDetails) {
            this.libelles = new Libelles({ ...sourceDetails.libelles, ...this.entetesReplacedValues });
            this.schema = sourceDetails.schema;
            this.comportements = Comportement.init(sourceDetails.comportements);
            this.fonctionsEdition = FonctionsEdition.init(sourceDetails.fonctionsEdition);
            this.selections = sourceDetails.selections;
            this.validatedFields = sourceDetails.validatedFields;
            this.listenedFields = sourceDetails.listenedFields;
            this.reglesValidation = sourceDetails.validationRules;
            this.mnemonique = sourceDetails.mnemonique.toUpperCase();
            this.messages = sourceDetails.messages;
            this.colonnesEnteteTitre = sourceDetails.colonnesEnteteTitre;
            this.procedureValeursDefautDefinie = sourceDetails.procedureValeursDefautDefinie;
            this.isView = sourceDetails.isView;

            this.initDataTypes(sourceDetails.dataTypes);

            // Si le cleint n'est pas explicitment passé, utilise celui de la source
            if (!this.cleint) {
                this.cleint = sourceDetails.cleint;
            }

            if (this.schema.demcleint || this.schema.eplcleint) {
                this.hasAccesMenuEmploye = true;
            }

            this.cycleCleint = sourceDetails.cycleCleint;
            this.forageCleint = this.cycleCleint || this.cleint;

            // La forageCleint est maintenant certaines d'être définie, alors on peut l'ajouter aux colonnes système.
            this.colonnesSysteme.push(this.forageCleint);
        }

        fetchEtats() {
            if (!this.fonctions.selectionnerUnEtat) {
                this.selectEtat(this.etatDefault);
                this.restoreEtatTemporaire();
            } else {
                this.fetchingEtats = true;

                const params = {
                    gtecleref: this.gtecleref,
                    ...parametresSecurite(this.stateParams, this.ecranDetails)
                };

                this.etatsQuery = EtatsResource.query(params);

                return this.etatsQuery.$promise.then(() => {
                    this.etatsError = false;
                    this.fetchingEtats = false;

                    const etats = this.etatsQuery;
                    // Il faut définir les colonnesDisponibles en se basant sur l'état par défaut
                    etats.forEach((etat: IEtatsResourcesEntite) => {
                        this.defineEtatColonnesDisponibles(etat);
                        this.defineEtatColonnesFiltrables(etat);
                    });

                    this.etats = etats;

                    this.selectEtat(this.etatDefault);
                }).catch(() => {
                    this.fetchingEtats = false;
                    this.etatsError = true;
                    this.etats = [];
                    this.selectEtat(this.etatDefault);
                    // On ne veut pas arrête le chargement de la page
                    return $q.resolve();
                }).finally(() => {
                    if (!this.forcerEtatPredefinisDepart) {
                        this.restoreEtatTemporaire();
                    }
                });
            }
        }

        private restoreEtatTemporaire() {
            const storedEtat = etatStore.fetch(this);
            if (storedEtat && storedEtat.etat) {
                this.defineEtatColonnesDisponibles(storedEtat.etat);
                this.defineEtatColonnesFiltrables(storedEtat.etat);

                const pageCourante = storedEtat.pageCourante;
                const etatSelectedOriginal = this.etatSelected;
                this.selectEtat(storedEtat.etat);

                if (pageCourante && pageCourante !== 1) {
                    // Lors de la recherche initiale, la page courante est remise à '1' si des changements aux critères
                    // sont détectés. En obtenant les queryParams ici, on établit les critères courant, ce
                    // qui préviendra le changement de la page courante.
                    this.getDataListQueryParams();
                    this.etatSelected.pagination.pageCourante = pageCourante;
                }

                // Il nous faut préserver l'état sur lequel est basé notre état temporaire
                this.etatSelectedOriginal = etatSelectedOriginal;
            }
        }

        private defineEtatColonnesDisponibles(etat: IEtatsResourcesEntite) {
            etat.colonnesDisponibles = [];

            // On définit toutes les colonnes disponibles en se basant sur l'état par défaut
            this.etatDefault.getAllColonnes().forEach((colonne: string) => {
                if (!etat.colonnesVisibles.includes(colonne)) {
                    etat.colonnesDisponibles.push(colonne);
                }
            });
        }

        defineEtatColonnesFiltrables(etat: IEtatsResourcesEntite) {
            etat.colonnesFiltrables = this.editionRapideActive ? etat.colonnesVisibles : [].concat(this.colonnesFixesGauche, etat.colonnesVisibles, this.colonnesFixesDroite);
            //on valide s'il y en a champs non Filtrables
            const filter = this.getColonnesNonFiltrables()
            if (filter && filter.length > 0) {
                etat.colonnesFiltrables = etat.colonnesFiltrables.filter((el) => !filter.includes(el))
            }
        }

        private getColonnesNonFiltrables(): string[] {
            let result: string[] = []
            //recherche des champs non filtrables
            if (this?.colonnesParametres) {
                Object.values(this?.colonnesParametres).forEach((el) => {
                    //on utilise le flag hideFromRecheche afin de cacher le champ de la barre de recherche
                    if (el && el?.hideFromRecheche) {
                        result.push(el.nom)
                    }
                })
            }
            return result
        }

        private selectEtat(etat: IEtatsResourcesEntite) {
            const total = this.etatSelected && this.etatSelected.pagination.total;
            if (this.criteresSuggeres) {
                //Si des valeurs sont ignorées par la sauvegarde d'état,
                //on doit s'assurer que leur valeur n'est pas perdu au changement d'état.
                this.criteresSuggeres.flatListe
                    .filter((formulaireItem: IFormulaireItem) => formulaireItem.ignoreEtat)
                    .map((formulaireItem: IFormulaireItem) => formulaireItem.col)
                    .forEach((col: string) => {
                        etat.criteresSuggeresData[col] = (this.etatSelected) ?
                            this.etatSelected.criteresSuggeresData[col] : this.getCritereSuggereDefaultValue(col);

                        if (this.dataTypes[col] instanceof LovDataType) {
                            const colsDescription = this.dataTypes[col].params.description;

                            colsDescription.forEach((colDesc: string) => {
                                etat.criteresSuggeresData[`${col}__${colDesc}`] = (this.etatSelected) ?
                                    this.etatSelected.criteresSuggeresData[`${col}__${colDesc}`] : undefined;
                            });
                        }
                    });
            }

            this.etatSelectedOriginal = etat;
            this.etatSelected = etat.clone();

            // On doit réinsérer les filtres paramétrés car on ne les sauvegarde pas
            const filtresParametres = this.etatDefault.filtres.filter((filtre: IFiltre) => filtre.parametre);
            const filtresNonParametres = this.etatSelected.filtres.filter((filtre: IFiltre) => !filtre.parametre);
            this.etatSelected.filtres.splice(0, this.etatSelected.filtres.length, ...filtresParametres, ...filtresNonParametres);

            if (total != null) {
                this.etatSelected.pagination.total = total;
            }

            if (this.etatSelected.axeAffichageActif) {
                this.axesAffichage.changeAffichage(this.etatSelected.axeAffichageActif);
            }
        }

        getCritereSuggereDefaultValue(col: string) {
            if (this.fonctions.valeursEcranCriteres && this.ecranDetails.valeurs[col]) {
                return this.ecranDetails.valeurs[col]
            }

            const formulaireItem = this.criteresSuggeres.flatListe.find((formulaireItem: IFormulaireItem) => formulaireItem.col === col);
            if ((formulaireItem as IFormulaireItem).default instanceof Function) {
                return (formulaireItem as IFormulaireItem).default(this.etatDefault.criteresSuggeresData);
            } else {
                return (formulaireItem as IFormulaireItem).default;
            }
        }

        getEtatsPersonnels() {
            return this.etats.filter(etat => !etat.predefini && (etat.isEditionRapide === undefined || etat.isEditionRapide === this.editionRapideActive))
        }

        async updateSelectedEtat(etatSelected: IEtatsResourcesEntite, criteresSuggeresVisibles: boolean = false) {
            etatSelected.pmtcleint = undefined
            if (etatSelected?.id !== "$NONE$") {
                //on cherche si le pmtcleint exist
                await this.getPmtcleint(this.srccod, profil.preferences.usrcleint).then((result) => {
                    if (result) {
                        etatSelected.pmtcleint = result
                    }
                })
            } else {
                //quand on retourne à l'état aucun on netoye le local storage
                this.removeLovsFromLocalStorageSelectionMultiple(this.srccod)
                $rootScope.$broadcast("ex.input.lov.selection.multiple.all.valide.storage");
            }

            if (etatSelected.isEditionRapide !== undefined && etatSelected.isEditionRapide !== this.editionRapideActive) {
                etatSelected = this.etatDefault
            }

            let updating: IPromise<any>;
            let nombreElementPage: number;
            this.fonctions.criteresSuggeresVisibles = criteresSuggeresVisibles

            if (this.etatDefault.equal(etatSelected) && !etatSelected.predefini) {
                updating = Promise.resolve()
            } else if (this.etatsPredefinis.length && this.etatsPredefinis.includes(etatSelected)) {
                const etatPredefiniSelected = this.etats.find(etat => (etat.nom === etatSelected.nom));
                if (etatPredefiniSelected) {
                    updating = EtatsResource.update({
                        id: etatPredefiniSelected.id,
                        ...parametresSecurite(this.stateParams, this.ecranDetails)
                    }, etatPredefiniSelected).$promise;
                } else {
                    updating = this.saveEtat(etatSelected.clone());
                }
            } else {
                updating = EtatsResource.update({
                    id: etatSelected.id,
                    ...parametresSecurite(this.stateParams, this.ecranDetails)
                }, etatSelected).$promise;
            }

            return updating.then(() => {
                const updatedColonnesVisibles = !angular.equals(etatSelected.colonnesVisibles, this.etatSelected.colonnesVisibles);
                this.selectEtat(etatSelected);
                this.emit('selectedEtatUpdate', etatSelected);

                if (nombreElementPage) {
                    this.etatSelected.pagination.nombreElementPage = nombreElementPage;
                }

                if (updatedColonnesVisibles) {
                    this.emit('colonnesVisiblesUpdate');
                }

                this.saveEtatTemporaire();
                //on valide que l'état par defaut a été creé
                const col = "criteremultipledef" + this?.stateParams?.criteremultiplesrccod
                const flagCritereMultipleDef = this?.ecranDetails?.valeurs[col]
                if (flagCritereMultipleDef) {
                    this.flagValDefSelectionMultiple = true
                }

                //on doit attendre un instant pour la creation de l'etatSelected
                setTimeout(() => {
                    if (flagCritereMultipleDef) {
                        //on commence le traitement pour mettre valeurs par defaut dans la lov de selection multiple
                        this.viderValuesSelectionMultiple(this.srccod).finally(() => {
                            $rootScope.$broadcast('vm.multiOccurrence.etat.reinitialiser');
                            this.setSelectionMultipleValeursParDefaut(col)
                        })
                    } else {
                        if (typeof (etatSelected?.id) !== "string") {
                            this.getCriteresMultiplesListe().then(() => {
                                let listeChamps: any = []
                                const data: any = []

                                this.etatSelected.criteresMultiplesListe.forEach((el) => {
                                    const tmpData = {
                                        source: el.dataType.params.source,
                                        champsDescription: el.dataType.params.description,
                                        col: el.col
                                    }
                                    data.push(tmpData)
                                    //on va creer un dictionary de champs permis dans le local storage
                                    const datatype = el.dataType.params.description
                                    listeChamps.push(el.col)
                                    Object.values(datatype).forEach((cols) => {
                                        const champ = el.col + "__" + cols
                                        listeChamps.push(champ)
                                    })
                                })
                                const etatMultiple: IEtatsMultipleResourcesEntite = new EtatsMultipleResource({
                                    pmtcleint: etatSelected.pmtcleint,
                                    gtecleint: etatSelected.id
                                })
                                etatMultiple.$get({ id: etatSelected.id, data: JSON.stringify(data), gtecleint: etatSelected.id, srccod: this.srccod, ...parametresSecurite(this.stateParams, this.ecranDetails) })
                                    .then((res: any) => {
                                        //on efface les lovs du local storage
                                        this.removeLovsFromLocalStorageSelectionMultiple(this.srccod)
                                        let data = res?.result
                                        if (data > 0 || Array.isArray(data)) {
                                            const lov: any = this.getLovsNameSelectionMultiple(this.srccod)
                                            //on filtre les lov qui on été sauvergardes
                                            const filteredLovs = lov.filter((obj: any) =>
                                                data.some((filterObj: any) => obj.col === filterObj.col)
                                            );
                                            //on va re-creer le local storage avec les infos de la bd
                                            Object.values(filteredLovs).forEach((el: any) => {
                                                //filter les donnes par lov car le back-end nous donne tout l'information dans une seule requete
                                                let tmpData = []
                                                //on recherche les lov intervalle en utilisant le champ pmtcleintseq
                                                const uniquePmtcleintseqs = Array.from(new Set(data.map((dat: any) => dat.pmtcleintseq)));
                                                if (uniquePmtcleintseqs.length > 0 && typeof (uniquePmtcleintseqs[0]) !== "undefined") {
                                                    //on execute le processus par chaque sequence
                                                    uniquePmtcleintseqs.forEach((seq) => {
                                                        //filtre par sequence dans data
                                                        const itemsToProcess = data.filter((dat: any) => dat.pmtcleintseq === seq);
                                                        //il doit avoir 2 enregistrements
                                                        if (itemsToProcess.length === 2) {

                                                            const col = itemsToProcess[0].col
                                                            const idintervalle1 = itemsToProcess[0].id
                                                            const idintervalle2 = itemsToProcess[1].id
                                                            const intervalles = this.nettoyerDataSelectionMultiple(itemsToProcess, listeChamps, true)
                                                            const intervalle1 = intervalles[0]
                                                            const intervalle2 = intervalles[1]
                                                            //on cree le nouvel object
                                                            const resultat = {
                                                                id: idintervalle1,
                                                                [col + "Int1"]: this.getDataColonneIntervalle(intervalle1, col),
                                                                [col + "Int2"]: this.getDataColonneIntervalle(intervalle2, col),
                                                                idsIntervalle: { intervalleDe: idintervalle1, intervalleA: idintervalle2 }
                                                            }
                                                            tmpData.push(resultat)
                                                        }
                                                        //on elimine les objets type intervalle de la source
                                                        data = data.filter((dat: any) => dat.pmtcleintseq !== seq);
                                                    });
                                                } else {

                                                    tmpData = data.filter((info: any) => info.col === el.col)
                                                    tmpData = this.nettoyerDataSelectionMultiple(tmpData, listeChamps)
                                                }
                                                if (localStorage.getItem(el.lov) !== null) {
                                                    localStorage.removeItem(el.lov)
                                                }
                                                localStorage.setItem(el.lov, JSON.stringify(tmpData))
                                            })
                                        }
                                        $rootScope.$broadcast("ex.liste.pastille.update.selection.multiple");
                                        $rootScope.$broadcast("ex.input.lov.selection.multiple.all.valide.storage");
                                    })
                            })

                        }
                        else {
                            if (this.fonctions.effaceCriteres) {
                                this.getCriteresMultiplesListe().finally(() => {
                                    if (this.hasItemsSelectionMultiple()) {
                                        this.viderValuesSelectionMultiple(this.srccod).finally(() => {
                                            //quand on retourne à l'état aucun on netoye le local storage
                                            this.removeLovsFromLocalStorageSelectionMultiple(this.srccod)
                                            $rootScope.$broadcast("ex.liste.pastille.update.selection.multiple");
                                            $rootScope.$broadcast("ex.input.lov.selection.multiple.all.valide.storage")
                                        })
                                    }
                                })

                            }
                        }
                    }
                }, 10);
            });
        }

        nettoyerDataSelectionMultiple(tmpData: any, listeChamps: Array<string>, flag: boolean = false) {
            //on doit effacer les champs qui ne font partie du grid car le composant le trouve comme un doublon
            Object.values(tmpData).forEach((value: any) => {
                Object.entries(value).filter((key) => { return !listeChamps.includes(key[0]) }).forEach((del) => {
                    //on conserve l'id 
                    if (value[del[0]] && del[0] !== "id" || flag) {
                        delete value[del[0]]
                    }
                })
            })
            return tmpData
        }

        setSelectionMultipleValeursParDefaut(col: string) {
            //on avance si le flag a été activé exemple pour as0046 champ taycleint col=criteremultipledeftay
            if (this?.ecranDetails?.valeurs[col]) {
                //on vide le local storage
                this.removeLovsFromLocalStorageSelectionMultiple(this.srccod)
                this.getCriteresMultiplesListe().then(() => {
                    let listeChamps: any = []
                    //on filtre la lov a modifier avec les données par defaut
                    this.etatSelected.criteresMultiplesListe.filter((el) => this.stateParams.criteremultiplecol === el.col).forEach((el) => {
                        //on a besoin de chercher par colonne et retourner son correspondant car les champs description utilisent un format differente par exemple taycleint__taycod
                        //la liste de valeurs retourne les noms des colonnes de la vue exemple juste taycleint, taycod
                        listeChamps.push({
                            recherche: el.col,
                            correspondant: el.col
                        })
                        //on va creer un dictionary en ajoutant la colonne à rechercher et son correspondant
                        const datatype = el.dataType.params.description
                        Object.values(datatype).forEach((cols) => {
                            const tmpData = {
                                recherche: cols,
                                correspondant: el.col + "__" + cols
                            }
                            listeChamps.push(tmpData)
                        })
                    })
                    //on va faire la requete afin d'obtenir les données du back-end
                    let requete;
                    requete = $resource(`${ApiConfig.ROOT}/liste-valeurs/` + this.stateParams.criteremultiplesrccod, {
                        srccod: this.srccod,
                        ...parametresSecurite(this.stateParams)
                    });

                    requete.query().$promise.then((response: any) => {
                        //on continue s'il a des données
                        if (Array.isArray(response) && response.length > 0) {
                            //on obtiens les valeurs par defaut de la procedure obtenirValeurs
                            const valeursDefaut = this.ecranDetails.valeurs[col].split(',')
                            //on filtre les données
                            const filterData: any = []
                            response.forEach((el) => {
                                const tmp = valeursDefaut.filter((row: any) => Number.parseInt(row) === el[this.stateParams.criteremultiplecol])
                                if (tmp.length > 0) {
                                    filterData.push(el)
                                }
                            })
                            //on va créer la nouvelle lov
                            const newLov = filterData.map((item: any, index: number) => {
                                const newItem: { [key: string]: any } = { id: index + 1 };
                                listeChamps.forEach((mapping: any) => {
                                    newItem[mapping.correspondant] = item[mapping.recherche];
                                });
                                return newItem;
                            });
                            //on obtiens le nom de la lov à modifier
                            const nomLov = this.getLovsNameSelectionMultiple(this.srccod)
                                .filter((field) => field.col.trim() === this.stateParams.criteremultiplecol.trim())
                                .map(obj => obj.lov)

                            //on sauvegarde les données dans le local storage
                            if (Array.isArray(nomLov) && nomLov.length > 0) {
                                if (localStorage.getItem(nomLov[0]) !== null) {
                                    localStorage.removeItem(nomLov[0])
                                }
                                localStorage.setItem(nomLov[0], JSON.stringify(newLov))
                                //on enregistre les valeurs et on affiche la pastille
                                $rootScope.$broadcast("ex.input.lov.selection.multiple.save.data");
                                $rootScope.$broadcast("ex.liste.pastille.update.selection.multiple");
                                if (!this.fonctions.criteresSuggeresVisibles) {
                                    this.flagValDefSelectionMultiple = false;
                                    this.fetchDataList();
                                }
                            }
                        }
                    });
                })
            }
        }
        async saveEtat(etatOriginal: IEtatsResourcesEntite) {
            const etat: IEtatsResourcesEntite = etatOriginal.clone();
            let pmtcleint = 0
            const validePmtCleint = this.hasItemsCriteresMultiples()
            if (validePmtCleint.length > 0) {
                //on remets le pmtcleint 
                await this.getPmtcleint(this.srccod, profil.preferences.usrcleint).then((result) => {
                    if (result) {
                        pmtcleint = result
                    }
                })
            }
            const total = etat.pagination.total;

            if (etat.criteresSuggeresData && this.criteresSuggeres) {
                //On retire les valeurs des champs qui ne doivent pas être enregistrées dans l'état
                this.criteresSuggeres.liste
                    .filter((formulaireItem: IFormulaireItem) => formulaireItem.ignoreEtat)
                    .map((formulaireItem: IFormulaireItem) => formulaireItem.col)
                    .forEach(col => etat.criteresSuggeresData[col] = this.fonctions.valeursEcranCriteres ? this.ecranDetails.valeurs[col] : undefined);
            }

            if (etat.nom === this.etatSelected.nom) {
                return etat.$update(parametresSecurite(this.stateParams, this.ecranDetails)).then((nouvelEtat: IEtatsResourcesEntite) => {
                    const etatToUpdate = this.etats.find(etat => etat.id === nouvelEtat.id);
                    this.defineEtatColonnesFiltrables(nouvelEtat);
                    angular.extend(etatToUpdate, nouvelEtat);
                    etatToUpdate.pagination.total = total;
                    this.selectEtat(nouvelEtat);
                });
            } else {

                return etat.$save({ id: null, ...parametresSecurite(this.stateParams, this.ecranDetails) }).then((nouvelEtat: IEtatsResourcesEntite) => {
                    //eviter de creer  un bug à cause de sauvegader le dernier etat
                    try { localStorage.setItem(etat.gtecleref.trim(), etat.id.toString()); }
                    catch (e) { }

                    // On doit faire une copie, afin de garder les filtres prédéfinis (le back-end ne garde pas cette information)
                    const etatPredefiniClone = this.etatsPredefinis[0] ? this.etatsPredefinis[0].clone() : undefined;
                    angular.extend(etat, nouvelEtat);

                    etat.pagination.total = total;
                    this.defineEtatColonnesDisponibles(nouvelEtat);
                    this.defineEtatColonnesFiltrables(nouvelEtat);
                    if (!etat.predefini) {
                        this.etats.push(etat);
                        this.selectEtat(etat);
                    } else {
                        if (etatPredefiniClone) {
                            this.etats.push(etat);
                            this.selectEtat(etatPredefiniClone);
                        }
                    }
                    //si le pmtcleint existe alors on garde l'information
                    if (pmtcleint) {
                        const etatMultiple: IEtatsMultipleResourcesEntite = new EtatsMultipleResource({
                            pmtcleint: pmtcleint,
                            gtecleint: etat.id
                        })
                        etatMultiple.$save({ id: null, pmtcleint: pmtcleint, gtecleint: etat.id, ...parametresSecurite(this.stateParams, this.ecranDetails) })
                    }
                });
            }
        }

        deleteEtat(etat: IEtatsResourcesEntite) {
            return EtatsResource.delete({
                id: etat.id,
                ...parametresSecurite(this.stateParams, this.ecranDetails)
            }).$promise.then(() => {
                // Si l'état actif est celui qui est supprimé, on retourne à l'état de base
                if (this.etatSelectedOriginal.equal(etat)) {
                    return this.reinitialiser();
                }
            }).then(() => {
                const index = this.etats.indexOf(etat);
                this.etats.splice(index, 1);
            });
        }

        getEtatPredefiniById(id: number) {
            return this.etatsPredefinis.find((etat) => etat.id === id);
        }

        reinitialiser() {
            this.activeRowCleint = undefined

            const newEtat = this.etatDefault.clone()
            if (this.criteresSuggeres) {
                this.criteresSuggeres.initFormData(newEtat.criteresSuggeresData, this.dataTypes)
            }

            return this.updateSelectedEtat(newEtat, this.fonctions.reinitialiserOuvrirCriteres || (this.criteresSuggeres && this.criteresSuggeres.flatListe.some((f: IFormulaireItem) => f.required && !f.default)))
        }
        reinitialiserPastillefiltre() {
            try {
                this.etatSelected.filtres = []
                this.reinitialiser()
            } catch (error) {
            }
        }
        reinitialiserDataOnglet() {
            setTimeout(() => {
                try {
                    this.dataList = [];
                    this.emit('dataListUpdate');
                } catch (error) {
                }
            })
        }

        toggleToutSelectionne(menu?: IMenu | IMenuItem) {
            this.dataList.forEach((data: any) => {
                if (menu && this.toutSelectionne) {
                    if (menu.disabled instanceof Function) {
                        data[this.selectionColonne] = !menu.disabled(data);
                    } else {
                        data[this.selectionColonne] = !menu.disabled;
                    }
                } else {
                    data[this.selectionColonne] = this.toutSelectionne;
                }
            });
        }

        getSelectedRows() {
            if (this.dataList) {
                return this.dataList.filter((data: any) => data[this.selectionColonne]);
            } else {
                return [];
            }
        }

        hasEdition(data: any) {
            if (this.fonctions.edition instanceof Function) {
                return (this.formulaire || this.selectionPersistante) && Boolean(this.fonctions.edition(data));
            } else {
                return (this.formulaire || this.selectionPersistante) && this.fonctions.edition;
            }
        }

        hasSuppression(data: any) {
            if (this.fonctions.supprime instanceof Function) {
                return Boolean(this.fonctions.supprime(data));
            } else {
                return this.fonctions.supprime;
            }
        }

        updateDataList(isChangementPage?: boolean, saveEtatTemporaire: boolean = true) {
            this.emit('etatUpdate');
            this.toutSelectionne = false;

            if (saveEtatTemporaire) {
                this.saveEtatTemporaire();
            }
            if (!this.preventDataListUpdate) {
                this.autoFetch || this.hasFiltreOrCritere() ? this.fetchDataList({ isChangementPage }) : this.dataList = [];
            }
        };

        startWatching(scope: IScope) {
            const onQueryChange = (newValue: any, oldValue: any, scope?: IScope, isChangementPage?: boolean) => {
                if (newValue !== oldValue) {
                    this.updateDataList(isChangementPage, true);
                }
            };

            this.ready.then(() => {
                if (this.hasGridElement) {
                    // Élément
                    const removeWatcher = scope.$watch(() => this.getMultiOccurrenceElement().length, (mutliOccurenceElementExiste) => {
                        if (mutliOccurenceElementExiste) {
                            this.element = this.getMultiOccurrenceElement();
                            removeWatcher();
                        }
                    });
                }

                scope.$watchCollection(() => Object.keys(this.etatSelected.criteresSuggeresData).map(key => this.etatSelected.criteresSuggeresData[key]), (newValue, oldValue) => {
                    $timeout(() => {
                        // Critères suggérés
                        const colsCriteresSuggeresFormulaire = (this.criteresSuggeres) ? this.getColonnesFormulaireCriteresSuggeres() : [];
                        if (colsCriteresSuggeresFormulaire.length || this.autoFetch) {
                            this.onEtatParamsChange(onQueryChange, newValue, oldValue);
                        }
                    });
                })

                scope.$watchCollection(() => this.etatSelected.filtres, (newValue, oldValue) => this.onEtatParamsChange(onQueryChange, newValue, oldValue))
                scope.$watch(() => this.etatSelected.pagination.nombreElementPage, onQueryChange);
                scope.$watch(() => this.etatSelected.pagination.pageCourante, (newValue, oldValue, scope) => onQueryChange(newValue, oldValue, scope, true));

                this.on('colonnesVisiblesUpdate', () => {
                    this.updateDataList();

                    if (this.fonctions.saveEtatTemporaire) {
                        this.saveEtatTemporaire();
                    }
                });
                scope.$on('multi-occurrence.remettre.div.edition', (event, obj) => {
                    if (!event.defaultPrevented) {
                        event.defaultPrevented = true;
                        //on remets le div dans son etat original pour les prochaines actions de dupliquer
                        const divRow = obj.bloc.concat("Parent")
                        let parentDiv = document.getElementById(divRow);
                        // on a reussie à trouver le div donc on l'ajoute, sinon le comportement sera l'ancien
                        if (parentDiv) {
                            let childDiv = document.getElementById(obj.bloc.concat("Child"));
                            if (childDiv) {
                                parentDiv.appendChild(childDiv);
                            }
                        }
                    }
                })

                scope.$on('ex-tab-tab-selected-multi-occurrence', (event, data) => {
                    this.setTabSelected(false)
                    if (!event.defaultPrevented) {
                        if (data && data?.bloc && this?.bloc) {
                            //on met le tab selected en utilisant le nom du bloc
                            if (this?.bloc.toLocaleLowerCase() === data.bloc.toLocaleLowerCase()) {
                                event.defaultPrevented = true;
                                this.setTabSelected(true)
                            }
                        }
                    }
                })
            });

            this.once('dataListUpdate', () => {
                if (this.element) {
                    $timeout(() => this.saveEtatTemporaire);
                }
            });

            // On enregistre aussi la rangée active
            scope.$watch(() => this.activeRowCleint, (newValue: number, oldValue: number) => {
                if (newValue !== oldValue && newValue != null) {
                    this.saveEtatTemporaire();
                }
            });
        }

        onEtatParamsChange(onQueryChange: Function, newValue: any, oldValue: any) {
            if (this.autoFetch || this.hasFiltreOrCritere()) {
                onQueryChange(newValue, oldValue);
            } else {
                $timeout(() => {
                    this.emit('etatUpdate');
                    this.saveEtatTemporaire();

                    if (!this.localDataList) {
                        this.dataList = [];
                        this.resetPagination();
                    }
                });
            }
        }

        private getMultiOccurrenceElement() {
            return angular.element(`#ex-multi-occurrence-${this.id}`);
        }

        saveEtatTemporaire() {
            if (this.etatSelected) {
                etatStore.save(this);
            }
        }

        hasChampSuiviModification() {
            return (this.schema.usrmod || this.schema.usrcre || this.schema.datcre || this.schema.datmod) && !isMobile;
        }

        toggleEditionRapide(toggle?: boolean) {
            if (toggle !== undefined) {
                this.editionRapideActive = toggle;
            } else {
                this.editionRapideActive = !this.editionRapideActive;
            }

            this.emit('editionRapideActiveUpdate');
        }

        unshiftRangeeEditionRapide() {
            //on ajoute un index different pour pouvoir enlever une ligne en blanche s'il en a plusieurs         
            const index = (this?.dataList?.length) ? this.dataList.length : 0;
            this.addRangeeEditionRapide(index);
        }

        pushRangeeEditionRapide() {
            this.addRangeeEditionRapide(this.dataList.length);
        }

        addRangeeEditionRapide(index: number) {
            const item = { $$index: index, ...this.valeursDefaut };
            this.dataList.splice(index, 0, item);

            if (this.element) {
                dataLinker.link(this.element, this.dataList[index], this.stateParams, this.ecranDetails);
            }

            // On ajoute les valeurs par défaut définis sur les colonnes
            this.etatSelected.colonnesVisibles.forEach((colonne: string) => {
                const colonneParam = this.colonnesParametres[colonne];
                if (colonneParam && colonneParam.default) {
                    this.dataList[index][colonne] = colonneParam.default instanceof Function ? colonneParam.default(this.dataList[index]) : colonneParam.default
                }
            });

            this.dataListCopy.splice(index, 0, {});
            this.indexEditionRapide = index;
            this.rangeeEditionRapide = this.dataList[index];
        }

        deleteRangeeEditionRapide(index: number) {
            this.dataList.splice(index, 1);
            this.dataListCopy.splice(index, 1);

            this.rangeeEditionRapide = null;
            this.indexEditionRapide = null;

            for (let i = index; i < this.dataList.length; i++) {
                this.dataList[i].$$index = i;
                this.dataListCopy[i].$$index = i;
            }
        }

        hasChangementsNonSauvegardes(formData: any, index: number) {
            const list = this.dataListCopy || this.dataList;

            if (formData.$deleted || index >= list.length) {
                return true;
            }

            return changementManager.compareChangements(formData, list[index], this.schema, this.dataTypes);
        }

        hasZoneRechercheVisible() {
            return Boolean(this.fonctions.afficherMenuSettingDansEntete &&
                (this.rechercheVisible ||
                    (this.etats && this.etats.length) ||
                    (this.etatsPredefinis && this.etatsPredefinis.length) ||
                    (
                        this.etatSelected &&
                        this.etatSelected.filtres &&
                        this.etatSelected.filtres.length
                    )
                )
            );
        }

        toggleRechercheVisible() {
            this.rechercheVisible = !this.rechercheVisible;

            if (this.rechercheVisible) {
                // Ajouter un délais sur le focus pour avoir le même délais que l'animation CSS(300ms).
                $timeout(() => {
                    const multiOccurrenceEl = this.getMultiOccurrenceElement();
                    const rechercheEl = multiOccurrenceEl.closest('.ex-multi-occurrence-container').find('ex-recherche,ex-input-recherche');

                    exFocus(rechercheEl);
                }, 300);
            }
        }

        hasFiltreOrCritere() {
            if (this.modeEditionRapide || this.getNomSourceDetails().startsWith('lov-')) {
                //En édition rapide ou dans un lov, on a pas besoin de filtres pour lancer la recherche.
                return true;
            } else {
                return Boolean(
                    this.hasCriteresSuggeresSaisis(this.etatSelected ? this.etatSelected.criteresSuggeresData : null) ||
                    this.hasFiltresSaisis() ||
                    this.stateParams.employe ||
                    (this.multiOccurrenceParent && this.srccod !== 'gs0044' && this.srccod !== 'gs0018')
                );
            }
        }

        hasCriteresSuggeresSaisis(criteres: { [key: string]: any }): boolean {
            if (criteres) {
                return this.getColonnesFormulaireCriteresSuggeres().some((col: string) => {
                    return criteres[col] != null;
                });
            } else {
                return false;
            }
        }

        private hasFiltresSaisis() {
            return this.etatSelected && this.etatSelected.filtres.some((filter: IFiltre) => {
                return filter != null;
            });
        }

        private getColonnesFormulaireCriteresSuggeres(liste: Array<FormulaireElement> = this.criteresSuggeres ? this.criteresSuggeres.liste : []) {
            const cols: Array<string> = [];
            liste.filter((formulaireElement: FormulaireElement) => formulaireElement instanceof FormulaireItem || formulaireElement instanceof FormulaireGroupe)
                .filter((formulaireItem: IFormulaireItem) => !formulaireItem.affichage)
                .forEach((formulaireItem: IFormulaireItem | IFormulaireGroupe) => {
                    if (formulaireItem instanceof FormulaireGroupe) {
                        cols.push(...this.getColonnesFormulaireCriteresSuggeres((<IFormulaireGroupe>formulaireItem).formulaire.liste));
                    } else {
                        cols.push((<IFormulaireItem>formulaireItem).col);
                    }
                });
            return cols;
        }

        isDataListEmpty() {
            return this.dataList && this.dataList.length === 0;
        }

        resetPagination() {
            this.etatSelected.pagination.total = 0;
        }

        fetchNombreResultatsTrouves() {
            const queryParams = {
                filter: this.getDataListFilterQueryParam(),
                ...this.etatSelected.criteresSuggeresData,
                ...this.getResourceParamsValues(),
                count: true,
                ...this.getParentParams(),
                ...this.stateParams,
                ...this.currentQueryParams,
                limit: undefined,
                id: undefined
            };

            this.fetchingNombreLignes = true;
            return this.DataResource.query(queryParams).$promise.then((response: any) => {
                this.compteurNombreLignesErreur = false;
                return response.data[0].TOTAL__COUNT;
            }).catch((err) => {
                this.compteurNombreLignesErreur = true;

                return $q.reject(err);
            }).finally(() => {
                this.fetchingNombreLignes = false;
            });
        }

        getSchemaItem(colonne: string) {
            return this.schema[colonne];
        }

        doubleClickRow(event: MouseEvent, data: any, stateParams: any) {
            event.preventDefault();
            localStorage.removeItem('forageFromMenu');

            const menuItemForage = this.actionsRangeeDroite.listeMenuItem.find((menuItem) => { return menuItem instanceof MenuItemForage }) as IMenuItemForage;
            const menuItemEcran = this.actionsRangeeDroite.listeMenuItem.find((menuItem) => { return menuItem instanceof MenuItemEcran }) as IMenuItemEcran;
            const menuItemFactory = this.actionsRangeeDroite.listeMenuItem.find((menuItem) => { return menuItem instanceof MenuItemFactory }) as IMenuItemFactoryFactory;

            if (this.navigatePage) {
                const params = this.navigateParams ? this.navigateParams(data, this) : {};

                const fonction = this.ecranDetails.fonctions['BOUOUV'] || this.ecranDetails.fonctions[`${this.mnemonique}.BOUOUV`];
                if (fonction && !fonction.flgacc) return;

                const pageName = this.navigatePage instanceof Function ? this.navigatePage(data) : this.navigatePage
                $state.go(pageName, Object.assign({
                    id: data[this.forageCleint],
                    menuId: stateParams.menuId
                }, params));
            } else if (menuItemForage && this.isMenuItemAccessible(menuItemForage, data)) {
                menuItemForage.action(data, stateParams, true);
            } else if (menuItemEcran && this.isMenuItemAccessible(menuItemEcran, data)) {
                menuItemEcran.action(event, data);
            } else if (menuItemFactory) {
                const menuItem = menuItemFactory.callback(data);
                if (menuItem instanceof MenuItemForage && this.isMenuItemAccessible(menuItem, data)) {
                    menuItem.action(data, stateParams, true);
                } else if (menuItem instanceof MenuItemEcran && this.isMenuItemAccessible(menuItem, data)) {
                    menuItem.action(event, data);
                }
            }
        }

        private isMenuItemAccessible(menuItem: IMenuItem, data: any) {
            return (menuItem.disabled instanceof Function ? !menuItem.disabled(data) : !menuItem.disabled && menuItem.hidden instanceof Function ? !menuItem.hidden(data) : !menuItem.hidden);
        }

        getEnteteLibelle(colonne: string, colonneRegroupement: Array<any>, showEntete: boolean, enteteReplacedValues: any = {}) {
            const colParams = this.colonnesParametres[colonne];
            const replacedValue = colParams
                ? this.getReplacedValue(colParams.replacedValue)
                : null;

            return exEnteteLibelleFilter(colonne, this.libelles, colonneRegroupement, showEntete, enteteReplacedValues, replacedValue);
        }

        rowHasError(data: any) {
            const errorsColumnsName = this.getErrorColumnsName();
            return errorsColumnsName.length ? errorsColumnsName.some(col => data[col]) : false;
        }

        getErrorColumnsName() {
            return Object.keys(this.dataTypes).filter((col: string) => (this.dataTypes[col] instanceof IndicateurErreurDataType && this.dataTypes[col].params.err) || this.dataTypes[col] instanceof StatutDataType)
        }

        getStateParams(currentParams: any) {
            if (this.stateParams.route.QUERY_PARAMS && this.fonctions.routeParamsCriteres) {
                this.stateParams.route.QUERY_PARAMS.forEach((param: string) => {
                    const nomParam = param.replace(/^\?/, '');
                    currentParams[nomParam] = currentParams[nomParam] || this.stateParams[nomParam];
                });
            }
        }

        creerBoutonDupliquer(getNewMono: () => IMonoOccurrence, openNew: () => void) {
            if (!this.fonctions.boutonDupliquer || !this.formulaire || this.actionsMoreLigne.listeMenuItem.some((m: IMenuItem) => m.fonction === "BOUCOP")) return
            this.actionsMoreLigne.add(new MenuItem("G_LBL_DUPLIQUER", (event: Event, data: any) => {
                const newMonoOccurrence = getNewMono()
                if (this.isView) {
                    newMonoOccurrence.id = data[this.cleint]
                    newMonoOccurrence.paramDefaults = { id: data[this.cleint] }
                    newMonoOccurrence.fetchData().then(() => {
                        newMonoOccurrence.id = undefined
                        newMonoOccurrence.paramDefaults = { id: undefined }
                        newMonoOccurrence.data[this.cleint] = undefined
                        newMonoOccurrence.data.usrcre = undefined
                        newMonoOccurrence.data.datcre = undefined
                        newMonoOccurrence.data.usrmod = undefined
                        newMonoOccurrence.data.datmod = undefined
                        openNew()
                    })
                } else {
                    newMonoOccurrence.data = { ...data, [this.cleint]: undefined, usrcre: undefined, usrmod: undefined, datmod: undefined, datcre: undefined }
                    newMonoOccurrence.emit('dataUpdate')
                    openNew()
                }
                //on va afficher le div edition en bas de l'enregistrement choisi a dupliquer 
                const divRow = ((newMonoOccurrence.bloc) ? newMonoOccurrence.bloc : newMonoOccurrence.mnemonique).concat("-", data[this.cleint])
                let parentDiv = document.getElementById(divRow);
                // on a reussie à trouver le div donc on l'ajoute, sinon le comportement sera l'ancien
                if (parentDiv) {
                    let childDiv = document.getElementById(((newMonoOccurrence.bloc) ? newMonoOccurrence.bloc : newMonoOccurrence.mnemonique).concat("Child"));
                    if (childDiv) {
                        parentDiv.appendChild(childDiv);
                    }
                }
            }, { hidden: (data: any) => !this.fonctions.nouveau || (this.fonctions.boutonDupliquer instanceof Function && !this.fonctions.boutonDupliquer(data)), icon: "content_copy" }))
        }

        hasItemsSelectionMultiple() {
            const liste = this?.etatSelected?.criteresMultiplesListe;
            let result = false;
            //on valide s'il y a données dans le local storage
            if (liste && Array.isArray(liste) && liste.length > 0) {
                liste.forEach((el) => {
                    let lov = ("lov" + el.col + this.srccod).trim()
                    const data = localStorage.getItem(lov);
                    if (data !== null) {
                        result = true;
                    }
                })
            }
            return result;
        }

        hasItemsCriteresMultiples() {
            const criteresMultiplesListe: any = []
            const data = this.etatSelected?.criteresMultiplesListe
            //on valide s'il y a des données dans la liste de selection multiple
            if (data && Array.isArray(data) && data.length > 0) {
                if (this.hasItemsSelectionMultiple()) {
                    data.forEach((el) => {
                        let lov = ("lov" + el.col + this.srccod).trim()
                        if (this.hasValuesItemSelectionMultiple(lov)) {
                            criteresMultiplesListe.push(el);
                        }
                    })
                }
            }
            //on ajoute ceux qui ont values
            return criteresMultiplesListe;
        }

        hasValuesItemSelectionMultiple(lov?: string) {
            if (lov) {
                const data = localStorage.getItem(lov);
                //on valide si chaque lov de selection multiple a de l'information
                if (data !== null) {
                    const values = JSON.parse(data)
                    if (values && Array.isArray(values) && values.length > 0) {
                        return true;
                    }
                }
            }
            return false;
        }

        getValuesItemSelectionMultiple(srccod: string) {
            let arraySelectionMultiple: any = []
            const liste = this?.etatSelected?.criteresMultiplesListe;
            //on valide s'il y a données dans le local storage
            if (liste && Array.isArray(liste) && liste.length > 0) {
                liste.forEach((el) => {
                    let lov = ("lov" + el.col + this.srccod).trim()
                    const data = localStorage.getItem(lov);
                    if (data !== null) {
                        const values = JSON.parse(data)
                        if (values && Array.isArray(values) && values.length > 0) {
                            arraySelectionMultiple.push = values;
                        }
                    }
                })
            }

            let ids = this.getLovsMultiple(srccod)
            return ids;
        }

        //sert à vider le local storage pour le bouton reinitialiser
        viderValuesSelectionMultiple(srccod?: string) {
            let idsLov = this.getLovsMultiple(srccod)

            this.etatSelected.criteresMultiplesListe = [];
            //s'il n'y a pas des critteres multiples on ne reinitialise pas
            if (idsLov == "") {
                return $q.resolve()
            } else {
                return this.reinitialiserSelectionMultiple(srccod, idsLov);
            }
        }

        deleteParams(key: string, reload: boolean = true) {
            try {
                const url = new URL(window.location.href);
                Object.keys(this?.stateParams).forEach((param) => {
                    //si le parametre existe on l'enleve du state params et de l'url
                    if (param.includes(key)) {
                        delete this.stateParams[param]
                        url.searchParams.delete(param);
                        this.flagValDefSelectionMultiple = false
                    }
                })
                if (reload) {
                    window.history.replaceState({}, '', url.toString());
                }
            } catch (error) {
                return $q.reject(error)
            }
            return $q.resolve()
        }

        getLovsMultiple(srccod?: string, askForlov: string = undefined) {
            let idsLov = ""
            if (this?.criteresSuggeres?.liste) {
                Object.values(this.criteresSuggeres.liste).forEach((el: any) => {
                    if (el?.lovSelectionMultiple) {
                        let dataType = el.dataType
                        //si le dataType est null on obtiens l'information de l'écranSourceDetails du monoOccurrence
                        if (!dataType && this?.monoOccurrenceParent?.ecranSourceDetails?.dataTypes) {
                            dataType = this.monoOccurrenceParent.ecranSourceDetails.dataTypes[el.col]
                        }
                        let lov = ("lov" + el.col + srccod).trim()
                        if (typeof (askForlov) === "undefined") {
                            localStorage.removeItem(lov)
                            //on liste les lov du formulaire afin d'envoyer juste une appel 
                            if (dataType) {
                                idsLov = idsLov.trim() + "," + dataType.params.source
                            }

                        } else if (askForlov == el.col) {
                            localStorage.removeItem(lov)
                            //on liste les lov du formulaire afin d'envoyer juste une appel 
                            if (dataType) {
                                idsLov = dataType.params.source
                            }
                        }

                    }
                })
            }
            return idsLov
        }
        reinitialiserSelectionMultiple(srccod: string, sources: any) {
            //on envoie l'execution du services pour vider les valeurs dans la bd
            return $http.post(`${ApiConfig.ROOT}/mcparm-multi-tmp/reinitialiser/`, {
                srccod: srccod,
                sources: sources,
                ...parametresSecurite(this.stateParams, this.ecranDetails)
            })
        }

        reinitialiserLovSelectionMultiple(srccod: string, source: any) {
            //on fait le call à la procedure pour effacer juste 1 lov 
            return $http.post(`${ApiConfig.ROOT}/mcparm-multi-tmp/reinitialiser-lov/`, {
                srccod: srccod,
                sources: source,
                ...parametresSecurite(this.stateParams, this.ecranDetails)
            })
        }


        updateCriteresSuggeresListe() {
            if (this?.etatSelected) {
                this.etatSelected.criteresMultiplesListe = [];
            }

            if (this?.criteresSuggeres?.liste) {
                //on ajoute les champs de selection multiple afin d'afficher la pastille non supprimable
                Object.values(this?.criteresSuggeres?.liste).forEach((el: any) => {
                    if (el?.lovSelectionMultiple) {
                        if (!this?.etatSelected?.criteresMultiplesListe) {
                            this.etatSelected.criteresMultiplesListe = [];
                        }
                        if (!this.etatSelected.criteresMultiplesListe.includes(el)) {
                            this.etatSelected.criteresMultiplesListe.push(el)
                        }
                    }
                })
            }
            return this.etatSelected
        }

        getPmtcleint(srccod: string, usrcleint: number): IPromise<number> {
            const requete = $resource(`${ApiConfig.ROOT}/mcparm-multi-tmp/getid?srccod=${srccod}&usrcleint=${usrcleint}`, {});

            return requete.get().$promise
                .then((result: any) => {
                    return (result.pmtcleint) ? result.pmtcleint : 0;
                }).catch(() => {
                    return 0;
                });
        }

        getCriteresMultiplesListe(): IPromise<any[]> {
            let result: any = this.updateCriteresSuggeresListe()
            return $q.resolve(result)
        }

        getLovsNameSelectionMultiple(srccod: any) {
            let result: any[] = []
            Object.values(this.etatSelected.criteresMultiplesListe).forEach((el: any) => {
                const tmpObj = {
                    lov: ("lov" + el.col + srccod).trim(),
                    col: el.col
                }
                result.push(tmpObj)
            })
            return result;
        }

        removeLovsFromLocalStorageSelectionMultiple(srccod: any) {
            if (srccod && typeof (srccod) === "string") {
                srccod = srccod.toLocaleLowerCase()
            }
            if (this?.etatSelected?.criteresMultiplesListe) {
                Object.values(this.etatSelected.criteresMultiplesListe).forEach((el: any) => {
                    const data = localStorage.getItem("lov" + el.col + srccod);
                    if (data !== null) {
                        localStorage.removeItem("lov" + el.col + srccod);
                    }
                })
            }
        }
        async isLovMultipleIntervalle(srccod: string, source: string, col: string): Promise<boolean> {
            const usrcleint = profil.preferences.usrcleint
            const requete = $resource(`${ApiConfig.ROOT}/mcparm-multi-tmp/islov-intervalle?col=${col}&source=${source}&srccod=${srccod}&usrcleint=${usrcleint}`, {});

            return requete.get().$promise
        }

        getDataColonneIntervalle(intervalle: any, col: string) {
            let result = ""
            Object.keys(intervalle).forEach((key) => {
                //on obtiens les description des lov intervalle afin d'afficher dans le grid
                if (key !== col) {
                    result = result + " - " + intervalle[key]
                }
            })
            const valideString = result.split('-')
            if (valideString.length === 1) {
                return result
            } else {
                return valideString.slice(1).join('-');
            }
        }

        setTabSelected(flag: boolean) {
            this.tabSelected = flag
        }

        async getValuesItemsSelectionMultiple(srccod: string): Promise<string[]> {
            const criteresMultiplesListe: string[] = [];

            try {
                await this.getCriteresMultiplesListe();
                const data = this.etatSelected?.criteresMultiplesListe;

                if (Array.isArray(data) && data.length > 0 && this.hasItemsSelectionMultiple()) {
                    data
                        .map((el) => ({
                            lov: `lov${el.col}${srccod}`.trim(),
                            element: el,
                        }))
                        .filter(({ lov }) => this.hasValuesItemSelectionMultiple(lov))
                        .forEach(({ element }) => criteresMultiplesListe.push(element));
                }
            } catch (error) { }

            return criteresMultiplesListe;
        }

        async valideChangeItemEditionRapide(): Promise<boolean> {
            this.removeDuplicatesChageItemArray()
            const result: boolean = (this.champsChangeItemEditionRapide.length > 0);
            return $q.resolve(result)
        }

        async addChampChangeItemEditionRapide(col: string) {
            this.champsChangeItemEditionRapide = this.addValueToArray(col)
        }

        addValueToArray(value: string) {
            let filter = this.champsChangeItemEditionRapide.filter(item => item !== value);
            filter = [...new Set(filter)];
            filter.push(value)
            return filter
        }

        removeDuplicatesChageItemArray() {
            this.champsChangeItemEditionRapide = [...new Set(this.champsChangeItemEditionRapide)];
        }

        async removeChampChangeItemEditionRapide(col: string) {
            this.removeDuplicatesChageItemArray()
            const index = this.champsChangeItemEditionRapide.indexOf(col);
            if (index !== -1) {
                this.champsChangeItemEditionRapide.splice(index, 1);
            }
        }
    }
}
