import {
    element,
    IAugmentedJQuery,
    IComponentController,
    IFormController,
    IPromise,
    IScope,
    ITimeoutService
} from 'angular';
import { IBlocBehavior } from '../../behaviors/ex-bloc/ex-bloc.controller';
import { IFocusService } from '../../behaviors/ex-focus/ex-focus.behavior';
import { IApiError } from '../../interfaces/api-error.interface';
import { IDialog } from '../../services/dialog.service';
import { IFormulaireItem } from '../../services/formulaire/formulaire-item.service';
import { IMenuItem, IMenuItemClass } from '../../services/menu/menu-item.service';
import { IMenuMoreClass, IMenuMoreTypes, IMenuMoreTypesOptions } from '../../services/menu/menu-more.service';
import { IMenuClass } from '../../services/menu/menu.service';
import { IMonoOccurrence, IMonoOccurrenceClass } from '../../services/mono-occurrence.service';
import {
    IMultiOccurrence,
    IMultiOccurrenceClass,
    IMultiOccurrenceOptions
} from '../../services/multi-occurrence.service';
import { IDefaultsService } from '../../services/utils/defaults.service';
import { INotificationHandler } from '../../services/utils/notification-handler.service';
import { IDataSourceController } from '../ex-data-source/ex-data-source.component';
import { IComposantMonoOccurrenceEcran } from '../ex-mono-occurrence-ecran/ex-mono-occurrence-ecran.controller';
import { IComposantMonoOccurrence } from '../ex-mono-occurrence/ex-mono-occurrence.controller';
import { IComposantMultiOccurrence } from '../ex-multi-occurrence/ex-multi-occurrence.controller';
import { ITemplateController } from '../../services/template.service';
import { IEcranContextController } from '../../behaviors/ex-ecran-context/ex-ecran-context.behavior';
import { IDialogAllStatus } from '../../constants/dialog.constant';
import IStateService = angular.ui.IStateService;
import IDialogOptions = angular.material.IDialogOptions;
import { IMessagesErreurs } from '../../../apps/sofe/components/ex-multi-occurrence-analyse/ex-multi-occurrence-analyse.controller';
import { IDataLinker } from '../../services/data-linker.service';
import { IKeyCodes } from '../../constants/key-codes.constant';
import { IFilterLibelle } from '../../filters/ex-libelle.filter';

export interface IComposantMultiOccurrenceAdvanced extends IComponentController {
    ecranContextCtrl: IEcranContextController;
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrence: IMultiOccurrence;
    newMonoOccurrence: IMonoOccurrence;
    monoOccurrenceCtrl: IComposantMonoOccurrence;
    monoOccurrenceEcranCtrl: IComposantMonoOccurrenceEcran;
    multiOccurrenceCtrl: IComposantMultiOccurrence;
    templateCtrl: ITemplateController;
    dataSourceCtrl: IDataSourceController;
    blocCtrl: IBlocBehavior;
    containerFab: IAugmentedJQuery;
    lblTitreEdition: string;
    loading: boolean;
    activeCleint: number;
    codeEcran: string;
    newOpened: boolean;
    newFormCtrl: IFormController;
    selectionActionsCycle: boolean;
    messagesErreur: IMessagesErreurs;
    btnActionFermer: IMenuItem;
    triggeredUpdate: boolean;

    customSaveAction(savedData: any): IPromise<any>;
    customDeleteAction(idLigne: string | number): IPromise<any>;
    clickRow(event: JQueryEventObject, id: number): void;
    clickRowAction(obj: any): void;
    doubleClickRow(event: MouseEvent, data: any): void;
    openNew(event?: MouseEvent): void;
    closeNew(): void;
    closeRow(data: any): void;
    saveRowAction(monoOccurrence: IMonoOccurrence, rowData: any, savedData: any): IPromise<any>;
    saveNewAction(monoOccurrence: IMonoOccurrence, event: MouseEvent | KeyboardEvent): IPromise<any>;
    saveNewAndNextAction(monoOccurrence: IMonoOccurrence, data: any): IPromise<any>;
    retryMonoOccurrenceErreur(monoOccurrence: IMonoOccurrence): void;
    retryValeursDefautErreur(monoOccurrence: IMonoOccurrence): void;
    displayNewFormulaire(): boolean;
    hasTranscludeDetails(): boolean;
    onTabOut(): void;
    shouldDisableSave(data: any): boolean;
    keydownEditionRapide(event: KeyboardEvent): void;
    hasBoutonFermer(data: any): boolean;
    keydownEdition(event: KeyboardEvent, monoOccurrence: IMonoOccurrence): void
}

/* @ngInject */
export default function MultiOccurrenceAdvancedController(MenuItem: IMenuItemClass,
    Menu: IMenuClass,
    MenuMore: IMenuMoreClass,
    dataLinker: IDataLinker,
    defaults: IDefaultsService,
    notificationHandler: INotificationHandler,
    $scope: IScope,
    $state: IStateService,
    $timeout: ITimeoutService,
    MultiOccurrence: IMultiOccurrenceClass,
    DialogStatus: IDialogAllStatus,
    DialogConfirm: IDialog,
    DialogSuiviModification: IDialog,
    exFocus: IFocusService,
    $element: IAugmentedJQuery,
    MonoOccurrence: IMonoOccurrenceClass,
    keyCodes: IKeyCodes,
    exLibelleFilter: IFilterLibelle) {
    const vm: IComposantMultiOccurrenceAdvanced = this;

    const newAction = new MenuItem('G_LBL_BTN_NOUVEAU', openNew, {
        disabled() {
            return vm.newOpened && !vm.multiOccurrence.editionRapideActive;
        }
    });


    const DEFAULT_LINKED_DATA = {};

    vm.$onInit = $onInit;
    vm.clickRow = clickRow;
    vm.doubleClickRow = doubleClickRow;
    vm.closeNew = closeNew;
    vm.closeRow = closeRow;
    vm.saveRowAction = saveRowAction;
    vm.saveNewAction = saveNewAction;
    vm.saveNewAndNextAction = saveNewAndNextAction;
    vm.retryMonoOccurrenceErreur = retryMonoOccurrenceErreur;
    vm.retryValeursDefautErreur = retryValeursDefautErreur;
    vm.displayNewFormulaire = displayNewFormulaire;
    vm.hasTranscludeDetails = hasTranscludeDetails;
    vm.onTabOut = onTabOut;
    vm.shouldDisableSave = shouldDisableSave;
    vm.openNew = openNew;
    vm.keydownEditionRapide = keydownEditionRapide;
    vm.hasBoutonFermer = hasBoutonFermer;

    function $onInit() {
        defaults(vm, {
            newOpened: false
        });

        dataLinker.link($element, DEFAULT_LINKED_DATA, vm.ecranContextCtrl.stateParams, vm.ecranContextCtrl.ecranDetails);

        // Couvre le cas d'un multi-occurrence imbriqué dans un autre multi-occurrence qui n'est pas d'édition
        if (vm.multiOccurrenceCtrl && vm.blocCtrl) {
            const multiOccurrenceParent = (vm.multiOccurrenceCtrl && vm.multiOccurrenceCtrl.multiOccurrence);
            vm.multiOccurrenceOptions.multiOccurrenceParent = multiOccurrenceParent;
            vm.multiOccurrenceOptions.srccod = vm.multiOccurrenceCtrl.multiOccurrence.srccod;
            vm.multiOccurrenceOptions.bloc = vm.blocCtrl.bloc;

            const cleint = vm.multiOccurrenceCtrl.multiOccurrence.cleint;
            if (vm.dataSourceCtrl) {
                vm.multiOccurrenceOptions.cycleId = vm.dataSourceCtrl.data[vm.multiOccurrenceCtrl.multiOccurrence.cycleCleint] || vm.multiOccurrenceCtrl.multiOccurrence.cycleId;

                // On filtre par l'item courant du multi-occurrence parent
                vm.multiOccurrenceOptions.resourceParams = {
                    ...vm.multiOccurrenceOptions.resourceParams,
                    [cleint]: vm.dataSourceCtrl.data[cleint]
                };
            }

            iniMultiOccurrence();
        }
        // Voici le cas ou le multi-occurrence est à l'intérieur d'un mono-occurrence.
        else if (vm.monoOccurrenceCtrl && vm.blocCtrl) {
            vm.multiOccurrenceOptions.srccod = vm.monoOccurrenceCtrl.monoOccurrence.srccod;
            vm.multiOccurrenceOptions.bloc = vm.blocCtrl.bloc;

            vm.monoOccurrenceCtrl.monoOccurrence.ready
                .then(() => vm.monoOccurrenceCtrl.monoOccurrence.data.$promise)
                .then(() => {
                    // Il faut attendre que les données soit chargées. Le id à utilisé est possiblement différent de celui
                    // qui est utilisé pour le forage
                    const cleint = vm.monoOccurrenceCtrl.monoOccurrence.cleint;
                    vm.multiOccurrenceOptions.parentId = vm.monoOccurrenceCtrl.monoOccurrence.data[cleint];
                    vm.multiOccurrenceOptions.monoOccurrenceParent = vm.monoOccurrenceCtrl.monoOccurrence;
                    const cycleCleint = vm.monoOccurrenceCtrl.monoOccurrence.cycleCleint;
                    vm.multiOccurrenceOptions.cycleId = vm.monoOccurrenceCtrl.monoOccurrence.data[cycleCleint];

                    // On charge le multi seulement en mode édition
                    if (vm.multiOccurrenceOptions.parentId) {
                        iniMultiOccurrence();
                    }
                });
        } else {
            iniMultiOccurrence();
        }

        vm.btnActionFermer = new MenuItem('G_LBL_BTN_FERMER', (ev: MouseEvent, data: any) => vm.closeRow(data), {
            class: 'md-raised ex-button ex-button-secondary',
            iconButton: false
        });
    }

    function iniMultiOccurrence(editionRapideActive = vm.multiOccurrenceOptions.editionRapideActive) {
        if (editionRapideActive) {
            initMultiOccurrenceEditionRapide()
            return
        }

        const menuMoreTypes: IMenuMoreTypes = {};

        vm.multiOccurrenceOptions.fonctions = { suiviModification: true, ...vm.multiOccurrenceOptions.fonctions };

        const actionsDroite = [];
        if (vm.multiOccurrenceOptions.actionsRangeeDroite) {
            actionsDroite.push(...vm.multiOccurrenceOptions.actionsRangeeDroite.listeMenuItem);
        }

        if (vm.multiOccurrenceOptions.actionsSelection) {
            vm.multiOccurrenceOptions.rangeesSelectionnables = true;
        }

        vm.multiOccurrenceOptions.colonnesCachees = vm.multiOccurrenceOptions.colonnesCachees || [];
        const multiOccurrenceParent = vm.multiOccurrenceOptions.multiOccurrenceParent || vm.multiOccurrenceCtrl && vm.multiOccurrenceCtrl.multiOccurrence;

        vm.multiOccurrence = new MultiOccurrence({
            ...vm.multiOccurrenceOptions,
            editionRapideActive: false,
            hasGridElement: true,
            stateParams: vm.ecranContextCtrl.stateParams,
            ecranDetails: vm.ecranContextCtrl.ecranDetails,
            ecranSourceDetails: vm.ecranContextCtrl.ecranSourceDetails,
            multiOccurrenceParent,
            actionsNouveaux: vm.multiOccurrenceOptions.actionsNouveaux || new Menu([newAction]),
            actionsRangeeDroite: new Menu(actionsDroite),
            // Les usagers de suivi de modification ne sont pas inclus lorsqu'on connecte sur une table, contrairement à une vue
            colonnesCachees: [
                'usrcre',
                'usrmod'
            ].concat(vm.multiOccurrenceOptions.colonnesCachees)
        });

        if (vm.multiOccurrenceOptions.fonctions.editionRapide) {
            vm.multiOccurrence.on('editionRapideActiveUpdate', initMultiOccurrenceEditionRapide);
        }

        vm.multiOccurrence.once('ready', () => {
            if (vm.monoOccurrenceEcranCtrl && vm.multiOccurrence.bloc) {
                vm.monoOccurrenceEcranCtrl.registerMultiOccurrenceEnfant(vm.multiOccurrence);
            }

            vm.selectionActionsCycle = hasActionsCycle();
            $timeout(() => {
                vm.containerFab = $element.find('ex-multi-occurrence:first');
            });

            if (vm.ecranContextCtrl.stateParams.error) {
                const erreurData = vm.ecranContextCtrl.stateParams.error;

                if (erreurData.bloc === vm.multiOccurrence.bloc.toUpperCase()) {
                    const confirmAction = () => saveNew(vm.newMonoOccurrence);
                    afficherConfirmerSauvegarde(confirmAction, erreurData.error);
                    $scope.$watch('::vm.newMonoOccurrence', () => {
                        //Il faut attendre que le monoOCcurrence d'édition soit initialisé.
                        if (vm.newMonoOccurrence) {
                            openNew();
                        }
                    });
                }
            }

            if (vm.selectionActionsCycle) {
                vm.multiOccurrence.rangeesSelectionnables = true;
                vm.multiOccurrence.fonctions.selectionnerToutesLesRangees = true;
            }

            const menuMoreTypesOptions: IMenuMoreTypesOptions = {};
            if (vm.multiOccurrence.fonctions.edition && vm.multiOccurrence.fonctions.editionStandard) {
                menuMoreTypes.edition = (event: JQueryEventObject, rowDetails: any) => {
                    if (vm.multiOccurrence.hasEdition(rowDetails)) {
                        clickRow(event, rowDetails);
                        toggleRow(rowDetails);
                    }
                };

                menuMoreTypesOptions.edition = {
                    disabled: (data: any) => data.$opened
                };

                if (vm.multiOccurrence.fonctions.edition instanceof Function) {
                    menuMoreTypesOptions.edition.visible = vm.multiOccurrence.fonctions.edition;
                }
            }

            if (vm.multiOccurrence.fonctions.nouveau instanceof Function) {
                const baseDisabled = newAction.disabled
                newAction.disabled = () => {
                    const disabled = baseDisabled instanceof Function ? baseDisabled() : baseDisabled

                    const data = (vm.dataSourceCtrl) ?
                        vm.dataSourceCtrl.data :
                        (vm.monoOccurrenceCtrl) ? vm.monoOccurrenceCtrl.monoOccurrence.data : {};

                    return disabled || !(vm.multiOccurrence.fonctions.nouveau as Function)(data)
                }
            }

            menuMoreTypes.dossierEmploye = vm.multiOccurrence.hasAccesMenuEmploye;

            if (vm.multiOccurrence.fonctions.suiviModification && vm.multiOccurrence.hasChampSuiviModification()) {
                menuMoreTypes.suiviModification = (event: any, rowDetails: any) => {
                    afficherSuiviModification(event, rowDetails);
                };
            }

            if (vm.multiOccurrence.fonctions.supprime && vm.multiOccurrence.fonctions.editionStandard) {
                menuMoreTypes.supprime = (event: MouseEvent, rowDetails: any) => {
                    if (vm.multiOccurrence.hasSuppression(rowDetails)) {
                        afficherConfirmerSuppression(event, () => {
                            return deleteRow(rowDetails);
                        });
                    }
                };

                if (vm.multiOccurrence.fonctions.supprime instanceof Function) {
                    menuMoreTypesOptions.supprime = {
                        visible: vm.multiOccurrence.fonctions.supprime
                    };
                }
            }

            if (vm.multiOccurrence.fonctions.supprime ||
                vm.multiOccurrence.fonctions.edition ||
                (vm.multiOccurrence.fonctions.suiviModification && vm.multiOccurrence.hasChampSuiviModification()) ||
                (vm.multiOccurrence.actionsMoreLigne && vm.multiOccurrence.actionsMoreLigne.listeMenuItem.length)) {
                vm.multiOccurrence.creerBoutonDupliquer(() => vm.newMonoOccurrence, () => vm.newOpened = true)
                const menuMore = new MenuMore(menuMoreTypes, vm.multiOccurrence.actionsMoreLigne, menuMoreTypesOptions);

                actionsDroite.push(menuMore);
            }

            if (vm.multiOccurrenceOptions.navigatePage) {
                actionsDroite.push(new MenuItem('G_LBL_BTN_OUVRIR', getPageLink, {
                    icon: 'exit_to_app',
                    link: true,
                    fonction: `${vm.multiOccurrence.mnemonique}.BOUOUV`,
                    directionTooltip: 'bottom',
                    hidden: (data: any) => !getPageLink(data)
                }));
            }
        });
    }

    function initMultiOccurrenceEditionRapide() {
        if (vm.multiOccurrence && !vm.multiOccurrence.editionRapideActive) {
            iniMultiOccurrence(false)
            return
        }

        vm.multiOccurrence = new MultiOccurrence({
            ...vm.multiOccurrenceOptions,
            filtres: undefined,
            editionRapideActive: true,
            hasGridElement: true,
            modeEditionRapide: true,
            colonnesVisibles: vm.multiOccurrenceOptions.colonnesEditionRapide,
            stateParams: vm.ecranContextCtrl.stateParams,
            ecranDetails: vm.ecranContextCtrl.ecranDetails,
            ecranSourceDetails: vm.ecranContextCtrl.ecranSourceDetails,
            multiOccurrenceParent: vm.multiOccurrenceOptions.multiOccurrenceParent || vm.multiOccurrenceCtrl && vm.multiOccurrenceCtrl.multiOccurrence,
            actionsNouveaux: new Menu([newAction]),
            autoFetch: true,
            raccourcisRangee: {
                'CTRL+DELETE': (event, data) => {
                    if (data.$deleted) {
                        data.$deleted = false;
                    } else {
                        supprimerRangeeEditionRapide(data);
                    }
                }
            },
            actionsRangeeDroite: new Menu([
                new MenuItem('G_LBL_BTN_SUPPRIMER', (event: MouseEvent, data: any) => {
                    if (data.$deleted) {
                        data.$deleted = false;
                    } else {
                        supprimerRangeeEditionRapide(data);
                    }
                }, {
                    iconButton: true,
                    tabindex: -1,
                    icon: (data: any) => {
                        if (!data[vm.multiOccurrence.cleint]) {
                            return 'clear';
                        } else if (!data.$deleted) {
                            return 'delete';
                        } else {
                            return 'undo';
                        }
                    },
                    hidden: (data: any) => data[vm.multiOccurrence.cleint] && !vm.multiOccurrence.hasSuppression(data)
                })
            ])
        });

        vm.multiOccurrence.on('editionRapideActiveUpdate', initMultiOccurrenceEditionRapide);
        vm.multiOccurrence.once('ready', () => {
            if (vm.monoOccurrenceEcranCtrl && vm.multiOccurrence.bloc) {
                vm.monoOccurrenceEcranCtrl.registerMultiOccurrenceEnfant(vm.multiOccurrence);
            }

            if (!vm.multiOccurrence.hasChampsModifiables(true, {})) {
                // Si on a pas d'édition, on ne peut pas lancer l'éditionRapide.
                vm.multiOccurrence.toggleEditionRapide(false);
            }
        })

        // Si aucune données n'est présente au délenchement de l'édition rapide, on crée une nouvelle ligne
        vm.multiOccurrence.on('dataListUpdate', () => {
            vm.triggeredUpdate = true
            if (!vm.multiOccurrence.dataListReady) {
                vm.multiOccurrence.emit("dataListUpdate")
            }

            if (!vm.multiOccurrence.dataList.length && hasAccesNouveau(vm.multiOccurrence, DEFAULT_LINKED_DATA)) {
                vm.multiOccurrence.pushRangeeEditionRapide();
            }
        });
        vm.multiOccurrence.startWatching($scope);
    }

    function supprimerRangeeEditionRapide(data: any) {
        if (!data[vm.multiOccurrence.cleint]) {
            vm.multiOccurrence.deleteRangeeEditionRapide(data.$$index);
        } else {
            data.$deleted = true;

            if (data === vm.multiOccurrence.rangeeEditionRapide) {
                vm.multiOccurrence.rangeeEditionRapide = null;
                vm.multiOccurrence.indexEditionRapide = null;
            }
        }
    }

    function clickRow(event: JQueryEventObject, rowDetails: any) {
        if (((vm.multiOccurrence.hasEdition(rowDetails) && vm.multiOccurrence.fonctions.editionStandard) || vm.multiOccurrence.details || hasTranscludeDetails() || vm.multiOccurrence.rowHasError(rowDetails)) && !(event.originalEvent as any).sofeMultiOccurrenceAdvancedRowClicked) {
            const cleint = vm.multiOccurrence.cleint;
            const value = rowDetails[cleint];
            vm.multiOccurrence.activeRowCleint = value;

            if (vm.multiOccurrence.details) {
                vm.multiOccurrence.details.multiOccurrenceOptions.resourceParams = { ...vm.multiOccurrence.details.multiOccurrenceOptions.resourceParams, [cleint]: value };

                if (vm.multiOccurrence.details.multiOccurrenceOptions.formulaire) {
                    vm.multiOccurrence.details.multiOccurrenceOptions.formulaire.liste.forEach((formulaireItem: IFormulaireItem) => {
                        formulaireItem.resourceParams = formulaireItem.resourceParams || {};
                        formulaireItem.resourceParams[cleint] = value;
                    });
                }
            }

            const target = element(event.target);
            // On ne ferme pas si le clique est sur une action de la ligne.
            if (target.closest('html').length &&
                target.closest('.ex-grid-row-content').length &&
                // On en ferme pas les parents quand on clique dans un MultiOccurrence imbriqué
                target.closest('.ex-grid-body').is($element.find('.ex-grid-body:first')) &&
                !target.closest('.ex-grid-col-action-container').length &&
                !target.closest('.md-button').length
            ) {
                toggleRow(rowDetails);
                (<any>event.originalEvent).sofeMultiOccurrenceAdvancedRowClicked = true;
            }
        }

        if (vm.clickRowAction instanceof Function) {
            vm.clickRowAction({ $data: rowDetails });
        }
    }

    function getPageLink(data: any) {
        const params = vm.multiOccurrence.navigateParams ? vm.multiOccurrence.navigateParams(data, vm.multiOccurrence) : {};

        const pageName = vm.multiOccurrence.navigatePage instanceof Function ? vm.multiOccurrence.navigatePage(data) : vm.multiOccurrence.navigatePage;

        if (!pageName) return;

        return $state.href(pageName, {
            id: data[vm.multiOccurrence.forageCleint],
            menuId: vm.ecranContextCtrl.stateParams.menuId,
            ...params
        });
    }

    function openNew(event?: Event) {
        if (vm.multiOccurrence instanceof MultiOccurrence && vm.multiOccurrence.fonctions.editionRapide && vm.multiOccurrence.editionRapideActive) {
            vm.multiOccurrence.unshiftRangeeEditionRapide();

            (event as any).originalEvent.exGridRowClick = true;

            setTimeout(() => {
                exFocus($element.find('.ex-grid-row:first'));
            });
        } else {
            vm.newMonoOccurrence.resetData();
            vm.newOpened = true;
        }
    }

    function onTabOut() {
        if (hasAccesNouveau(vm.multiOccurrence, DEFAULT_LINKED_DATA)) {
            vm.multiOccurrence.pushRangeeEditionRapide();

            setTimeout(() => {
                exFocus($element.find('.ex-grid-row:last'));
            });
        }
    }

    function hasAccesNouveau(multiOccurrence: IMultiOccurrence, data: any) {
        if (multiOccurrence.fonctions.nouveau instanceof Function) {
            return multiOccurrence.fonctions.nouveau(data);
        } else {
            return multiOccurrence.fonctions.nouveau;
        }
    }

    function closeNew() {
        vm.newOpened = false;
    }

    function closeRow(data: any) {
        data.$opened = false;
    }

    function afficherConfirmerSauvegarde(action: () => {}, error: IApiError) {
        notificationHandler.erreur({
            error,
            lblTitre: vm.lblTitreEdition,
            lblConfirm: 'G_LBL_BTN_APPLIQUER',
            lblMessage: 'G_MSG_SAUV_ENR',
            confirmAction() {
                return action();
            }
        });
    }

    function saveRowAction(monoOccurrence: IMonoOccurrence, rowData: any, savedData: any) {
        return saveRow(monoOccurrence, rowData, savedData).catch((error: number | IApiError) => {
            if (error !== DialogStatus.FERMER) {
                const confirmAction = () => saveRow(monoOccurrence, rowData);
                afficherConfirmerSauvegarde(confirmAction, <IApiError>error);
            }
        });
    }

    function saveRow(monoOccurrence: IMonoOccurrence, rowData?: any, savedData?: any) {
        if (vm.customSaveAction) {
            return vm.customSaveAction(savedData).then(() => {
                notificationHandler.succes();

                if (rowData) {
                    closeRow(rowData);
                }
            });
        } else {
            return monoOccurrence.save(savedData).then(() => {
                // La liste utilise un service différent, alors il faut toujours rafraichir meme lors des updates
                vm.multiOccurrence.fetchDataList();
                //s'il s'agit de l'action duppliquer on remets le div dans son parent original 
                $scope.$broadcast('multi-occurrence.remettre.div.edition', { bloc: (vm.multiOccurrence.bloc ? vm.multiOccurrence.bloc : vm.multiOccurrence.mnemonique) })
                notificationHandler.succes();

                if (rowData) {
                    closeRow(rowData);
                }
            });
        }
    }

    function saveNewAction(monoOccurrence: IMonoOccurrence, event: MouseEvent | KeyboardEvent) {
        return saveNew(monoOccurrence).catch((error: number | IApiError) => {
            if (error !== DialogStatus.FERMER) {
                const confirmAction = () => saveNew(monoOccurrence);
                afficherConfirmerSauvegarde(confirmAction, <IApiError>error);
            }
        });
    }

    function saveNew(monoOccurrence: IMonoOccurrence) {
        return saveRow(monoOccurrence, null, vm.newMonoOccurrence.data).then(() => {
            vm.closeNew();
        });
    }

    function saveNewAndNextAction(monoOccurrence: IMonoOccurrence, savedData: any) {
        return saveNewAndNext(monoOccurrence, savedData).catch((error: number | IApiError) => {
            if (error !== DialogStatus.FERMER) {
                const confirmAction = () => saveNewAndNext(monoOccurrence, savedData);
                afficherConfirmerSauvegarde(confirmAction, <IApiError>error);
            }
        });
    }

    function saveNewAndNext(monoOccurrence: IMonoOccurrence, savedData: any) {
        return saveRow(monoOccurrence, null, savedData).then(() => {
            monoOccurrence.resetData();
            //s'il s'agit de l'action duppliquer on remets le div dans son parent original 
            $scope.$broadcast('multi-occurrence.remettre.div.edition', { bloc: (vm.multiOccurrence.bloc ? vm.multiOccurrence.bloc : vm.multiOccurrence.mnemonique) })
            vm.newFormCtrl.$setPristine();
            vm.newFormCtrl.$setUntouched();
            exFocus($element.find('ex-edition'));
        });
    }

    function toggleRow(rowDetails: any): void {
        initMsgErr(rowDetails);
        rowDetails.$opened = !rowDetails.$opened;
    }

    function afficherConfirmerSuppression(event: MouseEvent, confirmAction: () => any) {
        const dialogOptions: IDialogOptions = {
            targetEvent: event,
            locals: {
                lblTitre: 'G_LBL_BTN_SUPP_ENR',
                lblDescription: 'G_LBL_SUPP_ENR',
                lblConfirm: 'G_LBL_BTN_APPLIQUER',
                lblMessage: 'G_MSG_SUPP_ENR',
                icon: 'delete',
                ecranContext: vm.ecranContextCtrl,
                confirmAction
            }
        };

        DialogConfirm.show(dialogOptions);
    }

    function deleteRow(rowDetails: any) {
        if (vm.customDeleteAction) {
            //si on ne trouve pas la valeur par forageCleint on essaye avec le parametre dynamique parametre_valeur/intervalle en utilisant la propriete $$hashKey de l'object gs0090
            const idligne = (rowDetails?.parametre_valeur || rowDetails?.intervalle_min) ? rowDetails.$$hashKey : rowDetails[vm.multiOccurrence.forageCleint]
            return vm.customDeleteAction(idligne).then(() => notificationHandler.succes('G_MSG_SUPP_ENR_SUCCES'));
        } else {
            const monoOccurrenceSupression = new MonoOccurrence({
                stateParams: vm.ecranContextCtrl.stateParams,
                ecranDetails: vm.ecranContextCtrl.ecranDetails,
                ecranSourceDetails: vm.ecranContextCtrl.ecranSourceDetails,
                multiOccurrenceParent: vm.multiOccurrence,
                resourceParams: vm.multiOccurrenceOptions.resourceParams,
                srccod: vm.multiOccurrence.srccod,
                isEdition: true,
                autoFetch: false
            });
            return monoOccurrenceSupression.delete(rowDetails[vm.multiOccurrence.forageCleint]).then(() => {
                notificationHandler.succes('G_MSG_SUPP_ENR_SUCCES');
                vm.multiOccurrence.fetchDataList();
                vm.multiOccurrence.onDelete && vm.multiOccurrence.onDelete()
            });
        }
    }

    function afficherSuiviModification(ev: any, rowDetails: any) {
        DialogSuiviModification.show({
            targetEvent: ev,
            locals: {
                data: rowDetails,
                ecranContext: vm.ecranContextCtrl
            }
        });
    }

    function retryMonoOccurrenceErreur(monoOccurrence: IMonoOccurrence) {
        if (monoOccurrence.initError) {
            monoOccurrence.init();
        } else {
            monoOccurrence.fetchData();
        }
    }

    function retryValeursDefautErreur(monoOccurrence: IMonoOccurrence) {
        if (monoOccurrence.initError) {
            monoOccurrence.init();
        } else {
            monoOccurrence.fetchValeursDefaut();
        }
    }

    function displayNewFormulaire() {
        return Boolean(
            vm.newOpened &&
            vm.multiOccurrence.initialized &&
            vm.newMonoOccurrence.initialized &&
            !vm.newMonoOccurrence.fetchingValeursDefaut &&
            !vm.newMonoOccurrence.valeursDefautError
        );
    }

    function hasActionsCycle() {
        const nomFonction = (vm.multiOccurrence.bloc || vm.multiOccurrence.mnemonique).toUpperCase();
        const fonction = vm.ecranContextCtrl.ecranDetails.fonctions[`${nomFonction}.SELCYC`];

        return Boolean(fonction && fonction.flgacc);
    }

    function hasTranscludeDetails() {
        const bloc = (vm.multiOccurrence.bloc || '$NONE$').toUpperCase();
        return Boolean(vm.templateCtrl && vm.templateCtrl.templates[bloc] && vm.templateCtrl.templates[bloc].details)
    }

    function initMsgErr(data: any) {
        if (!vm.messagesErreur || !vm.messagesErreur[data[vm.multiOccurrence.cleint]]) {
            vm.messagesErreur = vm.messagesErreur || {};

            const messagesErreurCols = vm.multiOccurrence.getErrorColumnsName();

            const lstMessages = messagesErreurCols.map((ele: any) => {
                if (vm.multiOccurrence.dataTypes[ele].params.err) {
                    return data[vm.multiOccurrence.dataTypes[ele].params.err];
                }
            });

            Object.assign(vm.messagesErreur, lstMessages.filter((ele) => ele).reduce((messagesErreurs: IMessagesErreurs, message: string) => {
                if (!messagesErreurs[data.$id]) {
                    messagesErreurs[data.$id] = [message];
                } else if (!messagesErreurs[data.$id].indexOf(message)) {
                    messagesErreurs[data.$id].push(message);
                }

                return messagesErreurs;
            }, {}));
        }
    }

    function shouldDisableSave(data: any) {
        return (
            !vm.multiOccurrence.hasChampsModifiables(true, data) ||
            (vm.multiOccurrence.disableSave && vm.multiOccurrence.disableSave(data))
        );
    }

    function doubleClickRow(event: MouseEvent, data: any) {
        vm.multiOccurrence.doubleClickRow(event, data, vm.ecranContextCtrl.stateParams);
    }

    async function keydownEditionRapide(event: KeyboardEvent) {
        if (vm.multiOccurrence.editionRapideActive && event.ctrlKey && event.keyCode === keyCodes.S) {
            event.preventDefault();
            callSaveEditionRapide()
        }
    }
    //delay, rester en attente du processus prb_change_item
    const delay = (ms: number) => new Promise(res => setTimeout(res, ms));
    const executeWait = async (miliseconds: number) => {
        await delay(miliseconds);
    }

    async function wait(miliseconds: number) {
        //mettre en attente ChangeItemResource.save doit finaliser
        await executeWait(miliseconds).then(() => { });
    }

    async function callSaveEditionRapide() {
        await wait(1050);
        const validation = await vm.multiOccurrence.valideChangeItemEditionRapide().then();
        if (validation) {
            callSaveEditionRapide()
        } else {
            saveEditionRapide();
        }
    }

    vm.keydownEdition = function keydownEdition(event: KeyboardEvent, monoOccurrence: IMonoOccurrence) {
        if (event.shiftKey && event.key === "F6" && vm.multiOccurrence.initialized && hasAccesNouveau(vm.multiOccurrence, DEFAULT_LINKED_DATA) && vm.multiOccurrence.isEveryChampsRequisModifiables(false) && vm.multiOccurrence.fonctions.editionStandard && !vm.multiOccurrence.editionRapideActive) {
            event.preventDefault()
            vm.newMonoOccurrence.data = { ...monoOccurrence.data, [monoOccurrence.cleint]: undefined, usrcre: undefined, usrmod: undefined, datmod: undefined, datcre: undefined }
            vm.newMonoOccurrence.id = undefined
            vm.newMonoOccurrence.emit("dataUpdate")
            vm.newOpened = true
        }
    }

    function saveEditionRapide() {
        if (vm.isSaving) return;

        if (vm.multiOccurrence.dataList.every(data => data.$editionRapideCtrl.$valid)) {
            vm.isSaving = true;

            return vm.multiOccurrence.saveAll().then(() => {
                notificationHandler.succes();

                //On notifie sur le multiOccurrence principal que la liste a été mise à jour.
                vm.multiOccurrence.emit('dataListUpdate');

                return vm.multiOccurrence.fetchValeursDefaut();
            }).catch((error: IApiError) => {
                let showConfirmAction;

                if (error.data && error.data.details && typeof error.data.details.row !== 'undefined') {
                    const enregistrementEnErreur = vm.multiOccurrence.dataList[error.data.details.row];

                    if (enregistrementEnErreur) {
                        enregistrementEnErreur.$messagesErreur = [getMessage(error)];
                    }

                    showConfirmAction = false;
                }

                afficherValidationErreur(error, showConfirmAction);
            }).finally(() => {
                vm.isSaving = false;
            });
        }
    }

    function afficherValidationErreur(error: IApiError, showConfirmAction?: boolean) {
        const message = getMessage(error);

        notificationHandler.erreur({
            error,
            lblTitre: 'G_LBL_MOD_ERREUR_TITRE',
            lblMessage: message,
            confirmAction: showConfirmAction ? () => saveEditionRapide() : null
        });
    }

    function getMessage(error: IApiError) {
        const customMessage = (
            error.data &&
            error.data.code &&
            (error.data.code.startsWith('SOFE-') || error.data.code === 'ERREUR_DE_VALIDATION')
        );

        return customMessage ?
            error.data.message :
            exLibelleFilter('G_MSG_SAUV_ENR', vm.multiOccurrence.libelles);
    }

    function hasBoutonFermer(data: any) {
        return Boolean(!vm.multiOccurrence.hasEdition(data) && (vm.hasTranscludeDetails() || vm.multiOccurrence.rowHasError(data) || vm.multiOccurrence.details));
    }
}
