import { IComponentController } from 'angular';
import { IDefaultsService } from '../../services/utils/defaults.service';

interface ITooltipModel extends IComponentController {
    tooltip: boolean;
    icon: string;
}

/* @ngInject */
export default function TooltipController(defaults: IDefaultsService) {
    const vm: ITooltipModel = this;
    //init
    vm.$onInit = function $onInit() {
        defaults(vm, {
            icon: 'info_outline'
        });

    }
}
