import { IComponentOptions, module } from 'angular';
import Binding from '../../helpers/binding.helper';
import InputLovController from './ex-input-lov.controller';

export default module('core.components.ex-input-lov', []).component('exInputLov', InputLovFactory());

function InputLovFactory(): IComponentOptions {
    return {
        bindings: Binding(
            Binding.NAME_ELEMENT,
            Binding.COL,
            Binding.DATA,
            Binding.VALUE,
            Binding.LIBELLES.OPTIONAL,
            Binding.SCHEMA_ITEM,
            Binding.DATA_TYPE.OPTIONAL,
            Binding.DISABLED.OPTIONAL,
            Binding.READONLY.OPTIONAL,
            Binding.OCCURRENCE.OPTIONAL,
            {
                titre: '@',
                required: '<?ngRequired',
                resourceParams: '<?',
                nombreLibellesDetailsCacher: '<?',
                isSelectionMultiple: '@?',
                longueurFixe: '<?',
                srccodref: '@?',
                onValueChange: '<?',
                filtres: '<?',
                name: '@?',
                tooltip: '@?'
            }
        ),
        require: {
            formCtrl: '^form',
            ecranContextCtrl: '^^exEcranContext',
            monoOccurrenceCtrl: "^^?exMonoOccurrence"
        },
        controllerAs: 'vm',
        controller: InputLovController,
        template: require('./ex-input-lov.html')
    };
}
