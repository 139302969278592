/**
 * Composant servant à faire le rendu d'une valeur de donnée
 */
import { IComponentOptions, module } from 'angular';
import DataValueController from './ex-data-value.controller';
import Binding from '../../helpers/binding.helper';

export default module('core.components.ex-data-value', []).component('exDataValue', DataValueFactory());

function DataValueFactory(): IComponentOptions {
    return {
        bindings: Binding(
            Binding.COL,
            Binding.DATA.OPTIONAL,
            Binding.DATA_TYPES.OPTIONAL,
            Binding.SCHEMAS.OPTIONAL,
            Binding.READONLY.OPTIONAL,
            {
                // Manière de passer un context additionel au template
                context: '<?',
                menu: '<?',
                hideVide: '<?',
                prefix: '<?',
                suffix: '<?',
                isGridData: '<?',
                cssprefix: '<?',
                cssMenu: '<?',
                isIcon: '<?',
                iconClass: '<?',
                icon: '<?',
                iconTitle: '<?',
                tagMultiLigne: '<?',
                setSeparateurEntete: '<?',
                stringToTag: '<?',
                stringToTagSeparateur: '<?',
                tagSeparateurEntete: '<?',
                iconLibelleTitre: '<?',
                hasModal: '<?'
            }
        ),
        require: {
            monoOccurrenceCtrl: '?^exMonoOccurrence',
            multiOccurrenceCtrl: '?^exMultiOccurrence',
            multiOccurrenceTemplateCtrl: '?^exMultiOccurrenceTemplate',
            dataSourceCtrl: '?^exDataSource'
        },
        controller: DataValueController,
        controllerAs: 'vm',
        template: require('./ex-data-value.html')
    };
}
