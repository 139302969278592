import { IComponentOptions, module } from 'angular';
import Binding from '../../helpers/binding.helper';
import InputDescriptionController from './ex-input-description.controller';

export default module('core.components.ex-input-description', []).component('exInputDescription', InputDescriptionFactory());

function InputDescriptionFactory(): IComponentOptions {
    return {
        bindings: Binding(
            Binding.NAME_ELEMENT,
            Binding.VALUE,
            Binding.LIBELLES.OPTIONAL,
            Binding.SCHEMA_ITEM,
            Binding.REGLES_VALIDATION_ITEM.OPTIONAL,
            Binding.DATA_TYPE.OPTIONAL,
            Binding.DATA_TYPES.OPTIONAL,
            Binding.SCHEMAS.OPTIONAL,
            Binding.DISABLED.OPTIONAL,
            Binding.READONLY.OPTIONAL,
            {
                titre: '@',
                required: '<?ngRequired',
                enableCount: '<',
                rows: '<?',
                exMaxlength: '<?',
                srccodref: '@?',
                tooltip: '@?'
            }
        ),
        require: {
            formCtrl: '^form',
            ecranContextCtrl: '^^exEcranContext'
        },
        controllerAs: 'vm',
        controller: InputDescriptionController,
        template: require('./ex-input-description.html')
    };
}
