import {IComponentOptions, module} from 'angular';
import InputLovIntervenantController from './ex-input-lov-intervenant.controller';
import Binding from '../../helpers/binding.helper';

export default module('core.components.ex-input-lov-intervenant', []).component('exInputLovIntervenant', InputLovIntervenantFactory());

function InputLovIntervenantFactory(): IComponentOptions {
    return {
        bindings: Binding(
            Binding.NAME_ELEMENT,
            Binding.COL,
            Binding.DATA,
            Binding.VALUE,
            Binding.SCHEMA_ITEM,
            Binding.DATA_TYPE.OPTIONAL,
            Binding.OCCURRENCE.OPTIONAL,
            {
                titre: '@',
                required: '<?ngRequired',
                resourceParams: '<?',
                nombreLibellesDetailsCacher: '<?',
                longueurFixe: '<?',
                readonly: '<?exReadonly',
                disabled: '<?exDisabled',
                srccodref: '@?',
                tooltip: '@?'
            }
        ),
        require: {
            ecranContextCtrl: '^^exEcranContext',
            formCtrl: '^form'
        },
        controllerAs: 'vm',
        controller: InputLovIntervenantController,
        template: require('./ex-input-lov-intervenant.html')
    };
}
