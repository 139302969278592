import {IComponentOptions, module} from 'angular';
import InputLovEntiteExterneController from './ex-input-lov-entite-externe.controller';
import Binding from '../../helpers/binding.helper';

export default module('app.components.ex-input-lov-entite-externe', []).component('exInputLovEntiteExterne', InputLovEntiteExterneFactory());

function InputLovEntiteExterneFactory(): IComponentOptions {
    return {
        bindings: Binding(
            Binding.NAME_ELEMENT,
            Binding.COL,
            Binding.DATA,
            Binding.VALUE,
            Binding.SCHEMA_ITEM,
            Binding.DATA_TYPE.OPTIONAL,
            Binding.DISABLED.OPTIONAL,
            Binding.READONLY.OPTIONAL,
            Binding.OCCURRENCE.OPTIONAL,
            {
                titre: '@',
                required: '<?ngRequired',
                resourceParams: '<?',
                nombreLibellesDetailsCacher: '<?',
                longueurFixe: '<?',
                srccodref: '@?',
                name: '@?',
                tooltip: '@?'
            }
        ),
        require: {
            ecranContextCtrl: '^^exEcranContext',
            monoOccurrenceEcranCtrl: '?^exMonoOccurrenceEcran',
            multiOccurrenceCtrl: '?^exMultiOccurrence',
            formCtrl: '^^form'
        },
        controllerAs: 'vm',
        controller: InputLovEntiteExterneController,
        template: require('./ex-input-lov-entite-externe.html')
    };
}
